import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import education from "../components/common/education/education";

const slice = createSlice({
  name: "misc",
  initialState: {
    setting: {},
    Countries: {},
    usState: {},
    usCitiesItems: {},
    searchAllData: {},
    liveFeeds: {},
    siteAccess: {},
    education:{},
    references:{},
    loading: false,
  },
  reducers: {
    settingRequested: (misc, action) => {
      misc.loading = true;
    },
    settingReceived: (misc, action) => {
      misc.setting = action.payload;
      misc.loading = false;
    },
    settingRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    countryRequested: (misc, action) => {
      misc.loading = true;
    },
    countryReceived: (misc, action) => {
      misc.Countries = action.payload;
      misc.loading = false;
    },
    countryRequestFailed: (misc, action) => {
      misc.loading = false;
    },

    usStateRequested: (misc, action) => {
      misc.loading = true;
    },
    usStateReceived: (misc, action) => {
      misc.usState = action.payload;
      misc.loading = false;
    },
    usStateRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    usCitiesRequested: (misc, action) => {
      misc.loading = true;
    },
    usCitiesReceived: (misc, action) => {
      misc.usCitiesItems = action.payload;
      misc.loading = false;
    },
    usCitiesFailed: (misc, action) => {
      misc.loading = false;
    },
    searchAllTypeRequested: (misc, action) => {
      misc.loading = true;
    },
    searchAllTypeReceived: (misc, action) => {
      misc.searchAllData = action.payload;
      misc.loading = false;
    },
    searchAllTypeFailed: (misc, action) => {
      misc.loading = false;
    },
    liveFeedsRequested: (misc, action) => {
      misc.loading = true;
    },
    liveFeedsReceived: (misc, action) => {
      misc.liveFeeds = action.payload;
      misc.loading = false;
    },
    liveFeedsFailed: (misc, action) => {
      misc.loading = false;
    },
    siteAccessRequested: (misc, action) => {
      misc.loading = true;
    },
    siteAccessReceived: (misc, action) => {
      misc.siteAccess = action.payload;
      misc.loading = false;
    },
    siteAccessFailed: (misc, action) => {
      misc.loading = false;
    },
    educationMediaRequested: (misc, action) => {
      misc.loading = true;
    },
    educationMediaReceived: (misc, action) => {
      misc.education = action.payload;
      misc.loading = false;
    },
    educationMediaFailed: (misc, action) => {
      misc.loading = false;
    },
    referencesRequested: (misc, action) => {
      misc.loading = true;
    },
    referencesReceived: (misc, action) => {
      misc.references = action.payload;
      misc.loading = false;
    },
    referencesFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  settingRequested,
  settingReceived,
  settingRequestFailed,
  countryRequested,
  countryReceived,
  countryRequestFailed,
  usStateRequested,
  usStateReceived,
  usStateRequestFailed,
  usCitiesRequested,
  usCitiesReceived,
  usCitiesFailed,
  searchAllTypeRequested,
  searchAllTypeReceived,
  searchAllTypeFailed,
  liveFeedsRequested,
  liveFeedsReceived,
  liveFeedsFailed,
  siteAccessRequested,
  siteAccessReceived,
  siteAccessFailed,
  educationMediaRequested,
  educationMediaReceived,
  educationMediaFailed,
  referencesRequested,
  referencesReceived,
  referencesFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const setting = "setting";

export const getSetting = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${setting}`,
      method: "get",
      callback,
      onStart: settingRequested.type,
      onSuccess: settingReceived.type,
      onError: settingRequestFailed.type,
    })
  );
};

export const getCountry = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/region/country/dropdown`,
      method: "get",
      callback,
      onStart: countryRequested.type,
      onSuccess: countryReceived.type,
      onError: countryRequestFailed.type,
    })
  );
};

export const getUsState = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/region/country/states/230`,
      method: "get",
      callback,
      onStart: usStateRequested.type,
      onSuccess: usStateReceived.type,
      onError: usStateRequestFailed.type,
    })
  );
};

export const getUsCitiesDropdown = (param, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `country/cities/dropdown/${param.id}`,
      method: "get",
      callback,
      onStart: usCitiesRequested.type,
      onSuccess: usCitiesReceived.type,
      onError: usCitiesFailed.type,
    }),
  );
};

export const addSubscribe = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `emailSubscription/subscribe`,
      method: "POST",
      callback,
      data,
    })
  );
};
export const searchAll = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `search`,
      method: "get",
      params,
      callback,
      onStart: searchAllTypeRequested.type,
      onSuccess: searchAllTypeReceived.type,
      onError: searchAllTypeFailed.type,
    }),
  );
}
export const uploadMedia = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `media`,
      method: "POST",
      callback,
      data,
    })
  );
};

//Live Feeds
export const getLiveFeeds = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `liveFeed/all`,
      method: "get",
      params,
      callback,
      onStart: liveFeedsRequested.type,
      onSuccess: liveFeedsReceived.type,
      onError: liveFeedsFailed.type,
    })
  );
};
//Site Access
export const siteAccess = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `siteAccess`,
      method: "POST",
      callback,
      data,
    }),
  );
}

//Api to triger Donation
export const updateDonationStatus = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `report/arr/updateDonationStatus`,
      method: "PUT",
      callback,
      data,
    }),
  );
}
//API for Track Arr Report for species
export const trackArrReport = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `report/arr/track`,
      method: "POST",
      callback,
      data,
    }),
  );
}

//Share Page API

export const sharePage = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `share`,
      method: "POST",
      callback,
      data,
    }),
  );
}

//reCAPTCHA Verification
export const verifyRecaptcha = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `verify/captcha`,
      method: "POST",
      callback,
      data,
    }),
  );
}

//API for education tabs
export const uploadEducationTab = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `education`,
      method: "POST",
      callback,
      data,
    })
  );
};
export const getEducationMedia = (params, callback) => (dispatch) => {
  let url = `education?page=${params.page}&order=${params.order}&${params.section}=${params.id}`;
  if (params.type) {
    url = `education?tab=${params.type}&page=${params.page}&order=${params.order}&${params.section}=${params.id}`;
  }
  if (!params.section && params.type) {
    url = `education?tab=${params.type}&page=${params.page}&order=${params.order}`;
  }
  if (!params.section && !params.type) {
    url = `education?page=${params.page}&order=${params.order}`;
  }
  return dispatch(
    apiCallBegan({
      url,
      method: "get",
      callback,
      onStart: educationMediaRequested.type,
      onSuccess: educationMediaReceived.type,
      onError: educationMediaFailed.type,
    })
  );
};


//Get references
export const getReferences = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `reference?&page=${params.page}&sort=${params.created}&order=${params.order}&${params.section}=${params.id}`,
      method: "get",
      callback,
      onStart: referencesRequested.type,
      onSuccess: referencesReceived.type,
      onError: referencesFailed.type,
    })
  );
}
export const addReference = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `reference`,
      method: "POST",
      data,
      callback,
    })
  );
};
export const updateReference = (params,data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `reference/${params}`,
      method: "PUT",
      data,
      callback,
    })
  );
};
export const addData =(data,callback)=>(dispatch)=>{
  return dispatch(
    apiCallBegan({
      url:"dataRequest",
      method :"post",
      data,
      callback
    })
  )
}
export const contactUs = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `contactUs`,
      method: "POST",
      callback,
      data,
    })
  );
};
export const getMisc = createSelector(
  (state) => state.entities.misc,
  (misc) => misc
);
