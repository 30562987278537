import React, { useEffect, useState } from 'react'
import { getMisc, getSetting } from '../../store/misc';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { capitalizeContentInBrackets } from '../../utils/helperFunctions';
import parse from "html-react-parser";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


//This is the Splash page used from EI to Salvex Site
const Splash = (props) => {
    let splashText = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data;
    const [loadingDetail, setLoadingDetail] = useState(false)
    useEffect(() => {
        if (!splashText) {
            setLoadingDetail(true)
            props.getSetting((res) => {
                if (res && res.status === 200) {
                    setLoadingDetail(false)
                }
            });
        }
    }, [])
    return (
        <main id="main">
            <div className='container'>
                <div class="org-detail-container">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="org-detail-main d-flex flex-wrap align-items-start mt-2">
                                    <div class="od-logo">
                                        {loadingDetail ? <div class="od-logo-inner"><Skeleton borderRadius={"10px"} height={"100%"} /> </div> :
                                            <div class="od-logo-inner">
                                                <img
                                                    src={
                                                        process.env.REACT_APP_MEDIA +
                                                        splashText?.salvexLogo?.small
                                                    }
                                                    alt=""
                                                    style={{ objectFit: "contain" }}
                                                />
                                            </div>
                                        }

                                    </div>
                                    <div class="od-content">
                                        <span class="text-title text-green">Organizations</span>
                                        <div class="od-content-heading d-flex">
                                            {loadingDetail ?
                                                <Skeleton borderRadius={"10px"} height={40} width={200} /> :
                                                <h1 className="caps-text ">{splashText?.salvexName && capitalizeContentInBrackets(splashText?.salvexName)}
                                                </h1>}

                                        </div>
                                        {loadingDetail ? <ul class="w-100 mt-4">
                                            <Skeleton count={3} borderRadius={"10px"} width={"100%"} />
                                        </ul> :
                                            <ul class="page-detail-head-list ml-0">
                                                {splashText?.splashText ? parse(splashText.splashText) : ""}
                                            </ul>}
                                        {loadingDetail ? "" : <div className='mt-4'>
                                            <Link
                                                to={"/"}
                                                className={`btn splash-btn`}
                                            >
                                                Go back to Environment Inclusion home page
                                            </Link>
                                            <Link
                                                // href={donationUrl ? `https://${donationUrl}` : '#'}
                                                // target="_blank"
                                                // rel="noopener noreferrer"
                                                className={`btn btn-default`}
                                            >
                                                Post a listing
                                            </Link>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )
}
const mapDispatchToProps = (dispatch) => ({
    getSetting: (callback) => dispatch(getSetting(callback)),

});

const mapStateToProps = (state) => ({
    getMisc: getMisc(state),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Splash));
