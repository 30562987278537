import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import valcanoHero from "../../include/images/vallourec-hero.jpg";
import { getProfile } from "../../utils/localStorageServices";
import defualtIcon from "../../include/images/feed-Icon.svg";
import { connect } from "react-redux";
import { getsponsorDetails, getSubscription } from "../../store/subscription";
import defaultImage from "../../include/images/default_image_species.svg";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import noRecord from "../../include/images/noPost.png";
import Skeleton from "react-loading-skeleton";
import HTMLReactParser from "html-react-parser";

//Sustainability Page
const Sustainability = (props) => {
  const profile = getProfile();
  const [visiblePosts, setVisiblePosts] = useState(3); // initially show 10 posts
  const [expandedPostId, setExpandedPostId] = useState(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const handleViewMore = () => {
    if (visiblePosts + 7 >= data?.sponsorships[0]?.post?.length) {
      history.push(`/endangered-species/${data.sponsorships[0].slug}`);
    } else {
      setVisiblePosts(10);
    }
  };
  const data =
    props.getSubscription && props.getSubscription.sponsorDetail && props.getSubscription.sponsorDetail?.data;
  useEffect(() => {
    props.getsponsorDetails((res) => {
      if (res.status == 200) {
        setLoading(false);
      } else {
      }
    });
  }, []);
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const handleAddPost = () => {
    history.push("/add-post");
  };
  return (
    <div>
      <div class="vallourec-hero-container d-flex align-items-end" style={{ marginTop: "-6.2%" }}>
        <div class="vc-bg">
          <img
            src={
              data?.user?.bannerPicture?.large
                ? process.env.REACT_APP_MEDIA + data?.user?.bannerPicture?.large
                : valcanoHero
            }
            alt=""
          />
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="vallourec-hero-content d-flex">
                <div class="vallourec-hero-logo flex-shrink-0">
                  {/* process.env.REACT_APP_MEDIA + profile?.profile?.coverPicture?.small */}
                  <img src={process.env.REACT_APP_MEDIA + data?.user?.organizationLogo?.small} alt="" />
                </div>
                <div class="vallourec-hero-text flex-grow-1 d-flex align-items-end text-capitalize">
                  <h1>{data?.user?.organizationName}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vallourec-content-container vallourec-intro-container bg-gray">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 mx-auto">
              <div class="vallourec-intro-content">
                <div class="ss-left mb-3">
                  <h2>{data?.user?.tagLine}</h2>
                </div>
                <p className="ps-2">{data?.user?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vallourec-content-container pt-0 sponsored-species-container bg-gray">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 mx-auto">
              <div class="sponsored-species-head d-flex flex-column gap-4 flex-md-row justify-content-md-between">
                <div class="ss-left">
                  <h2>Sponsored Species</h2>
                </div>
                {!loading && (
                  <div class="ss-right">
                    <div class="ss-list d-flex flex-wrap">
                      <div class="ss-item">
                        <div class="ss-box w-100 h-100 d-flex flex-column text-center">
                          <div class="ss-img">
                            <img
                              src={
                                data?.sponsorships[0]?.coverImage?.original
                                  ? process.env.REACT_APP_MEDIA + data?.sponsorships[0]?.coverImage?.small
                                  : getDefaultImage(data?.sponsorships[0]?.speciesCategoryName)
                              }
                              alt=""
                            />
                          </div>
                          <p
                            onClick={() => history.push(`/endangered-species/${data?.sponsorships[0]?.slug}`)}
                            className="text-capitalize cursor-pointer"
                          >
                            {data?.sponsorships[0]?.displayCommonName}
                          </p>
                        </div>
                      </div>
                      <div class="ss-item">
                        <div class="ss-box w-100 h-100 d-flex flex-column text-center">
                          <div class="ss-img">
                            <img
                              src={
                                data?.sponsorships[1]?.coverImage?.original
                                  ? process.env.REACT_APP_MEDIA + data?.sponsorships[1]?.coverImage?.small
                                  : getDefaultImage(data?.sponsorships[1]?.speciesCategoryName)
                              }
                              alt=""
                            />
                          </div>
                          <p
                            onClick={() => history.push(`/endangered-species/${data?.sponsorships[1]?.slug}`)}
                            className="text-capitalize cursor-pointer"
                          >
                            {data?.sponsorships[1]?.displayCommonName}
                          </p>
                        </div>
                      </div>
                      <div class="ss-item">
                        <div class="ss-box w-100 h-100 d-flex flex-column text-center">
                          <div class="ss-img">
                            <img
                              src={
                                data?.sponsorships[2]?.coverImage?.original
                                  ? process.env.REACT_APP_MEDIA + data?.sponsorships[2]?.coverImage?.small
                                  : getDefaultImage(data?.sponsorships[2]?.speciesCategoryName)
                              }
                              alt=""
                            />
                          </div>
                          <p
                            onClick={() => history.push(`/endangered-species/${data?.sponsorships[2]?.slug}`)}
                            className="text-capitalize cursor-pointer"
                          >
                            {data?.sponsorships[2]?.displayCommonName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div class="sponsored-species-body d-flex flex-column">
                {loading ? (
                  Array.from({ length: 3 }).map((_, index) => (
                    <div className="species-profile-widget d-flex flex-column flex-md-row">
                      <div className="species-profile-overview flex-shrink-0">
                        <Skeleton height={"420px"} width={"100%"} />
                      </div>
                      <div
                        className="species-profile-description d-flex flex-column flex-grow-1"
                        style={{ maxHeight: "450px", overflowY: "auto" }}
                      >
                        <Skeleton height={"420px"} width={"100%"} />
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    {data?.sponsorships?.map((species, index) => (
                      <div key={species._id} className="species-profile-widget d-flex flex-column flex-md-row">
                        <div className="species-profile-overview flex-shrink-0">
                          <div className="species-profile">
                            <div className="species-profile-img">
                              <img
                                src={
                                  species.coverImage?.original
                                    ? `${process.env.REACT_APP_MEDIA}${species.coverImage.small}`
                                    : getDefaultImage(species.speciesCategoryName)
                                }
                                alt=""
                              />
                            </div>
                            <div className="species-profile-details">
                              <h3
                                onClick={() => history.push(`/endangered-species/${species?.slug}`)}
                                className="text-capitalize cursor-pointer"
                              >
                                {species.displayCommonName}
                              </h3>
                              <p className="text-capitalize">{species.scientificName}</p>
                              <div className="badge-holder d-flex align-items-center flex-wrap text-capitalize">
                                {species.regions?.slice(0, 3).map((region, regionIndex) => (
                                  <div
                                    onClick={() =>
                                      history.push(
                                        region.countryName === "United States"
                                          ? `/regions/united-states/${region?.slug}`
                                          : `/regions/${region?.slug}`,
                                      )
                                    }
                                    key={regionIndex}
                                    className="badge green-badge cursor-pointer"
                                  >
                                    {region.countryName === "United States" && region.state
                                      ? `${region.state}, ${region.name}`
                                      : region.name}
                                  </div>
                                ))}
                                {species.regions?.length > 3 && (
                                  <div className="badge green-badge">+{species.regions.length - 3}</div>
                                )}
                              </div>
                            </div>
                            <div className="species-profile-bottom">
                              <div className="species-profile-name d-flex align-items-center">
                                <div className="spn-icon-box">
                                  <img
                                    src={
                                      species.dangerLevelImage?.original
                                        ? `${process.env.REACT_APP_MEDIA}${species.dangerLevelImage.small}`
                                        : ""
                                    }
                                    alt="Danger level"
                                  />
                                </div>
                                <div className="npm-name-box text-capitalize">{species.dangerLevelName}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="species-profile-description d-flex flex-column flex-grow-1"
                          style={{ maxHeight: "450px", overflowY: "auto" }}
                        >
                          {species.post?.length > 0 ? (
                            species.post.slice(0, visiblePosts).map((post) => (
                              <div className="description-pannel" key={post._id}>
                                <div className="description-pannel-box">
                                  <p>
                                    {HTMLReactParser(
                                      expandedPostId === post._id
                                        ? post.description
                                        : `${post.description.slice(0, 150)}...`,
                                    )}
                                  </p>
                                  <div className="description-pannel-btn">
                                    <Link
                                      to={{
                                        pathname: `/posts/${post?.slug}`,
                                        state: {
                                          return: `/sustainability/${profile?.profile?.slug}`,
                                          page: "sustainability",
                                        },
                                      }}
                                      className="btn-read-more"
                                    >
                                      Read More
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="description-pannel">
                              <div
                                style={{ height: "420px" }}
                                className="description-pannel-box description-pannel-box-img d-flex align-items-center justify-content-center flex-column"
                              >
                                <img src={noRecord} alt="No record" className="mb-3" />
                                <div className="viewmore-post-btn w-25 text-center">
                                  <button onClick={handleAddPost} className="btn btn-default btn-block">
                                    Add Post
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {species.post?.length > visiblePosts && (
                            <div className="viewmore-post-btn">
                              <button onClick={() => handleViewMore(index)} className="btn btn-default btn-block">
                                View More Posts
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsorDetails: (callback) => {
    dispatch(getsponsorDetails(callback));
  },
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sustainability));
