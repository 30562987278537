import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import trash from "../../../include/images/trash-24x24.svg";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import defaultEnvImg from "../../../include/images/defaultEnvImg.png";
import defaultZooImg from "../../../include/images/Zoo.png";

const ListingCard = (props) => {
  const [imageError, setImageError] = useState(false);
  const isMobile = useCheckMobileScreen();
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <li class="ei-grid-item position-relative">
      {props.editMode && (
        <button
          type="button"
          class="trash-button-box z-1"
          onClick={(e) => {
            props.handleDelete(props.id);
          }}
        >
          <img src={trash} alt="w volumne" />
        </button>
      )}
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton borderRadius={"10px"} height={`${isMobile ? "180px" : "250px"}`} />
        </div>
      ) : props.page === "region-listing-page" ||
        props.page === "Science And Education" ||
        props.page === "environmentalist" ||
        props.page === "threats" ||
        props.page === "threats" ||
        props.page === "zoo" ||
        props.page === "region-species" ? (
        <Link
          to={
            props.page === "region-listing-page"
              ? {
                  pathname: props.country === "US" ? `/regions/united-states/${props.slug}` : `/regions/${props.slug}`,
                  state: { id: props.id },
                }
              : props.page === "Science And Education"
              ? {
                  pathname: `/science-education/${props.slug ? props.slug : props.id}`,
                  state: { id: props?.id },
                }
              : props.page === "environmentalist"
              ? {
                  pathname: `/environmentalists/${props?.slug}`,
                  state: { id: props?.id },
                }
              : props.page === "region-species"
              ? {
                  pathname: `/endangered-species/${props.slug}`,
                  state: { id: props?.id },
                }
              : props.page === "zoo"
              ? {
                  pathname: `/zoos-and-wildlife-reserves/${props?.slug}`,
                  state: { id: props?.id },
                }
              : props.page === "threats"
              ? {
                  pathname: `/war-on-the-environment-threats/${props?.slug}`,
                  state: { id: props?.id },
                }
              : ""
          }
          className="ei-grid-box"
        >
          {props.page === "region-species" ? (
            <div class="ei-grid-box-image list-card-image">
              {props.img ? (
                <img src={process.env.REACT_APP_MEDIA + props.img} alt="" />
              ) : (
                <img src={props.getDefaultImage(props?.speciesCategoryName)} alt="" />
              )}
            </div>
          ) : props.page === "environmentalist" ? (
            <div class="ei-grid-box-image list-card-image">
              {props.img ? (
                <img src={process.env.REACT_APP_MEDIA + props.img} alt="" />
              ) : (
                <img src={defaultEnvImg} alt="" />
              )}
            </div>
          )  : props.page === "zoo" ? (
            <div class="ei-grid-box-image list-card-image">
              {props.img ? (
                <img src={process.env.REACT_APP_MEDIA + props.img} alt="" />
              ) : (
                <img src={defaultZooImg} alt="" />
              )}
            </div>
          ) : (
            <div class="ei-grid-box-image list-card-image">
              {!imageError ? (
                <img src={process.env.REACT_APP_MEDIA + props.img} onError={handleImageError} alt="" />
              ) : (
                <img src={process.env.REACT_APP_MEDIA + props.img1} alt="" />
              )}
            </div>
          )}
          <div
            class={
              props.page === "region-listing-page"
                ? "ei-grid-box-overlay"
                : props.page === "Science And Education"
                ? "ei-grid-box-overlay"
                : props.page === "environmentalist"
                ? "ei-grid-box-overlay"
                : props.page === "region-species"
                ? "ei-grid-box-overlay"
                : props.page === "threats"
                ? "ei-grid-box-overlay"
                : props.page === "zoo"
                ? "ei-grid-box-overlay"
                : " ei-grid-box-overlay default-c"
            }
          >
            <p className="text-transfrom ">{`${capitalizeContentInBrackets(props.name)}`}</p>
            {props.pawRed && props.pawRedImage && (
              <img src={process.env.REACT_APP_MEDIA + props.pawRedImage} class="ei-grid-box-icon" alt="" />
            )}
          </div>
        </Link>
      ) : (
        <a href="javascript:void(0);" className="ei-grid-box no-cursor">
          <div class="ei-grid-box-image list-card-image">
            {!imageError ? (
              <img src={process.env.REACT_APP_MEDIA + props.img} onError={handleImageError} alt="" />
            ) : (
              <img src={process.env.REACT_APP_MEDIA + props.img1} alt="" />
            )}
          </div>
          <div
            class={
              props.page === "organizations" ||
              props.page === "species" ||
              props.page === "environmentalist" ||
              props.page === "region-species" ||
              props.page === "threats" ||
              props.page === "zoo"
                ? "ei-grid-box-overlay"
                : " ei-grid-box-overlay default-c"
            }
          >
            <p>{props.name}</p>
            {props.pawRed && props.pawRedImage && (
              <img src={process.env.REACT_APP_MEDIA + props.pawRedImage} class="ei-grid-box-icon" alt="" />
            )}
          </div>
        </a>
      )}
    </li>
  );
};

export default ListingCard;
