import React, { useEffect, useReducer, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Details from "../../components/zooSubTabs/details";
import ContactInfo from "../../components/zooSubTabs/contactInfo";
import Programs from "../../components/zooSubTabs/programs";
import { getSpeciesZoo, getZooDetails, getZooLiveFeeds, getZooPrograms, zooListings } from "../../store/zoo";
import Skeleton from "react-loading-skeleton";
import App from "../../common/maps/map";
import Species from "../../components/environmentalistDetail/species";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import { getMisc, getReferences, getSetting } from "../../store/misc";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetterWord,
  cleanAndFormatHtml,
} from "../../utils/helperFunctions";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ShareModal from "../../components/common/sharemodal/shareModal";
import shareSvg from "../../include/images/share.svg";
import editModeIcon from "../../include/images/edit.svg";
import { getProfile } from "../../utils/localStorageServices";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import Media from "../../components/common/education/media";

const ZooDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("1");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");
  const [mapData, setMapData] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [state, dispatch] = useReducer(loadingState, {
    detail: false,
    species: false,
    program: false,
  });
  const profile = getProfile();
  const [isEdit, setIsEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editState, setEditState] = useState({});

  function loadingState(state, action) {
    if (action.type === "loading_detail") {
      return {
        detail: !state.detail,
        species: state.species,
        program: state.program,
      };
    } else if (action.type === "loading_species") {
      return {
        detail: state.detail,
        species: !state.species,
        program: state.program,
      };
    } else if (action.type === "loading_program") {
      return {
        detail: state.detail,
        species: state.species,
        program: !state.program,
      };
    }
    throw Error("Unknown action.");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      setIsEdit(profile?.profile?.interestedZoos?.includes(id));
      setId(id);
      setLoadingDetail(true);
      props.getZooDetails(id, (res) => {
        if (res && res.status == 200) {
          setMapData([
            {
              name: res?.data?.data?.name,
              coordinates: res?.data?.data?.geoLocation,
            },
          ]);
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getZooDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setMapData([
            {
              name: res?.data?.data?.name,
              coordinates: res?.data?.data?.geoLocation,
            },
          ]);
          setId(res?.data?.data?._id);
          setIsEdit(profile?.profile?.interestedZoos?.includes(res?.data?.data?._id));
          setLoadingDetail(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      const ids = location?.state?.id || id;
      dispatch({ type: "loading_program" });
      props.getZooPrograms(ids, (res) => {
        if (res && res.status == 200) {
          dispatch({ type: "loading_program" });
        }
      });
      props.getZooLiveFeeds({ id: ids, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getSpeciesZoo(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: id,
        page: 1,
        section: "zoo",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);
  const details = props.zooListings && props.zooListings?.zooDetail;
  const program = props.zooListings && props.zooListings?.zooProgram;
  const species = props.zooListings?.zooDetail && props.zooListings?.species;

  const liveFeeds = props.zooListings && props.zooListings.liveFeeds;
  const endangeredSpeciesCount = countEndangeredSpecies(details?.data?.species);
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const donationUrl = details?.data?.donationUrl?.split("//").pop();
  Useauthredirect();
  function countEndangeredSpecies(data) {
    let count = 0;
    if (data) {
      for (const species of data) {
        if (species.dangerLevel && species.dangerLevel.name === "endangered") {
          count++;
        }
      }
    }
    return count;
  }
  const combinedData = [
    ...(species?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
    })),
  ];
  const zooMapData = [
    {
      id: details.data?._id,
      name: details.data?.name,
      coordinates: details.data?.geoLocation,
      slug: details.data?.slug,
      section: "zoos-and-wildlife-reserves",
      image: details.data?.coverImage?.small,
    },
  ];

  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "1", target: "#pills-one", label: "Details", count: null, data: [1, 2] },
    { id: "2", target: "#pills-two", label: "Contact Info", count: null, data: [1, 2] },
    { id: "3", target: "#pills-three", label: "Programs", count: program?.totalCount, data: program?.data },
    { id: "4", target: "#pills-four", label: "Endangered Species", count: species?.data?.length, data: species?.data },
    { id: "5", target: "#pills-five", label: "Live Feeds", count: liveFeeds?.totalCount, data: liveFeeds?.data },
    { id: "6", target: "#pills-six", label: "Map", count: null, data: [1, 2] },
    { id: "7", target: "#pills-seven", label: "Media and Posts", count: null, data: [1, 2] },
    { id: "8", target: "#pills-eight", label: "References", count: null, data: references },
    { id: "9", target: "#pills-nine", label: "Education", count: null, data:[1,2] },
  ];

  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);
  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
  };
  return (
    <PageLayout>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <main id="main">
        <div class="content-container overflow-hidden pt-4">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="listing-details-container d-flex flex-wrap justify-content-between environmentalist-details-container">
                  {loadingDetail ? (
                    <div class="ldc-image">
                      <Skeleton borderRadius={"10px"} width={"100%"} height={"100%"} />
                    </div>
                  ) : (
                    <div class="ldc-image">
                      <div class="zoo-details-img ">
                        {mapData && <App data={mapData} mapType="zoo-detail" zoom={"zoom"} singleMap={true} />}
                      </div>
                    </div>
                  )}
                  <div class="ldc-text">
                    <div class="ldc-text-head position-relative">
                      <span class="text-title text-green">Zoo</span>
                      <div class="ldc-text-main">
                        {loadingDetail ? (
                          <Skeleton borderRadius={"10px"} height={40} width={200} />
                        ) : (
                          <>
                            <h1 className="caps-text">
                              <span>
                                {details && details?.data ? `${capitalizeContentInBrackets(details?.data?.name)}` : ""}
                              </span>{" "}
                              <div className="ml-20">
                                {/* <Link onClick={() => setOpenShareModal(true)}><img
                                src={shareSvg} alt="" /></Link> */}
                                {isEdit && (
                                  <Link
                                    onClick={() => {
                                      setEditMode(true);
                                    }}
                                  >
                                    <img src={editModeIcon} alt="" />
                                  </Link>
                                )}
                              </div>
                            </h1>
                          </>
                        )}
                      </div>
                      <p class="fw-medium text-uppercase mb-0">{details?.data?.location?.country}</p>
                      {details?.data?.donationUrl ? (
                        <a
                          href={donationUrl ? `https://${donationUrl}` : "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ bottom: "10px" }}
                          className={`btn btn-default position-absolute end-0 ${!donationUrl ? "disabled" : ""}`}
                        >
                          Donate Direct
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="ldc-text-body">
                      <ul class="ltb-details-list d-flex flex-wrap">
                        <li class="ltb-details-item">
                          <div class="ltb-details-box">
                            <p>Land area</p>
                            {loadingDetail ? (
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            ) : details?.data?.landArea ? (
                              <h4>{details?.data?.landArea}</h4>
                            ) : (
                              <h4>-</h4>
                            )}
                          </div>
                        </li>
                        <li class="ltb-details-item">
                          <div class="ltb-details-box">
                            <p>No. of Species</p>
                            {loadingDetail ? (
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            ) : (
                              <h4>{details && details?.data && details?.data && details?.data?.species?.length}</h4>
                            )}
                          </div>
                        </li>
                        <li class="ltb-details-item">
                          <div class="ltb-details-box">
                            <p>Annual Visitors</p>
                            {loadingDetail ? (
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            ) : details?.data?.annualVisitor ? (
                              <h4>{details?.data?.annualVisitor}</h4>
                            ) : (
                              <h4>-</h4>
                            )}
                          </div>
                        </li>
                        <li class="ltb-details-item">
                          <div class="ltb-details-box">
                            <p>Endangered Species</p>
                            {loadingDetail ? (
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            ) : (
                              <h4>{endangeredSpeciesCount}</h4>
                            )}
                          </div>
                        </li>
                        <li className="ltb-details-item">
                          <div className="ltb-details-box">
                            <p>Social media</p>
                            {loadingDetail ? (
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            ) : (
                              <div className="ltb-icon-list d-flex flex-wrap mt-2">
                                {details?.data?.socialMediaLink?.facebook &&
                                details?.data?.socialMediaLink?.facebook.trim() !== "" ? (
                                  <div className="ltb-icon-item">
                                    <a
                                      href={details.data.socialMediaLink.facebook}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="ltb-icon-box"
                                    >
                                      <i className="fab fa-facebook-f"></i>
                                    </a>
                                  </div>
                                ) : null}

                                {details?.data?.socialMediaLink?.twitter &&
                                details?.data?.socialMediaLink?.twitter.trim() !== "" ? (
                                  <div className="ltb-icon-item mt-2">
                                    <a
                                      href={details.data.socialMediaLink.twitter}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="ltb-icon-box"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                      </svg>
                                    </a>
                                  </div>
                                ) : null}

                                {details?.data?.url && details?.data?.url.trim() !== "" ? (
                                  <div className="ltb-icon-item mt-2">
                                    <a
                                      href={details.data.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="ltb-icon-box custom-icon"
                                    >
                                      <svg
                                        width="40"
                                        height="1em"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M13 21C17.9706 21 22 16.9706 22 12C22 7.02944 17.9706 3 13 3C8.02944 3 4 7.02944 4 12C4 16.9706 8.02944 21 13 21Z"
                                          stroke="black"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M4 12H22"
                                          stroke="black"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12.9984 3C15.2496 5.46452 16.5289 8.66283 16.5984 12C16.5289 15.3372 15.2496 18.5355 12.9984 21C10.7473 18.5355 9.46796 15.3372 9.39844 12C9.46796 8.66283 10.7473 5.46452 12.9984 3Z"
                                          stroke="black"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </a>
                                  </div>
                                ) : null}

                                {details?.data?.socialMediaLink?.instagram &&
                                details?.data?.socialMediaLink?.instagram.trim() !== "" ? (
                                  <div className="ltb-icon-item mt-2">
                                    <a
                                      href={details.data.socialMediaLink.instagram}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="ltb-icon-box"
                                    >
                                      <i className="fab fa-instagram"></i>
                                    </a>
                                  </div>
                                ) : null}
                                {!details?.data?.socialMediaLink?.facebook?.trim() &&
                                  !details?.data?.socialMediaLink?.twitter?.trim() &&
                                  !details?.data?.url?.trim() &&
                                  !details?.data?.socialMediaLink?.instagram?.trim() && (
                                    <div className="ltb-icon-item">
                                      <span>-</span>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loadingDetail ? (
          <div class="tabs-block-container">
            <div class="tbc-head ">
              <Skeleton
                borderRadius={"10px"}
                height={"7.5vh"}
                baseColor="var(--dark-green-color)"
                highlightColor="#0cb40c"
              />
            </div>
            <div class="container mt-5">
              {" "}
              <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
            </div>
          </div>
        ) : (
          <div class="tabs-block-container new-tabs-block-container">
            <div class="tbc-head mob-height-container">
              <div class="container">
                <div class="row">
                  <div className="col-md-12">
                    <form className="d-lg-none">
                      <select
                        className="form-select"
                        id="tabSelector"
                        onChange={(e) => handleMenuItemClick(e.target.value)}
                        value={menuItem}
                      >
                        {items.map(
                          (item) =>
                            (editMode || item.data?.length > 0) && (
                              <option key={item.id} value={item.id}>
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </option>
                            ),
                        )}
                      </select>
                    </form>
                    <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                      {items.map(
                        (item) =>
                          (editMode || item.data?.length > 0) && (
                            <li className="nav-item" key={item.id} role="presentation">
                              <div
                                className={`nav-link ${menuItem === item.id && "active"}`}
                                id={`pills-${item.id}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${item.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${item.id}`}
                                aria-selected={menuItem === item.id}
                                onClick={() => handleMenuItemClick(item.id)}
                              >
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tbc-body overflow-hidden">
              <div class="tab-content tab-content-slider" id="pills-tabContent">
                {menuItem == "1" && (
                  <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                    <Details menuItem={menuItem} />
                  </div>
                )}
                {menuItem == "2" && (
                  <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                    <ContactInfo menuItem={menuItem} />
                  </div>
                )}
                {menuItem == "3" && (
                  <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                    <Programs menuItem={menuItem} id={details.data._id} />
                  </div>
                )}
                {menuItem == "4" && (
                  <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-three">
                    <Species id={details.data._id} menuItem={menuItem} page="zoo" editMode={editMode} />
                  </div>
                )}
                {menuItem == "5" && (
                  <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                    <LiveFeeds id={details.data._id} menuItem={menuItem} section="zoos" />
                  </div>
                )}
                {menuItem == "6" && (
                  <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                    <div className="container">
                      <div class="tbc-map-full detail-page-map region-detail-map">
                        <div class="map-hero-image map-wet-market">
                          {combinedData && (
                            <App
                              data={combinedData}
                              data1={zooMapData}
                              mapType="detail-page"
                              isMultiMarker={true}
                              zoom={"zoom"}
                              singleMap={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {menuItem == "7" && (
                  <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                    <Education menuItem={menuItem} id={id} section="zoo" data={details?.data} />
                  </div>
                )}
                {menuItem == "8" && (
                  <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                    <References
                      menuItem={menuItem}
                      id={id}
                      // setSelectedId={setSelectedId}
                      section="zoo"
                      isEdit={editMode}
                    />
                  </div>
                )}
                {menuItem == "9" && (
                  <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                    <Media
                      loading={loadingDetail}
                      menuItem={menuItem}
                      id={id}
                      section="zoo"
                      data={details?.data}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ShareModal
          show={openShareModal}
          onHide={() => setOpenShareModal(!openShareModal)}
          name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
          id={details?.data?._id}
          category="zoo"
        />
        <div class="container custom-citation">
          <div className="custom-citation-text">Citations</div>
          <div
            className="custom-citation-text"
            dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
          ></div>
        </div>
      </main>
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getZooDetails: (params, callback) => dispatch(getZooDetails(params, callback)),
  getZooPrograms: (params, callback) => dispatch(getZooPrograms(params, callback)),
  getZooLiveFeeds: (params, callback) => dispatch(getZooLiveFeeds(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getSpeciesZoo: (params, callback) => dispatch(getSpeciesZoo(params, callback)),
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
});

const mapStateToProps = (state) => ({
  zooListings: zooListings(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ZooDetail));
