import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { capitalizeContentInBrackets, capitalizeFirstLetter } from "../../../utils/helperFunctions";
import { getProfile } from "../../../utils/localStorageServices";
import defaultImage from "../../../include/images/default_image_species.svg";
import amphibiansImg from "../../../include/images/Amphibians.png";
import birdsImg from "../../../include/images/Birds.png";
import fishImg from "../../../include/images/Fish.png";
import insectsImg from "../../../include/images/Insects.png";
import mammalsImg from "../../../include/images/Mammals.png";
import reefCoralsImg from "../../../include/images/Reef Corals.png";
import reptilessImg from "../../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../../include/images/Crustaceans.png";

const SearchListCard = (props) => {
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  return (
    <li class="ei-grid-item">
      {props.loading ? (
        <div className="ei-grid-box z-0">
          <Skeleton borderRadius={"10px"} height={"220px"} />
        </div>
      ) : (
        <Link
          to={
            props.type === "species"
              ? {
                  pathname: `/endangered-species/${props?.slug}`,
                  state: { id: props.id },
                }
              : props.type === "region"
              ? {
                  pathname: `/regions/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : props.type === "organization"
              ? {
                  pathname: `/organizations/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : props.type === "environmentalist"
              ? {
                  pathname: `/environmentalists/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : props.type === "wetMarket"
              ? {
                  pathname: `/war-on-the-environment-threats/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : props.type === "zoo"
              ? {
                  pathname: `/zoos-and-wildlife-reserves/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : props.type === "scienceAndEducation"
              ? {
                  pathname: `/science-education/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
              : {
                  pathname: `/profile/${props?.slug}`,
                  id: props?.id,
                  name: capitalizeFirstLetter(props?.name),
                }
          }
          onClick={props.handleClick}
          className="ei-grid-box"
        >
          {props.type === "species" ? (
            <div class="ei-grid-box-image list-card-image">
              {props?.img ? (
                <img src={process.env.REACT_APP_MEDIA + props.img} alt="" />
              ) : (
                <img src={getDefaultImage(props?.item?.speciesCategoryName)} alt="" />
              )}
            </div>
          ) : (
            <div class="ei-grid-box-image list-card-image ">
              <img src={process.env.REACT_APP_MEDIA + props.img} alt="" />
            </div>
          )}

          <div class={props.page === "species" ? "ei-grid-box-overlay" : " ei-grid-box-overlay"}>
            <p className="text-transfrom text-capitalize">
              {capitalizeContentInBrackets(props.name)}
              <small className="text-transfrom search-type-text text-capitalize">
                {props.type ? (props.type === "scienceAndEducation" ? "Science And Education" : props.type) : "User"}
              </small>
            </p>

            {props.dangerImg && (
              <img src={process.env.REACT_APP_MEDIA + props.dangerImg} class="ei-grid-box-icon" alt="" />
            )}
          </div>
        </Link>
      )}
    </li>
  );
};

export default SearchListCard;
