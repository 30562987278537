import React, { useEffect, useState } from "react";
import ImageHolder from "../../common/customStyles/imageHolder";
import { capitalizeContentInBrackets, getRelativeTime } from "../../utils/helperFunctions";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getProfile } from "../../utils/localStorageServices";
import editIcon from "../../include/images/heart.svg";
import wIcon from "../../include/images/w-icon-1.svg";
import wIcon2 from "../../include/images/w-icon-2.svg";
import wIcon3 from "../../include/images/w-icon-3.svg";
import liked from "../../include/images/fill-heart-icon.svg";
import wIcon4 from "../../include/images/w-icon-4.svg";
import wIcon5 from "../../include/images/w-icon-5.svg";
import volume from "../../include/images/w-volume-2.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import HomeImageGallery from "../common/imageGallery/homePageImageGallery";
import defualtIcon from "../../include/images/feed-Icon.svg";
import HTMLReactParser from "html-react-parser";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import ReactPlayer from "react-player";
import DescriptionWithLink from "../../common/description/descriptionWithLink";
const PostGalleryModal = (props) => {
  const {
    item,
    location,
    deleteHandler,
    toggleDmPosts,
    setSelectedItem,
    toggleShowReport,
    toggleShowComments,
    likeAndUnlikePost,
    togglePost,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [startIndex, setStartIndex] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [galleryItems, setGalleryItems] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const profile = getProfile();
  const profilePicture = profile?.profile?.profilePicture?.medium;
  const isMobile = useCheckMobileScreen();
  const visibleItems = isMobile ? 5 : 20;
  const history = useHistory();
  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = (file) => {
    let mediaItems;
    if (file?.length > 0) {
      mediaItems = file
        ?.map((item) => {
          const mediaType = item?.mediaType;
          const original = process.env.REACT_APP_MEDIA + item?.original;
          if (mediaType === "image" || mediaType === "video") {
            return {
              file: {
                mediaType: mediaType,
                original: original,
              },
            };
          }
          return null;
        })
        .filter(Boolean);
    }
    setGalleryItems(mediaItems);
    setShowModal(!showModal);
  };
  const renderFiles = (files, item) => {
    if (files.length > 1) {
      const firstFile = files[0];
      const remainingCount = files.length - 1;
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(firstFile.mediaType);

      return (
        <>
          {" "}
          <div
            className="w-image-holder position-relative cursor-pointer"
            onClick={() => {
              setSelectedItem(item);
              togglePost();
            }}
          >
            {isVideo ? (
              <video controls>
                <source src={process.env.REACT_APP_MEDIA + firstFile.original} />
              </video>
            ) : (
              <img src={process.env.REACT_APP_MEDIA + firstFile.original} alt="" />
            )}
            <div
              style={{
                position: "absolute",
                bottom: 10,
                right: 10,
                background: "rgba(0,0,0,0.5)",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedItem(item);
                togglePost();
              }}
            >
              (+{remainingCount}) View More
            </div>
          </div>
        </>
      );
    } else if (files.length === 1) {
      const file = files[0];
      const isVideo = /^(mp4|mpeg|webm|video)$/i.test(file.mediaType);

      return (
        <div
          className={`w-image-holder position-relative cursor-pointer ${
            file.mediaType == "document" && !isMobile ? "d-flex justify-content-center align-items-center" : ""
          }`}
          onClick={() => {
            setSelectedItem(item);
            togglePost();
          }}
        >
          {(file?.mediaType === "image" || file?.mediaType === "sketch" || file?.mediaType === "essay") && (
            <img className="w-100 h-100 object-fit-cover" src={process.env.REACT_APP_MEDIA + file?.original} alt="" />
          )}
          {file?.mediaType === "video" && (
            <>
              {file?.original && file?.original.startsWith("https") ? (
                <ReactPlayer className={`w-100 react-player-iframe`} url={file?.original} controls={true} />
              ) : null}
              {file?.original && !file?.original.startsWith("https") ? (
                <video className="post-video-tag">
                  <source src={process.env.REACT_APP_MEDIA + file?.original} />
                </video>
              ) : null}
            </>
          )}
          {file?.mediaType === "document" && file?.original && (
            <div className="h-100 react-player-iframe">
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                  process.env.REACT_APP_MEDIA + file?.original,
                )}&embedded=true`}
                className="w-100 pdf-preview"
                title="PDF Preview"
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };
  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };
  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
    }
    return description;
  };
  const sectionsArray = [
    { name: "regions", path: "/regions" },
    { name: "species", path: "/endangered-species" },
    { name: "organizations", path: "/organizations" },
    { name: "wetMarkets", path: "/war-on-the-environment-threats" },
    { name: "zoos", path: "/zoos-and-wildlife-reserves" },
    { name: "scienceAndEducations", path: "/science-education" },
    { name: "environmentalists", path: "/environmentalists" },
  ];
  const toggleReadMore = () => {
    setReadMore(!readMore);
  };
  const renderDescription = (description) => {
    if (!description) return "";
    const characterLimit = isMobile ? 200 : 400;
    if (description.length <= characterLimit) {
      return HTMLReactParser(description);
    }

    const shortDescription = description.substring(0, characterLimit) + "... ";
    return (
      <>
        {HTMLReactParser(readMore ? description : shortDescription)}
        <p onClick={toggleReadMore} className="read-more-detail p-0">
          {readMore ? "Read Less" : "Read More"}
        </p>
      </>
    );
  };
  const allSectionItems = sectionsArray.reduce((acc, sectionName) => {
    if (item[sectionName.name]) {
      const sectionItems = item[sectionName.name].map((sectionItem) => ({
        ...sectionItem,
        path: `${sectionName.path}/${sectionItem.slug}`,
        id: sectionItem._id,
      }));
      return [...acc, ...sectionItems];
    }
    return acc;
  }, []);

  return (
    <div
      className={`widget d-flex flex-wrap ${props.page == "profile" ? "mt-2" : ""}`}
      key={item._id}
      id={item._id}
      data-post-id={item._id}
    >
      <div className="widget-icon">
        <div className="w-icon d-flex align-items-center justify-content-center overflow-hidden">
          {item.createdByAdmin != null ? (
            <img src={defualtIcon} name={item?.name?.slice(0, 1)} alt={item?.name} />
          ) : (
            <ImageHolder
              image={
                item?.createdBy
                  ? item?.createdBy?.profilePicture?.medium && location?.pathname !== "/profile"
                    ? item?.createdBy?.profilePicture?.medium
                    : profilePicture
                  : null
              }
              name={item?.name?.slice(0, 1)}
            />
          )}
        </div>
      </div>
      <div className="widget-content">
        <div className="widget-text-holder">
          <div className="w-heading-block position-relative">
            <div className="w-heading-box d-flex align-items-center flex-wrap ">
              {item.createdByAdmin != null ? (
                <Link
                  className="w-label-box fw-semibold text-capitalize post-title-name cursor-pointer"
                  style={{ fontSize: "14px" }}
                >
                  Environmental Inclusion
                </Link>
              ) : item?.isAddedFromMedia ? (
                item?.createdBy ? (
                  <Link
                    to={
                      profile?.profile?._id == item?.createdBy?._id
                        ? {
                            pathname: `profile`,
                          }
                        : {
                            pathname: `profile/${item?.createdBy?.slug}`,
                            state: { id: item?.createdBy?._id },
                          }
                    }
                    className="w-label-box fw-semibold text-capitalize post-title-name hover-underline cursor-pointer"
                    style={{ fontSize: "14px" }}
                  >
                    {`${item?.createdBy?.firstName?.charAt(0)?.toUpperCase()}${item?.createdBy?.firstName?.slice(1)} 
                    ${item?.createdBy?.lastName?.charAt(0)?.toUpperCase()}${item?.createdBy?.lastName?.slice(1)}`}
                  </Link>
                ) : (
                  <div className="w-label-box fw-semibold text-capitalize post-title-name" style={{ fontSize: "14px" }}>
                    {item?.name && item?.name}
                  </div>
                )
              ) : props?.page != "profile" ? (
                <Link
                  to={
                    profile?.profile?._id == item?.createdBy?._id
                      ? {
                          pathname: `profile`,
                        }
                      : {
                          pathname: `profile/${item?.createdBy?.slug}`,
                          state: { id: item?.createdBy?._id },
                        }
                  }
                  className="w-label-box fw-semibold text-capitalize post-title-name hover-underline cursor-pointer"
                  style={{ fontSize: "14px" }}
                >
                  {props?.likedPosts == "like"
                    ? item?.name
                    : item?.createdBy
                    ? `${item?.createdBy.firstName} ${item?.createdBy.lastName}`
                    : ""}
                </Link>
              ) : (
                <div className="w-label-box fw-semibold text-capitalize post-title-name" style={{ fontSize: "14px" }}>
                  {props?.likedPosts == "like"
                    ? item?.name
                    : item?.createdBy
                    ? `${item?.createdBy.firstName} ${item?.createdBy.lastName}`
                    : ""}
                </div>
              )}
              <span className="w-dot-box"></span>
              <div className="w-time-box d-flex align-items-center">
                <span className="w-time-count">{getRelativeTime(item && item.createdAt)}</span>{" "}
              </div>
              {/* {!location?.pathname?.startsWith("/profile") &&
              <div
                className={`w-time-box d-flex align-items-center position-absolute ${
                  item?.createdBy?._id == profile?.profile?._id && !item?.isAddedFromMedia ? "right-25" : "end-0"
                }`}
              >
                <span className="w-time-count custom-pill-post rounded-pill">
                  {item?.isAddedFromMedia ? "Education And Media" : "Post"}
                </span>{" "}
              </div>} */}
            </div>
            {item?.createdBy?._id == profile?.profile?._id && !item?.isAddedFromMedia && !item.createdByAdmin && (
              <div className="w-h-button-box d-flex align-items-center justify-content-center">
                <Dropdown className="btn-group heart-icon-box  d-flex align-items-center justify-content-center cursor-pointer">
                  <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle ">
                    <img src={editIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                    <Dropdown.Item
                      className="dropdown-item edit-delete-feeds"
                      eventKey={"1"}
                      onClick={() => {
                        history.push({
                          pathname: "/edit-post",
                          item: item,
                          postType: "edit",
                        });
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/edit-post",
                          item: item,
                          postType: "edit",
                        }}
                      >
                        Edit
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="dropdown-item delete edit-delete-feeds"
                      onClick={() => deleteHandler(item)}
                      eventKey={"2"}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
          <div className="w-text-box w-text-read-more">
            {" "}
            <DescriptionWithLink description={renderDescription(item?.description)} />
          </div>
          {item?.isAddedFromMedia && item?.class && (
            <div className="w-text-box w-text-read-more text-capitalize">Grade : {item?.class ? item?.class : ""}</div>
          )}
          {item?.isAddedFromMedia && item?.school && (
            <div className="w-text-box w-text-read-more text-capitalize">
              School : {item?.school ? item?.school : ""}
            </div>
          )}
          {item?.isAddedFromMedia && item?.teacherName && (
            <div className="w-text-box w-text-read-more text-capitalize">
              Teacher Name : {item?.teacherName ? item?.teacherName : ""}
            </div>
          )}
          <div className="eap-list d-flex flex-wrap mt-3 mb-2">
            {allSectionItems.slice(0, visibleItems).map((sectionItem, idx) => (
              <div className="eap-item text-capitalize" key={idx}>
                <Link
                  to={{
                    pathname: sectionItem.path,
                    state: { id: sectionItem.id },
                  }}
                >
                  #
                  {capitalizeContentInBrackets(
                    sectionItem?.displayCommonName
                      ? sectionItem?.displayCommonName
                      : sectionItem?.scientificName || sectionItem?.name,
                  )}
                </Link>
              </div>
            ))}
            {allSectionItems.length > visibleItems && (
              <div className="eap-item  text-capitalize">
                <Link>...</Link>
              </div>
            )}
          </div>
          {renderFiles(item?.files, item)}
          <div
            className={`w-iconlist d-flex flex-wrap align-items-center justify-content-between ${
              item?.files?.length == 0 ? "mt-3" : ""
            }`}
          >
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div
                  className="w-iconl-box"
                  onClick={() => {
                    if (!profile?.profile) {
                      history.push("/login");
                    } else {
                      setSelectedItem(item);
                      toggleShowReport();
                    }
                  }}
                >
                  <img src={wIcon} alt="w icon 1" />
                </div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div
                  className="w-iconl-box"
                  onClick={() => {
                    if (!profile?.profile) {
                      history.push("/login");
                    } else {
                      setSelectedItem(item);
                      togglePost();
                    }
                  }}
                >
                  <img src={wIcon2} alt="w icon 2" />
                </div>
                <div className="w-textl-box">{item?.commentCount == 0 ? "" : item?.commentCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div className="w-iconl-box">
                  <img
                    src={item?.isLiked ? liked : wIcon3}
                    alt="w icon 3"
                    onClick={() => {
                      if (!profile?.profile) {
                        history.push("/login");
                      } else {
                        likeAndUnlikePost(item?.isLiked ? "unlike" : "like", item?._id);
                      }
                    }}
                  />
                </div>
                <div className="w-textl-box">{item?.likeCount == 0 ? "" : item?.likeCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <div className="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
                <div className="w-iconl-box">
                  <img src={wIcon4} alt="w icon 4" />
                </div>
                <div className="w-textl-box">{item?.viewCount == 0 ? "" : item?.viewCount}</div>
              </div>
            </div>
            <div className="w-iconlist-item">
              <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
                <Dropdown.Toggle as="span" id="dropdown-basic" className="w-iconl-box dropdown-toggle">
                  <img
                    src={wIcon5}
                    alt="w icon 5"
                    onClick={(e) => {
                      if (!profile?.profile) {
                        history.push("/login");
                      }
                    }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu share-post">
                  <FacebookShareButton
                    url={getShareUrl(item?.slug)}
                    description={item?.description}
                    className="custom-share-post"
                  >
                    <Dropdown.Item className="dropdown-item" eventKey={"1"}>
                      <Link to="#" className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                        <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.7974 16.1444V10.7915H12.5942L12.8637 8.70543H10.7974V7.37352C10.7974 6.76958 10.9652 6.35797 11.8313 6.35797H12.936V4.49269C12.4013 4.43612 11.8639 4.40872 11.3263 4.41061C9.73351 4.41061 8.64314 5.38274 8.64314 7.16813V8.70543H6.8418V10.7915H8.64314V16.1444H10.7974Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>

                        <div className="dropdown-item-text custom-share-dropdown-text">Facebook</div>
                      </Link>
                    </Dropdown.Item>
                  </FacebookShareButton>
                  <TwitterShareButton url={getShareUrl(item?.slug)} className="custom-share-post">
                    <Dropdown.Item className="dropdown-item" eventKey={"2"}>
                      <Link to="#" className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                        <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.0317 5L8.05085 11.6187L3 17H4.13674L8.55875 12.2886L12.1316 17H16L10.6984 10.009L15.3997 5H14.263L10.1905 9.33911L6.90008 5H3.0317ZM4.70336 5.8258H6.48051L14.3281 16.1741H12.5509L4.70336 5.8258Z"
                              fill="currentColor"
                            />
                          </svg>
                        </div>

                        <div className="dropdown-item-text custom-share-dropdown-text">Twitter</div>
                      </Link>
                    </Dropdown.Item>
                  </TwitterShareButton>
                  <Dropdown.Item className="dropdown-item" eventKey={"3"}>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedItem(item);
                        toggleDmPosts();
                      }}
                      className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                    >
                      <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14 7.83325C15.1046 7.83325 16 6.93782 16 5.83325C16 4.72868 15.1046 3.83325 14 3.83325C12.8954 3.83325 12 4.72868 12 5.83325C12 6.93782 12.8954 7.83325 14 7.83325Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6 12.5C7.10457 12.5 8 11.6046 8 10.5C8 9.39543 7.10457 8.5 6 8.5C4.89543 8.5 4 9.39543 4 10.5C4 11.6046 4.89543 12.5 6 12.5Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 17.1666C15.1046 17.1666 16 16.2712 16 15.1666C16 14.0621 15.1046 13.1666 14 13.1666C12.8954 13.1666 12 14.0621 12 15.1666C12 16.2712 12.8954 17.1666 14 17.1666Z"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.72656 11.5066L12.2799 14.1599"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.2732 6.83997L7.72656 9.4933"
                            stroke="currentColor"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="dropdown-item-text">Direct</div>
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <HomeImageGallery show={showModal} handleClose={closeModal} items={galleryItems} startIndex={startIndex} />
    </div>
  );
};

export default PostGalleryModal;
