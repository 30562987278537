import React from "react";
import _ from "lodash";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";

const ListingPaginations = ({ setPage, page, totalPages }) => {
  const isMobile = useCheckMobileScreen();
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) setPage(newPage);
  };

  const pageRange = isMobile ? 1 : 2;

  let startPage = Math.max(page - pageRange, 1);
  let endPage = Math.min(page + pageRange, totalPages);

  if (endPage - startPage < pageRange * 2) {
    if (startPage === 1) {
      endPage = Math.min(startPage + pageRange * 2, totalPages);
    } else if (endPage === totalPages) {
      startPage = Math.max(endPage - pageRange * 2, 1);
    }
  }

  const pages = _.range(startPage, endPage + 1);

  return (
    <div
      className={`table-pagination d-flex flex-wrap w-100 mt-0 ${
        isMobile ? "justify-content-center" : "justify-content-end"
      }`}
    >
      <div className="page-item">
        <a
          className={page === 1 ? "page-link disabled _cursor" : "page-link _cursor"}
          aria-label="Previous"
          onClick={() => handlePageChange(page - 1)}
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M7 13L1 7L7 1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
      </div>

      <ul className="pagination d-flex flex-wrap justify-content-start">
        {startPage > 1 && (
          <>
            <li className="page-item" onClick={() => handlePageChange(1)}>
              <a className="page-link _cursor">1</a>
            </li>
            {startPage > 2 && <li className="page-item">...</li>}
          </>
        )}

        {pages.map((pageNumber) => (
          <li key={pageNumber} className={pageNumber === page ? "active page-item" : "page-item"}>
            <a className="page-link _cursor" onClick={() => handlePageChange(pageNumber)}>
              {pageNumber}
            </a>
          </li>
        ))}

        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <li className="page-item">...</li>}
            <li className="page-item" onClick={() => handlePageChange(totalPages)}>
              <a className="page-link _cursor">{totalPages}</a>
            </li>
          </>
        )}
      </ul>

      <div className="page-item">
        <a
          className={page === totalPages ? "page-link disabled _cursor" : "page-link _cursor"}
          aria-label="Next"
          onClick={() => handlePageChange(page + 1)}
        >
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none">
            <path d="M1 13L7 7L1 1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default ListingPaginations;
