import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEnvironmentalistDetails,
  environmentalistListings,
  getEnvLiveFeeds,
  getSpeciesEnv,
  getOrganizationEnv,
  getRegionEnv,
} from "../../store/environmentalist";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Detail from "../../components/environmentalistDetail/detail";
import Species from "../../components/environmentalistDetail/species";
import Organization from "../../components/environmentalistDetail/organization";
import Regions from "../../components/environmentalistDetail/regions";
import { getMisc, getReferences, getSetting } from "../../store/misc";
import App from "../../common/maps/map";
import UserUpload from "../../components/common/userUpload/userUpload";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetterWord,
  cleanAndFormatHtml,
} from "../../utils/helperFunctions";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import shareSvg from "../../include/images/share.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ShareModal from "../../components/common/sharemodal/shareModal";
import editModeIcon from "../../include/images/edit.svg";
import { getProfile } from "../../utils/localStorageServices";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import defaultEnvImg from "../../include/images/defaultEnvImg.png";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { updateYourProfile } from "../../store/users";

const EnvironmentalistDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("1");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  let envCitation =
    props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.environmentalCitation;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const profile = getProfile();
  const [isEdit, setIsEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [follow, setFollow] = useState(false);
  const pathSegments = url.pathname.split("/");
  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if(location?.state == "Environmental Press Corp"){
      setMenuItem("8")
    }
    if (location?.state?.id) {
      const id = location?.state?.id;
      setId(id);
      setIsEdit(profile?.profile?.interestedEnvironmentalists?.includes(id));
      if (profile?.profile?.preferredEnvironmentalists?.includes(id)) {
        setFollow(true);
      }
      setLoadingDetail(true);
      props.getEnvironmentalistDetails(id, (res) => {
        if (res && res.status == 200) {
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getEnvironmentalistDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setId(res?.data?.data?._id);
          setIsEdit(profile?.profile?.interestedEnvironmentalists?.includes(res?.data?.data?._id));
          if (profile?.profile?.preferredEnvironmentalists?.includes(id)) {
            setFollow(true);
          }
          setLoadingDetail(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (id) {
      props.getSpecies(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getEnvLiveFeeds({ id: id, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getOrganizationEnv(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getRegionEnv(id, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: id,
        page: 1,
        section: "environmentalist",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);
  const details = props.environmentalistListings && props.environmentalistListings.environmentalistDetail;
  const organizations =
    props.environmentalistListings.environmentalistDetail &&
    props.environmentalistListings.environmentalistDetail.data &&
    props.environmentalistListings.environmentalistDetail.data.organizations;
  const species = props.environmentalistListings && props.environmentalistListings.species;
  const regions =
    props.environmentalistListings.environmentalistDetail &&
    props.environmentalistListings.environmentalistDetail.data &&
    props.environmentalistListings.environmentalistDetail.data.regions;

  const liveFeeds = props.environmentalistListings && props.environmentalistListings.liveFeeds;

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);

  Useauthredirect();
  const combinedData = [
    ...(organizations || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "organizations",
      image: item?.coverImage?.small,
    })),
    ...(species?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
      image: item?.coverImage?.small,
    })),
  ];
  const envMapData = [
    {
      id: details.data?._id,
      name: details.data?.name,
      coordinates: details.data?.geoLocation,
      slug: details.data?.slug,
      section: "environmentalists",
      image: details.data?.detailTabImage?.small,
    },
  ];

  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "1", target: "#pills-one", label: "Details", count: null, data: [1, 2] },
    { id: "2", target: "#pills-two", label: "Species", count: species?.totalCount, data: species?.data },
    {
      id: "3",
      target: "#pills-three",
      label: "Organizations & Donating",
      count: details?.data?.organizations?.length,
      data: details?.data?.organizations,
    },
    {
      id: "4",
      target: "#pills-four",
      label: "Region",
      count: details?.data?.regions?.length,
      data: details?.data?.regions,
    },
    // { id: "5", target: "#pills-five", label: 'Media', count: null, data: [1, 2] },
    { id: "6", target: "#pills-six", label: "Live Feeds", count: liveFeeds?.totalCount, data: liveFeeds?.data },
    { id: "7", target: "#pills-seven", label: "Map", count: null, data: [1, 2] },
    { id: "8", target: "#pills-eight", label: "Media and Posts", count: null, data: [1, 2] },
    { id: "9", target: "#pills-nine", label: "References", count: null, data: references },
  ];
  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
  };
  const handleEnvFollow = (e) => {
    e.preventDefault();
    const payload = {
      preferredEnvironmentalists: [details?.data?._id, ...(profile?.profile?.preferredEnvironmentalists || [])],
    };
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        toast(<AlertSuccess message="You are now following this Environmentalist." />);
        setFollow(true);
      }
    });
  };
  return (
    <PageLayout>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <main id="main">
        <div class="content-container overflow-hidden pt-4">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="org-detail-main d-flex flex-wrap align-items-start mt-2">
                  {loadingDetail ? (
                    <div class="od-logo">
                      {" "}
                      <div class="od-logo-inner">
                        <Skeleton borderRadius={"10px"} width={"100%"} height={"100%"} />
                      </div>
                    </div>
                  ) : (
                    <div class="od-logo">
                      <div class="od-logo-inner">
                        <img
                          src={
                            details?.data?.coverImage
                              ? process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original
                              : defaultEnvImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  <div class="od-content">
                    <div className="d-flex align-items-center justify-content-between">
                      <span class="text-title text-green">Environmentalist</span>
                      {profile?.profile && (
                        <div className="ms-5">
                        <button
                          onClick={!follow ? handleEnvFollow : undefined}
                          className="btn btn-default h-40"
                        >
                          {follow ? "Following" : "Follow"}
                        </button>
                      </div>
                      )}
                    </div>
                    <div class="od-content-heading d-flex">
                      {loadingDetail ? (
                        <Skeleton borderRadius={"10px"} height={40} width={200} />
                      ) : (
                        <>
                          <h1 className="caps-text">
                            <span>
                              {details && details?.data ? `${capitalizeContentInBrackets(details?.data?.name)}` : ""}
                            </span>{" "}
                            <div class="od-content-icon" style={{ marginRight: "40px" }}>
                              {/* <Link onClick={() => setOpenShareModal(true)}><img
                              src={shareSvg} alt="" /></Link> */}
                              {isEdit && (
                                <Link
                                  onClick={() => {
                                    setEditMode(true);
                                  }}
                                >
                                  <img src={editModeIcon} alt="" />
                                </Link>
                              )}
                            </div>
                          </h1>
                        </>
                      )}
                    </div>
                    <ul class="page-detail-head-list">
                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Movement</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : (
                            <p className="caps-text">
                              {details && details?.data && details?.data?.movements?.length > 0
                                ? details?.data?.movements.map((item, idx) =>
                                    idx <= 1 ? (item?.name !== "" ? item?.name : "-") : null,
                                  )
                                : "-"}
                            </p>
                          )}
                        </div>
                      </li>
                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Years active</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : details?.data?.yearsActive && details?.data?.yearsActive != "N/A" ? (
                            <p>{details?.data?.yearsActive}</p>
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </li>
                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Organizations Founded</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : (
                            <p className="caps-text">
                              {details && details?.data && details?.data?.foundedOrganizations?.length > 0
                                ? details?.data?.foundedOrganizations?.slice(0, 2).map((item, idx) => (
                                    <span key={idx}>
                                      {item?.name !== "" ? item?.name : "-"}
                                      {idx < details?.data?.foundedOrganizations?.slice(0, 2).length - 1 ? ", " : ""}
                                    </span>
                                  ))
                                : "-"}
                            </p>
                          )}
                        </div>
                      </li>
                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Awards</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : details?.data?.awards?.length ? (
                            <p>{details?.data?.awards?.length}</p>
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </li>

                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Working in Region</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : (
                            <p>
                              {details && details?.data && details?.data?.regions?.length > 0
                                ? details?.data?.regions?.map((item, idx) =>
                                    idx <= 1 ? (
                                      <div key={idx}>
                                        {(item &&
                                          (item?.countryName === "United States"
                                            ? `${item?.state}, ${item?.countryName}`
                                            : item?.countryName)) ||
                                          "-"}
                                      </div>
                                    ) : null,
                                  )
                                : "-"}
                            </p>
                          )}
                        </div>
                      </li>

                      <li class="page-detail-head-item">
                        <div class="page-detail-head-box">
                          <small>Social media</small>
                          {loadingDetail ? (
                            <p>
                              <Skeleton borderRadius={"10px"} height={20} width={150} />
                            </p>
                          ) : (
                            <div class="ltb-icon-list d-flex flex-wrap">
                              {details?.data?.socialMediaLink?.facebook &&
                                details?.data?.socialMediaLink?.facebook != " " && (
                                  <div class="ltb-icon-item">
                                    <a
                                      href={details.data.socialMediaLink.facebook}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="ltb-icon-box"
                                    >
                                      <i class="fab fa-facebook-f"></i>
                                    </a>
                                  </div>
                                )}
                              {details?.data?.socialMediaLink?.twitter &&
                                details?.data?.socialMediaLink?.twitter != " " && (
                                  <div class="ltb-icon-item">
                                    <a
                                      href={details.data.socialMediaLink.twitter}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="ltb-icon-box"
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                      </svg>
                                    </a>
                                  </div>
                                )}
                              {details?.data?.socialMediaLink?.linkedin &&
                                details?.data?.socialMediaLink?.linkedin != " " && (
                                  <div class="ltb-icon-item">
                                    <a
                                      href={details.data.socialMediaLink.linkedin}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="ltb-icon-box"
                                    >
                                      <i class="fab fa-linkedin"></i>
                                    </a>
                                  </div>
                                )}
                              {details?.data?.socialMediaLink?.instagram &&
                                details?.data?.socialMediaLink?.instagram != " " && (
                                  <div class="ltb-icon-item">
                                    <a
                                      href={details.data.socialMediaLink.instagram}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      class="ltb-icon-box"
                                    >
                                      <i class="fab fa-instagram"></i>
                                    </a>
                                  </div>
                                )}
                              {details?.data?.socialMediaLink?.facebook &&
                                details?.data?.socialMediaLink?.facebook == " " &&
                                details?.data?.socialMediaLink?.twitter &&
                                details?.data?.socialMediaLink?.twitter == " " &&
                                details?.data?.socialMediaLink?.linkedin &&
                                details?.data?.socialMediaLink?.linkedin == " " &&
                                details?.data?.socialMediaLink?.instagram &&
                                details?.data?.socialMediaLink?.instagram == " " && (
                                  <div class="ltb-icon-item">
                                    <span>-</span>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loadingDetail ? (
          <div class="tabs-block-container">
            <div class="tbc-head ">
              <Skeleton
                borderRadius={"10px"}
                height={"7.5vh"}
                baseColor="var(--dark-green-color)"
                highlightColor="#0cb40c"
              />
            </div>
            <div class="container mt-5">
              {" "}
              <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
            </div>
          </div>
        ) : (
          <div class="tabs-block-container new-tabs-block-container">
            <div class="tbc-head ">
              <div class="container">
                <div class="row">
                  <div className="col-md-12">
                    <form className="d-lg-none">
                      <select
                        className="form-select"
                        id="tabSelector"
                        onChange={(e) => handleMenuItemClick(e.target.value)}
                        value={menuItem}
                      >
                        {items.map(
                          (item) =>
                            (editMode || item.data?.length > 0) && (
                              <option key={item.id} value={item.id}>
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </option>
                            ),
                        )}
                      </select>
                    </form>
                    <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                      {items.map(
                        (item) =>
                          (editMode || item.data?.length > 0) && (
                            <li className="nav-item" key={item.id} role="presentation">
                              <div
                                className={`nav-link ${menuItem === item.id && "active"}`}
                                id={`pills-${item.id}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${item.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${item.id}`}
                                aria-selected={menuItem === item.id}
                                onClick={() => handleMenuItemClick(item.id)}
                              >
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tbc-body overflow-hidden">
              <div class="tab-content tab-content-slider" id="pills-tabContent">
                {menuItem == "1" && (
                  <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                    <Detail menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                  </div>
                )}
                {menuItem == "2" && (
                  <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                    <Species
                      page="environmentalist"
                      menuItem={menuItem}
                      id={id}
                      setSelectedId={setSelectedId}
                      editMode={editMode}
                    />
                  </div>
                )}
                {menuItem == "3" && (
                  <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                    <Organization menuItem={menuItem} id={id} editMode={editMode} />
                  </div>
                )}

                {menuItem == "4" && (
                  <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                    <Regions menuItem={menuItem} id={id} setSelectedId={setSelectedId} editMode={editMode} />
                  </div>
                )}
                {menuItem == "5" && (
                  <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                    <UserUpload
                      menuItem={menuItem}
                      id={details.data._id}
                      setSelectedId={setSelectedId}
                      section="environmentalist"
                    />
                  </div>
                )}
                {menuItem == "6" && (
                  <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                    <LiveFeeds
                      menuItem={menuItem}
                      id={details.data._id}
                      setSelectedId={setSelectedId}
                      section="environmentalist"
                    />
                  </div>
                )}
                {menuItem == "7" && (
                  <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                    <div className="container">
                      <div class="map-hero-detail-page region-detail-map">
                        <div class="map-hero-image map-wet-market">
                          {combinedData && (
                            <App
                              data={combinedData}
                              data1={envMapData}
                              mapType="detail-page"
                              isMultiMarker={true}
                              zoom={"zoom"}
                              singleMap={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {menuItem == "8" && (
                  <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                    <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="environmentalist" />
                  </div>
                )}
                {menuItem == "9" && (
                  <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                    <References
                      menuItem={menuItem}
                      id={id}
                      setSelectedId={setSelectedId}
                      section="environmentalist"
                      isEdit={editMode}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ShareModal
          show={openShareModal}
          onHide={() => setOpenShareModal(!openShareModal)}
          name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
          id={details?.data?._id}
          category="environmentalist"
        />
        <div className="container custom-citation mb-4">
          <div className="custom-citation-text">Citations</div>
          {miscSettingCitation && (
            <div
              className="custom-citation-text"
              dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
              style={{ marginBottom: 0 }}
            ></div>
          )}
          {envCitation && (
            <div
              className="custom-citation-text"
              dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(envCitation) }}
              style={{ marginTop: 0 }}
            ></div>
          )}
        </div>
      </main>
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getEnvironmentalistDetails: (params, callback) => dispatch(getEnvironmentalistDetails(params, callback)),
  getEnvLiveFeeds: (params, callback) => dispatch(getEnvLiveFeeds(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getSpecies: (params, callback) => dispatch(getSpeciesEnv(params, callback)),
  getOrganizationEnv: (params, callback) => dispatch(getOrganizationEnv(params, callback)),
  getRegionEnv: (params, callback) => dispatch(getRegionEnv(params, callback)),
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
});

const mapStateToProps = (state) => ({
  environmentalistListings: environmentalistListings(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(EnvironmentalistDetail));
