import React from "react";
import Linkify from "react-linkify";

const DescriptionWithLink = ({ description }) => {
  // Custom behavior for links
  const componentDecorator = (href, text, key) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={key}>
      {text}
    </a>
  );

  return (
    <div>
      <Linkify componentDecorator={componentDecorator}>
        {description}
      </Linkify>
    </div>
  );
};

export default DescriptionWithLink;
