import React from "react";
import { Link } from "react-router-dom";

const StepOne = (props) => {
  return (
    <div class="login-container fw-medium">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="login-content-holder mx-auto">
              <div class="login-head-row">
                <h1>Forgot Password?</h1>
                <p>
                  Simply enter your email address and you'll receive an email to
                  reset your password shortly.
                </p>
              </div>
              <form class="login-form-row-group">
                <div class="form-floating-row d-flex flex-wrap">
                  <div class="ff-column">
                    <div class="form-floating custom-form-floating">
                      <input
                        type="email"
                        class="form-control"
                        id="emailAddress"
                        placeholder="Email Address"
                        value={props?.email}
                        onChange={(e) => props?.setEmail(e.target.value)}
                      />
                      <label for="emailAddress">Email Address</label>
                    </div>
                  </div>
                </div>

                <div class="login-form-btn d-flex align-items-center justify-content-end">
                  <a
                    href="javascript:void(0);"
                    class={`btn btn-default ${
                      props?.loading || !props?.email ? "disabled" : ""
                    }`}
                    onClick={() => props?.handleSendCode()}
                  >
                    Submit
                  </a>
                </div>
                <div class="login-link">
                  <Link to="/login">Back to login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
