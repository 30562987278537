import React from "react";
import noRecord from "../../include/images/nrb-img.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import noPost from "../../include/images/pew-media-icon.svg";
import ReactPlayer from "react-player";
import Skeleton from "react-loading-skeleton";

const Media = (props) => {
  const data = props?.mediaData?.data;

  return (
    <div className="widget-media-block d-flex flex-wrap">
      {props.loading ? (
        <ul className="d-flex flex-wrap">
          {[1, 2, 3, 4, 5, 6].map(() => (
            <li className="ei-grid-item position-relative ei-grid-item-sponsor">
              <div className="ei-grid-box">
                <Skeleton height={150} width={200} className="" />
              </div>
            </li>
          ))}
        </ul>
      ) : data && data.length > 0 ? (
        data.map((item) =>
          item.files.map((file, index) => (
            <div className="wmb-item" key={item._id}>
              <div
                className="wmb-img-box w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden"
                key={index}
              >
                {file?.original && file?.original.startsWith("https") ? (
                  <ReactPlayer className="w-100 react-player-iframe" url={file?.original} controls={true} />
                ) : ["mp4", "mpeg", "MP4", "webm", "video"].includes(file.mediaType) ? (
                  <video
                    controls
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  >
                    <source src={process.env.REACT_APP_MEDIA + file.original} />
                  </video>
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={process.env.REACT_APP_MEDIA + file.original}
                    alt=""
                  />
                )}
              </div>
            </div>
          )),
        )
      ) : (
        <div className="panel-body flex-grow-1">
          <div className="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
            <div className="pew-icon icon-lg">
              <img src={noPost} alt="pew feed icon" />
            </div>
            <h5>No Media Found</h5>
            <p className="mb-2">
              Looks like there are no media files posted yet. Feel free to share photos, videos, or any other media
              you'd like to add to the conversation! via post.
            </p>
            <Link to={"/add-post"}>
              <button className="btn btn-default btn-block">Add Post</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Media;
