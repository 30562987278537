import React, { useEffect, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { getMisc, searchAll, searchAllTypeReceived } from "../../store/misc";
import AlertError from "../../common/alerts/alertError";
import PageInfoSidebar from "../../components/common/pageInfoSidebar/pageInfoSidebar";
import SearchListCard from "../../components/common/listingCard/searchListCard";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import leftArrow from "../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../include/images/pagination-right-arrow-green.svg";
import { sideBarApisListings } from "../../store/sidebarApis";
import noRecord from "../../include/images/nrb-img.svg";
import ListingPaginations from "../../components/common/Pagination/listingPaginations";

const Search = (props) => {
  const [page, setPage] = useState(1);
  const [loadingMain, setLoadingMain] = useState(false);
  const [detail, setDetail] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState();
  const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const textTobesearch = decodeURIComponent(pathSegments[pathSegments.length - 1]);
  const queryStart = location.search.indexOf("category=");
  let category = "";
  if (queryStart !== -1) {
    const categoryParam = location.search.slice(queryStart + "category=".length);
    category = decodeURIComponent(categoryParam);
  }
  const details = props.misc && props.misc?.searchAllData && props.misc.searchAllData.data;
  const USRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country === "US");
  const otherRegion =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.regionList &&
    props?.sideBarApisListings?.regionList?.data?.filter((item) => item.country !== "US");
  const blogCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.blogCategoryList &&
    props?.sideBarApisListings?.blogCategoryList.data;
  // const [blogCategory, setBlogCategory] = useState([]); scienceAndEducationCategory
  const newsCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.newsCategoryList &&
    props?.sideBarApisListings?.newsCategoryList.data;
  const speciesCategories =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.speciesCategory &&
    props?.sideBarApisListings?.speciesCategory.data;
  const scienceAndEducationCategory =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.scienceAndEducationCategory &&
    props?.sideBarApisListings?.scienceAndEducationCategory.data;
  const organizationList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.organizationList &&
    props?.sideBarApisListings?.organizationList.data;
  const environmentalistList =
    props?.sideBarApisListings &&
    props?.sideBarApisListings?.environmentalist &&
    props?.sideBarApisListings?.environmentalist.data;
  const zooAndParksList =
    props?.sideBarApisListings && props?.sideBarApisListings?.zoo && props?.sideBarApisListings?.zoo.data;
  const wetmarketList =
    props?.sideBarApisListings && props?.sideBarApisListings?.wetMarket && props?.sideBarApisListings?.wetMarket.data;

  useEffect(() => {
    if (textTobesearch) {
      fetchData(textTobesearch);
    }
  }, [textTobesearch, category, page]);
  const fetchData = (searchText) => {
    const payload = {
      keyword: searchText,
      page,
    };

    setLoadingMain(true);
    props.searchAll(payload, (res) => {
      if (res && res.status === 200) {
        let tempDetail;

        switch (category) {
          case "Species":
            tempDetail = res.data.data?.filter((item) => item.type === "species");
            break;
          case "Region":
            tempDetail = res.data.data?.filter((item) => item.type === "region");
            break;
          case "Organization":
            tempDetail = res.data.data?.filter((item) => item.type === "organization");
            break;
          case "Zoos & Wildlife Reserves":
            tempDetail = res.data.data?.filter((item) => item.type === "zoo");
            break;
          case "War on the Environment- Threats":
            tempDetail = res.data.data?.filter((item) => item.type === "wetMarket");
            break;
          case "Environmentalists":
            tempDetail = res.data.data?.filter((item) => item.type === "environmentalist");
            break;
          case "Users":
            tempDetail = res.data.data?.filter((item) => item.type === "user");
            break;
          default:
            tempDetail = res.data.data;
            break;
        }

        setDetail(tempDetail);
        props?.searchAllTypeReceived({ data: tempDetail, recordsPerPage: 52, totalCount: res.data.totalCount });
        setLoadingMain(false);
      } else {
        setLoadingMain(false);
        props.toast(<AlertError message={res?.data?.message || "Something Went Wrong"} />);
      }
    });
  };
  const totalPages = props?.misc?.searchAllData?.totalCount || 0;
  const dataPerPage = props?.misc?.searchAllData?.recordsPerPage || 0;
  const noOfPage = Math.ceil(totalPages / dataPerPage);
  return (
    <PageLayout>
      <div class="content-container">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="ei-category-container d-flex flex-wrap">
                <div class="ei-category-left">
                  <PageInfoSidebar
                    page={page}
                    setPage={setPage}
                    USRegions={USRegion}
                    otherRegion={otherRegion}
                    blogCategories={blogCategory}
                    newsCategory={newsCategory}
                    setShowMenu={setShowMenu}
                    showMenu={showMenu}
                    speciesCategories={speciesCategories}
                    zooAndParksList={zooAndParksList}
                    organizationList={organizationList}
                    environmentalistList={environmentalistList}
                    wetmarketList={wetmarketList}
                    scienceAndEducationCategory={scienceAndEducationCategory}
                    selectSpeciesCategory={selectSpeciesCategory}
                    setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                    setSelectedSpeciesCategoryName={setSelectedSpeciesCategoryName}
                    selectSpeciesCategoryName={selectSpeciesCategoryName}
                  />
                </div>
                <div class="ei-category-right">
                  <div className="ei-category-details">
                    <div className="ei-heading">
                      {details?.length === 0 ? (
                        <h4>Showing results for "{textTobesearch}"</h4>
                      ) : (
                        <h4>
                          Showing results for "{textTobesearch}" ({totalPages})
                        </h4>
                      )}
                      {details && noOfPage > 1 && (
                        <div class="pagination-row d-flex justify-content-between">
                          <ListingPaginations noOfPage={noOfPage} totalPages={noOfPage} page={page} setPage={setPage} />
                        </div>
                      )}
                    </div>
                    {details?.length !== 0 && (
                      <ul className="ei-grid-list d-flex flex-wrap">
                        {loadingMain
                          ? Array.from({ length: 52 }, (item, index) => (
                              <SearchListCard
                                key={index}
                                img={item?.coverImage?.medium}
                                name={item?.name}
                                id={item?._id}
                                loading={loadingMain}
                                slug={item?.slug}
                                type={item?.type}
                              />
                            ))
                          : details?.map((item, idx) => (
                              <SearchListCard
                                key={idx}
                                img={item?.coverImage?.medium}
                                name={item.name}
                                page="home"
                                id={item._id}
                                item={item}
                                loading={loadingMain}
                                slug={item?.slug}
                                type={item?.type}
                                handleClick={() => {}}
                              />
                            ))}
                      </ul>
                    )}
                    {details?.length === 0 && !loadingMain ? (
                      <ul className="blog-list d-flex flex-wrap justify-content-center">
                        <div className="no-records-container d-flex align-items-center justify-content-center">
                          <div className="no-records-box text-center">
                            <div className="nrb-image">
                              <img src={noRecord} alt="" />
                            </div>
                            <h6>No Records Found</h6>
                          </div>
                        </div>
                      </ul>
                    ) : (
                      details &&
                      noOfPage > 1 && (
                        <div class="pagination-row d-flex justify-content-between">
                          <ListingPaginations noOfPage={noOfPage} totalPages={noOfPage} page={page} setPage={setPage} />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchAll: (params, callback) => {
    dispatch(searchAll(params, callback));
  },
  searchAllTypeReceived: (payload) => dispatch(searchAllTypeReceived(payload)),
});

const mapStateToProps = (state) => ({
  misc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Search));
