import React from "react";
import PropTypes from "prop-types";
import Header from "../header/header";
import pageNotFound from "../../include/images/500.svg";
import { Link } from "react-router-dom";

const ErrorPage = ({ message = "Something Went Wrong", redirectPath = "/" }) => {
  return (
    <>
      <Header />
      <main className="main-content">
        <div className="content-container">
          <div className="container text-center">
            <div className="error">
              <h1 className="error-title">ERROR</h1>
              <div className="mb-4" style={{ padding: "20px" }}>
                <img
                  src={pageNotFound}
                  alt="Error illustration"
                  style={{ height: "120px" }}
                />
              </div>
              <h2 className="error-message mb-5">{message}</h2>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Link
                to={redirectPath}
                className="btn btn-default header-btn h-40 pt-10 cursor-pointer"
              >
                Back To Home
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

ErrorPage.propTypes = {
  message: PropTypes.string,
  redirectPath: PropTypes.string,
};

export default ErrorPage;
