import React from "react";
import heroImg from "../../include/images/hero-bg.png";
import dogPaw from "../../include/images/dog-paw.svg";
import birdPaw from "../../include/images/bird-paw.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Cover = (props) => {
  const isSmallScreen = window.innerWidth <= 600;
  return (
    <>
      {isSmallScreen && props?.loading ? (
        <div>
          {" "}
          <Skeleton borderRadius={"10px"} className={`centered-skeleton ${isSmallScreen ? "mt-4" : ""}`} />
        </div>
      ) : (
        <div class="hero-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="hero-container-content d-flex flex-wrap position-relative">
                  <div class="hero-container-text" style={{ width: "100%", paddingTop: "30px", paddingBottom: "15px" }}>
                    {!isSmallScreen && props?.loading ? (
                      <Skeleton borderRadius={"10px"} height={100} />
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: props.intro }} />
                      // <>
                      //   <h1 style={{ fontWeight: "700", fontSize: "40px" }}>Pick an animal and save it.</h1>
                      //   <h3 style={{ fontSize: "28px", fontWeight: "600", marginTop: "15px" }}>
                      //     Be a part of the global conservation efforts to help save 14,311 endangered species.
                      //   </h3>
                      // </>
                    )}
                  </div>
                  {/* <div class="hero-container-image">
                    {!isSmallScreen && props?.loading ? (
                      <Skeleton borderRadius={"10px"} height={700} width={"100%"} />
                    ) : (
                      <div class="hci-inner">
                        <img src={heroImg} alt="" />
                      </div>
                    )}
                  </div>
                  <div class="dog-paw">
                    {!isSmallScreen && props?.loading ? (
                      <Skeleton borderRadius={"10px"} height={50} width={50} />
                    ) : (
                      <img src={dogPaw} alt="" />
                    )}
                  </div>
                  <div class="bird-paw">
                    {!isSmallScreen && props?.loading ? (
                      <Skeleton borderRadius={"10px"} height={50} width={50} />
                    ) : (
                      <img src={birdPaw} alt="" />
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cover;
