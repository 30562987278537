import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import HTMLReactParser from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import img from "../../include/images/post-image-1.jpg";
import noRecord from "../../include/images/nrb-img.svg";
import { getZooPrograms, zooListings } from "../../store/zoo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../common/Pagination/pagination";
import { capitalizeContentInBrackets } from "../../utils/helperFunctions";
const Programs = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const details = props.zooListings && props.zooListings.zooProgram;
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  useEffect(() => {
    const params = {
      id: props.id,
      page: currentPage,
    };
    setLoading(true);
    props.getZooPrograms(params, (res) => {
      if (res && res.status == 200) {
        setLoading(false);
      }
    });
  }, [currentPage]);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };
  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };
  const pageLinks = Array.from(
    { length: Math.ceil((details?.totalCount ?? 0) / (details?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );
  return (
    <>
      <div class="container" ref={topElementRef}>
        <div class="row">
          <div class="col-md-12">
            <div class="tbc-body-inner">
              <ul class="tbc-grid-list d-flex flex-wrap">
                {loading ? (
                  [1, 2, 3].map((item) => (
                    <li class="blog-item">
                      <a href="javascript:void(0);" class="blog-box">
                        <div class="blog-box-image" style={{ paddingBottom: "0px" }}>
                          <Skeleton borderRadius={"10px"} height="173px" />
                        </div>
                        <div class="blog-box-content">
                          <div class="bbc-body">
                            <h6>
                              {" "}
                              <Skeleton borderRadius={"10px"} />
                            </h6>
                            <p>
                              <Skeleton borderRadius={"10px"} height="78px" />
                            </p>
                          </div>
                        </div>
                        <div class="bbc-footer">
                          <div class="bbc-footer-inner">
                            <p>
                              {" "}
                              <Skeleton borderRadius={"10px"} width="385px" />
                            </p>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                ) : (
                    <ul className="blog-list d-flex flex-wrap mt-0 w-auto-list" style={{ marginBottom: "40px" }}>
                      {details?.length > 0 ? (
                        details.map((detail) => (
                          <li key={detail._id} className="blog-item">
                            <Link
                              to={{
                                pathname: `/zoos-and-wildlife-reserves/${detail?.slug}/program`,
                                state: {
                                  id: props.id,
                                  name: props?.name,
                                  item: detail,
                                },
                              }}
                              className="blog-box"
                            >
                              <div className="blog-box-image">
                                {!imageError ? (
                                  <img
                                    src={process.env.REACT_APP_MEDIA + detail?.coverImage?.medium}
                                    onError={handleImageError}
                                    alt=""
                                  />
                                ) : (
                                  <img src={process.env.REACT_APP_MEDIA + detail?.coverImage?.large} alt="" />
                                )}
                              </div>
                              <div className="blog-box-content">
                                <div className="bbc-body">
                                  <h6>{`${capitalizeContentInBrackets(detail.name)}`}</h6>
                                  <p>{detail.description}</p>
                                </div>
                              </div>
                              <div className="bbc-footer">
                                <div className="bbc-footer-inner">
                                  <p>{new Date(detail.createdAt).toLocaleDateString()}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))
                      ) : (
                        <ul className="ei-grid-list d-flex flex-wrap justify-content-center">
                          <div className="no-records-container d-flex align-items-center justify-content-center">
                            <div className="no-records-box text-center">
                              <div className="nrb-image">
                                <img src={noRecord} alt="No Records" />
                              </div>
                              <h6>No Records Found</h6>
                            </div>
                          </div>
                        </ul>
                      )}
                    </ul>
                )}
                {details && details?.totalCount > 52 && (
                  <Pagination
                    handlePageClick={handlePageClick}
                    currentPage={currentPage}
                    pageLinks={pageLinks}
                    totalPages={pageLinks.length}
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// Saber-Toothed Tiger
const mapDispatchToProps = (dispatch) => ({
  getZooPrograms: (params, callback) => dispatch(getZooPrograms(params, callback)),
});

const mapStateToProps = (state) => ({
  zooListings: zooListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Programs));
