import React from "react";
import Loader from "../../common/loader/loader";
import { ColorRing } from "react-loader-spinner";
import ImageHolder from "../../common/customStyles/imageHolder";
import noItem from "../../include/images/pew-follower-icon.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";

const FollowersAndFollowingList = ({
  data,
  tab,
  loading,
  page,
  setPage,
  totalPages,
  handleFollow,
}) => {
  return (
    <div class="widget-tab-content">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane mh-300 fade show active" id="widgetTab1">
          <div class="widget-tab-content-box">
            <div class="widget-member-list">
              {loading ? (
                [1, 2, 3, 4]?.map((item) => (
                  <div class="widget-member-item">
                    <div class="widget-member-row d-flex flex-wrap align-items-center justify-content-between">
                      <div class="widget-member-list w-100 d-flex flex-wrap align-items-center">
                        <Skeleton height="40px" borderRadius={"10px"} />
                      </div>
                    </div>
                  </div>
                ))
              ) : data && data?.length > 0 ? (
                data?.map((item) => (
                  <div class="widget-member-item">
                    <div class="widget-member-row d-flex flex-wrap align-items-center justify-content-between">
                      <div class="widget-member-list w-100 d-flex flex-wrap align-items-center">
                        <div class="widget-member-img-box">
                          <ImageHolder
                            image={item?.profilePicture?.small}
                            name={item?.firstName}
                          />
                        </div>
                        <div class="widget-member-text-box d-flex flex-wrap align-items-center justify-content-between">
                          <div class="widget-member-text text-capitalize">
                            {item?.firstName} {item?.lastName}
                          </div>
                          {tab == "following" ? (
                            <div
                              class="widget-member-btn"
                              onClick={() => handleFollow(item?._id)}
                            >
                              <a href="javascript:void(0);" class="btn btn-grey btn-block">
                                Following
                              </a>
                            </div>
                          ) : (
                            <div
                              class="widget-member-btn"
                              onClick={() => handleFollow(item?._id)}
                            >
                              <a href="javascript:void(0);" class="btn btn-default btn-block">
                                Follow Back
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                data?.length == 0 &&
                !loading && (
                  <div class="widget-tab-content no-box-shadow">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade active show"
                        id="widgetTab1"
                        role="tabpanel"
                        aria-labelledby="widgetPane1"
                      >
                        <div class="widget-tab-content-box">
                          <div class="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
                            <div class="pew-icon">
                              <img src={noItem} alt="pew media icon" />
                            </div>
                            <h5>
                              No{" "}
                              {tab == "following" ? "Followings" : "Followers"}{" "}
                              Found
                            </h5>
                            {tab == "following" ? <><p className="mb-2">It looks like you're not following anyone yet. Why not start connecting with others and discovering interesting content? Explore new profiles and see what catches your interest. Happy exploring!
                            </p>
                              <Link to={"/post"}><button className="btn btn-default btn-block">
                                Go To Posts
                              </button>
                              </Link></> : <><p className="mb-2">Looks like you're the first to pave the way! Start sharing your unique content and watch your follower count grow. Remember, every journey begins with a single step.
                              </p>
                              <Link to={"/add-post"}>
                                <button className="btn btn-default btn-block">
                                  Add Post
                                </button>
                              </Link></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              {!loading && page < totalPages && (
                <div className="more-button">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-default more-btn"
                    onClick={() => setPage(page + 1)}
                  >
                    View More
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowersAndFollowingList;
