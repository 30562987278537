import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import IndexRegion from "./pages/region/index-region";
import RegionListing from "./pages/region/region-listing";
import RegionDetail from "./pages/region/region-detail";
import SpeciesListing from "./pages/species/species-listing";
import Organizations from "./pages/organizations/organizations";
import TermsAndCondition from "./pages/termsAndCondition";
import PrivacyPolicy from "./pages/privacyPolicy";
import ZooListing from "./pages/zoo/zooListing";
import WetMarket from "./pages/wetMarket/wetMarket";
import SpeciesDetail from "./pages/species/species-detail";
import DonorsListing from "./pages/species/donors-listing";
import ScienceAndEducationListing from "./pages/scienceAndEducation/scienceAndEducation-listing";
import ScienceAndEducation from "./pages/scienceAndEducation/scienceAndEducation-detail.jsx";
import EnvironmentalistList from "./pages/environmentalist/environmentalist-list";
import EnvironmentalistDetail from "./pages/environmentalist/environmentalist-detail";
import PageNotFound from "./components/error/pageNotFound";
import OrganizationDetail from "./pages/organizations/organization-detail";
import ProgramDetailPage from "./components/organizationDetail/programDetailPage";
import Login from "./components/user/login";
import UpdateProfile from "./components/user/update-profile.jsx";
import IndexFeed from "./pages/feed/feed.jsx";
import UserProfile from "./pages/feed/user-profile";
import EditUserProfile from "./pages/feed/edit-profile";
import AddPost from "./components/common/post/add-post";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ResetPasswordSuccessful from "./pages/forgotPassword/resetPasswordSuccessful";
import Followers from "./pages/profile/followers";
import ZooDetail from "./pages/zoo/zoo-detail.jsx";
import ProgramDetailZooPage from "./components/zooSubTabs/ProgramDetailZooPage.jsx";
import WetMarketDetail from "./pages/wetMarket/wetMarket-detail.jsx";
import LiveFeedsListing from "./pages/liveFeeds/live-feeds-listing.jsx";
import Auth from "./components/user/auth.jsx";
import Splash from "./pages/splash/splash.jsx";
import ChangePassword from "./pages/feed/changePassword.jsx";
import VolunteerPreference from "./pages/volunteer/volunteerPreference.jsx";
import Posts from "./pages/feed/post.jsx";
import HomeIndex from "./pages/home.jsx";
import RegisterUserType from "./components/user/registerUserType.jsx";
import Greeting from "./pages/greetings/greeting.jsx";
import GuestProfile from "./pages/profile/guestProfile.jsx";
import AddSponsor from "./pages/subscription/addSponsor.jsx";
import CreateStory from "./pages/subscription/createStory.jsx";
import About from "./pages/about/about.jsx";
import Sustainability from "./pages/Sustainability/sustainability.jsx";
import Search from "./pages/search/search.jsx";
import ErrorPage from "./components/error/pafeForError.jsx";
import EnvironmentalPressCorp from "./pages/envPressCorp/envPressCorp.jsx";
import SponsorsListing from "./pages/subscription/sponsorsListing.jsx";
import SampleSustainability from "./sustainabilityPage/index.jsx";
import ContactUs from "./pages/contact/contactUs.jsx";
import SustainabilityDetail from "./pages/feed/sustainabilityDetail.jsx";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/">
          <HomeIndex />
        </Route> */}
        <Route exact path="/">
          <IndexRegion />
        </Route>
        <Route exact path="/regions">
          <RegionListing />
        </Route>
        <Route exact path={`/regions/:name`}>
          <RegionDetail />
        </Route>
        <Route exact path={`/regions/united-states/:name`}>
          <RegionDetail />
        </Route>
        <Route exact path="/endangered-species">
          <SpeciesListing />
        </Route>
        <Route exact path={`/endangered-species/:name`}>
          <SpeciesDetail />
        </Route>
        <Route exact path="/organizations">
          <Organizations />
        </Route>
        <Route exact path="/organizations/:name">
          <OrganizationDetail />
        </Route>
        <Route exact path="/zoos-and-wildlife-reserves">
          <ZooListing />
        </Route>
        <Route exact path="/zoos-and-wildlife-reserves/:name">
          <ZooDetail />
        </Route>
        <Route exact path="/war-on-the-environment-threats">
          <WetMarket />
        </Route>
        <Route exact path="/war-on-the-environment-threats/:name">
          <WetMarketDetail />
        </Route>
        <Route exact path="/terms-and-conditions">
          <TermsAndCondition />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/endangered-species/:name/donors-list">
          <DonorsListing />
        </Route>
        <Route exact path="/science-education">
          <ScienceAndEducationListing />
        </Route>
        <Route exact path="/science-education/:title">
          <ScienceAndEducation />
        </Route>
        <Route exact path="/environmentalists">
          <EnvironmentalistList />
        </Route>
        <Route exact path="/environmentalists/:name/">
          <EnvironmentalistDetail />
        </Route>
        <Route exact path="/organizations/:name/program">
          <ProgramDetailPage />
        </Route>
        <Route exact path="/zoos-and-wildlife-reserves/:name/program">
          <ProgramDetailZooPage />
        </Route>
        <Route exact path="/signup">
          <UpdateProfile />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/change-password">
          <ChangePassword />
        </Route>
        <Route exact path="/reset-password-successful">
          <ResetPasswordSuccessful />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register/user-type">
          <RegisterUserType />
        </Route>
        <Route exact path="/auth">
          <Auth />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        {/* <Route exact path="/post">
          <IndexFeed />
        </Route> */}
        <Route exact path="/profile">
          <UserProfile />
        </Route>
        <Route exact path="/edit-profile">
          <EditUserProfile />
        </Route>
        <Route exact path="/add-post">
          <AddPost />
        </Route>
        <Route exact path="/edit-post">
          <AddPost />
        </Route>
        <Route exact path="/:name/followers">
          <Followers />
        </Route>
        <Route exact path="/profile/:name/followers">
          <Followers />
        </Route>
        <Route exact path="/live-feeds">
          <LiveFeedsListing />
        </Route>
        <Route exact path="/splash-page">
          <Splash />
        </Route>
        <Route exact path="/volunteer">
          <VolunteerPreference />
        </Route>
        <Route exact path="/greeting">
          <Greeting />
        </Route>
        <Route exact path={`/posts/:name`}>
          <Posts />
        </Route>
        <Route exact path={`/profile/:name`}>
          <GuestProfile />
        </Route>
        <Route exact path={`/addsponsor/:name`}>
          <AddSponsor />
        </Route>
        <Route exact path="/sponsors">
          <SponsorsListing />
        </Route>
        <Route exact path={`/addsponsor`}>
          <AddSponsor />
        </Route>
        <Route exact path={`/story`}>
          <CreateStory />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/sustainability-details">
          <SustainabilityDetail />
        </Route>
        <Route exact path="/sustainability/:name">
          <Sustainability />
        </Route>
        <Route exact path={`/search/:name`}>
          <Search />
        </Route>
        <Route exact path="/environmental-press-corp">
          <EnvironmentalPressCorp />
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/sample-sustainability-page">
          <SampleSustainability />
        </Route>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
