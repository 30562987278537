import React from "react";
// import "./bootstrap.min.css";
// import "./fontawesome.css";
// import "./style.module.css";
import logo from "./images/logo.svg";
import vallourecHero from "./images/vallourec-hero.jpg";
import vallourecLogo from "./images/vallourec-logo.jpg";
import species1 from "./images/black-footed.jpg";
import species2 from "./images/turtle.jpg";
import species3 from "./images/aberdare-cisticola.jpg";
import union from "./images/union.svg";
import union1 from "./images/endengeredIcon.png";
import logoBw from "./images/logo-bw.svg";
import avaitionAIcon from "./images/avaiation1.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const SampleSustainability = () => {
  return (
    <div className="page-holder-wrapper bg-gray min-vh-100" style={{ marginTop: "-6.2%" }}>
      <div className="vallourec-hero-container d-flex align-items-end">
        <div className="vc-bg">
          <img src={vallourecHero} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="vallourec-hero-content d-flex">
                <div className="vallourec-hero-logo flex-shrink-0">
                  <img src={avaitionAIcon} alt="" />
                </div>
                <div className="vallourec-hero-text flex-grow-1 d-flex align-items-end">
                  <h1>Avaition AI</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Container */}
      <div className="vallourec-content-container vallourec-intro-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="vallourec-intro-content">
                <p>
                  Welcome to the Avaition AI Sustainability page where we sponsor endangered animals and the
                  conservation efforts by the scientists, biologists, journalists, conservation organizations, and
                  non-profits to save these species from extinction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sponsored Species Section */}
      <div className="vallourec-content-container pt-0 sponsored-species-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="sponsored-species-head d-flex flex-column gap-4 flex-md-row justify-content-md-between">
                <div className="ss-left">
                  <h2>Sponsored Species</h2>
                </div>
                <div className="ss-right">
                  <div className="ss-list d-flex flex-wrap">
                    <div className="ss-item">
                      <div className="ss-box w-100 h-100 d-flex flex-column text-center">
                        <div className="ss-img">
                          <img src={species1} alt="" />
                        </div>
                        <p>Black-footed ferret</p>
                      </div>
                    </div>
                    <div className="ss-item">
                      <div className="ss-box w-100 h-100 d-flex flex-column text-center">
                        <div className="ss-img">
                          <img src={species2} alt="" />
                        </div>
                        <p>Ridley turtle</p>
                      </div>
                    </div>
                    <div className="ss-item">
                      <div className="ss-box w-100 h-100 d-flex flex-column text-center">
                        <div className="ss-img">
                          <img src={species3} alt="" />
                        </div>
                        <p>Aberdare Cisticola</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sponsored-species-body d-flex flex-column">
                <div class="species-profile-widget d-flex flex-column flex-md-row">
                  <div class="species-profile-overview flex-shrink-0">
                    <div class="species-profile">
                      <div class="species-profile-img">
                        <img src={species1} alt="" />
                      </div>
                      <div class="species-profile-details">
                        <h3>Black-footed ferret</h3>
                        <p>Mustela Nigripes</p>
                        <div class="badge-holder d-flex align-items-center flex-wrap">
                          <div class="badge green-badge">Arizona, USA </div>
                          <div class="badge green-badge">Colorado, USA</div>
                          <div class="badge green-badge">Montana, USA</div>
                          <div class="badge green-badge">+5</div>
                        </div>
                      </div>
                      <div class="species-profile-bottom">
                        <div class="species-profile-name d-flex align-items-center">
                          <div class="spn-icon-box">
                            <img src={union1} alt="" />
                          </div>
                          <div class="npm-name-box">Endangered</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="species-profile-description d-flex flex-column flex-grow-1">
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>The Black-Footed Ferret: A Conservation Success Story</h4>
                        </div>
                        <p>
                          The black-footed ferret (Mustela nigripes) is one of North America's most elusive and
                          endangered creatures. With its slender body, masked face, and distinctive black paws, this
                          carnivorous mammal is truly unique.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>Why the Black-Footed Ferret Needs Our Protection</h4>
                        </div>
                        <p>
                          The black-footed ferret is one of North America's most endangered mammals, and while
                          conservation efforts have made significant strides, the species still faces many challenges.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>How the Black-Footed Ferret Helps Us Understand Ecosystem Health</h4>
                        </div>
                        <p>
                          When we think of endangered species, it's easy to focus on the animals themselves. But the
                          plight of the blackfooted ferret (Mustela nigripes) offers a powerful reminder that protecting
                          an individual species is also about pre...
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="viewmore-post-btn">
                      <Link class="btn btn-default btn-block no-pointer">View More Post</Link>
                    </div>
                  </div>
                </div>
                <div class="species-profile-widget d-flex flex-column flex-md-row">
                  <div class="species-profile-overview flex-shrink-0">
                    <div class="species-profile">
                      <div class="species-profile-img">
                        <img src={species2} alt="" />
                      </div>
                      <div class="species-profile-details">
                        <h3>Ridley turtle</h3>
                        <p>Lepidochelys </p>
                        <div class="badge-holder d-flex align-items-center flex-wrap">
                          <div class="badge green-badge">Virginia, USA</div>
                          <div class="badge green-badge">Serengeti, Tanzania</div>
                          <div class="badge green-badge">Galápagos Islands, Ecuador</div>
                          <div class="badge green-badge">+5</div>
                        </div>
                      </div>
                      <div class="species-profile-bottom">
                        <div class="species-profile-name d-flex align-items-center">
                          <div class="spn-icon-box">
                            <img src={union} alt="" />
                          </div>
                          <div class="npm-name-box">Critically Endangered</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="species-profile-description d-flex flex-column flex-grow-1">
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>The Fascinating World of the Ridley Sea Turtle: A Tiny Survivor of the Oceans</h4>
                        </div>
                        <p>
                          The Ridley sea turtle, one of the smallest and most endangered of sea turtles, is a remarkable
                          creature with an incredible survival story.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>Why the Kemps Ridley Turtle Is on the Brink of Extinction</h4>
                        </div>
                        <p>
                          The Kemp's Ridley turtle, scientifically known as Lepidochelys kempii, is one of the rarest
                          and most critically endangered sea turtles on the planet.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>The Olive Ridley Turtle: A Lesser-Known Hero of the Ocean</h4>
                        </div>
                        <p>
                          While much attention has been given to the critically endangered Kemp's Ridley turtle, the
                          Olive Ridley turtle (Lepidochelys olivacea) also deserves our focus and protection.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="viewmore-post-btn">
                      <Link class="btn btn-default btn-block no-pointer">View More Post</Link>
                    </div>
                  </div>
                </div>
                <div class="species-profile-widget d-flex flex-column flex-md-row">
                  <div class="species-profile-overview flex-shrink-0">
                    <div class="species-profile">
                      <div class="species-profile-img">
                        <img src={species3} alt="" />
                      </div>
                      <div class="species-profile-details">
                        <h3>Aberdare Cisticola</h3>
                        <p>Cisticola aberdare</p>
                        <div class="badge-holder d-flex align-items-center flex-wrap">
                          <div class="badge green-badge">Virginia, USA</div>
                          <div class="badge green-badge">Serengeti, Tanzania</div>
                          <div class="badge green-badge">Galápagos Islands, Ecuador</div>
                          <div class="badge green-badge">+5</div>
                        </div>
                      </div>
                      <div class="species-profile-bottom">
                        <div class="species-profile-name d-flex align-items-center">
                          <div class="spn-icon-box">
                            <img src={union} alt="" />
                          </div>
                          <div class="npm-name-box">Critically Endangered</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="species-profile-description d-flex flex-column flex-grow-1">
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>The Aberdare Cisticola: A Tiny Treasure of Kenyas Highlands</h4>
                        </div>
                        <p>
                          The Aberdare Cisticola is a rare and charming bird found only in the grasslands of Kenya's
                          Aberdare Mountain Range. Its distinct song and elusive nature make it a fascinating subject
                          for birdwatchers and conservationists alike.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>Why the Aberdare Cisticola Is at Risk of Extinction</h4>
                        </div>
                        <p>
                          With its habitat in the endangered highland grasslands of central Kenya, the Aberdare
                          Cisticola faces numerous threats from habitat loss and climate change. Protecting these unique
                          ecosystems is crucial for ensuring the surv...
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="description-pannel">
                      <div class="description-pannel-box">
                        <div class="description-pannel-header">
                          <h4>Conservation Efforts for the Aberdare Cisticola: Preserving a Unique Ecosystem</h4>
                        </div>
                        <p>
                          Conservation initiatives in Kenya are focused on protecting the Aberdare Cisticola by
                          preserving its fragile mountain grassland habitat.
                        </p>
                        <div class="description-pannel-btn">
                          <Link class="btn-read-more no-pointer">Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div class="viewmore-post-btn">
                      <Link class="btn btn-default btn-block no-pointer">View More Post</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer id="footerBar" className="font-family-poppins">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="footer-upper-bar d-flex flex-column align-items-center gap-3 gap-md-0 flex-md-row justify-content-between">
                <div class="footer-upper-bar-left">
                  <Link class="footer-bar-logo no-pointer">
                    <img src={logoBw} alt="" />
                  </Link>
                </div>
                <div class="footer-upper-bar-middle align-self-center">
                  <p>Address : P.O. Box G-3 Aspen, CO - 81611</p>
                </div>
                <div class="footer-upper-bar-right">
                  <ul class="social-media-list mt-0 d-flex flex-wrap">
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fa-brands fa-x-twitter"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-facebook-f" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li class="social-media-item">
                      <Link class="social-media-box no-pointer">
                        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="footer-lower-bar">
                <div class="footer-lower-bar-content d-flex flex-column-reverse flex-md-row gap-3 align-items-center justify-content-between">
                  <div>
                    <div class="copyright">&copy; Environmental Inclusion. All Rights Reserved.</div>
                  </div>
                  <div>
                    <ul class="footer-nav d-flex align-items-center">
                      <li>
                        <Link class="no-pointer">Terms & Condition</Link>
                      </li>
                      <li>
                        <Link class="no-pointer">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SampleSustainability;
