import React, { useEffect, useState, useRef } from "react";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Skeleton from "react-loading-skeleton";
import noRecord from "../../../include/images/nrb-img.svg";
import LargeImageGalleryModal from "../imageGallery/largeImageGallery";
import Pagination from "../Pagination/pagination";
import { connect } from "react-redux";
import { getEducationMedia, getMisc } from "../../../store/misc";
import { capitalizeContentInBrackets, dotDesc, removeTags } from "../../../utils/helperFunctions";
import ReactPlayer from "react-player";
import doc from "../../../include/images/Doc img/Doc.svg";
import docx from "../../../include/images/Doc img/Docx.svg";
import pdf from "../../../include/images/Doc img/Pdf.svg";
import txt from "../../../include/images/Doc img/TXT.svg";
import xls from "../../../include/images/Doc img/XLS.svg";
import xlxs from "../../../include/images/Doc img/XLXS.svg";
import essayIcon from "../../../include/images/Essay 2.svg";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { sideBarApisListings } from "../../../store/sidebarApis";
import ViewModal from "../education/viewModal";

const PostListCard = (props) => {
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [galleryItems, setGalleryItems] = useState([]);
  const gridRef = useRef(null);
  const masonryRef = useRef(null);
  const parentRef = useRef(null);
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true); // Track initial data loading state
  const [loadingData, setLoadingData] = useState(false);
  const { media, setMedia } = props;
  const sectionsArray = [
    { name: "regions", path: "/regions" },
    { name: "species", path: "/endangered-species" },
    { name: "organizations", path: "/organizations" },
    { name: "wetMarkets", path: "/war-on-the-environment-threats" },
    { name: "zoos", path: "/zoos-and-wildlife-reserves" },
    { name: "scienceAndEducations", path: "/science-education" },
    { name: "environmentalists", path: "/environmentalists" },
  ];

  const regionsDropdown =
    props.sideBarApisListings && props.sideBarApisListings?.regionList && props.sideBarApisListings?.regionList?.data;

  const mediaData = props.getMisc && props.getMisc?.education?.data && props.getMisc?.education?.data;
  const toggleViewModal = () => {
    setViewModal(!viewModal);
  };

  const fetchData = (page, filter) => {
    setLoadingData(true);
    const params = {
      order: "desc",
      created: "createdAt",
      page: page,
      section:props.section,
      id:props.id
    };
    if (filter != "All") {
      params.type = filter;
    }
    props.getEducationMedia(params, (res) => {
      if (res && res.status === 200) {
        setLoadingData(false);
        setMedia(res?.data?.data);
        let mediaItems = res?.data?.data?.map((item) => {
          const mediaType = item?.file?.mediaType;
          const original = process.env.REACT_APP_MEDIA + item?.file?.original;
          if (mediaType === "document" && item?.section) {
            return {
              file: {
                mediaType,
                original: item?.file?.original,
                firstName: item?.firstName || "",
                class: item?.class || "",
                name: item?.file?.name,
                school: item?.school,
                teacherName: item?.teacherName,
                description: item?.description,
              },
            };
          } else if (item?.section && (mediaType === "sketch" || mediaType === "video" || mediaType === "image")) {
            return {
              file: {
                mediaType,
                original,
                firstName: item?.firstName || "",
                class: item?.class || "",
                school: item?.school,
                teacherName: item?.teacherName,
                description: item?.description,
              },
            };
          } else if (item?.section) {
            return {
              file: {
                mediaType: mediaType || "essay1",
                original: item?.file?.original ? original : essayIcon,
                firstName: item?.firstName || "",
                class: item?.class || "",
                essay: item?.essay,
                school: item?.school,
                teacherName: item?.teacherName,
                description: item?.description,
              },
            };
          } else {
            return {
              file: {
                type1: "post",
                mediaType: item?.files[0]?.mediaType,
                original: process.env.REACT_APP_MEDIA + item?.files[0]?.original,
                firstName: item?.createdBy?.firstName || "",
                description: item?.description,
                ...item,
              },
            };
          }
        });

        setTotalCount(res?.data?.totalCount || 0);
        if (page === 1) {
          setInitialLoading(false);
          const filteredItems = mediaItems?.filter(Boolean) || [];
          setGalleryItems([...filteredItems]);
        } else {
          setGalleryItems((prevItems) => {
            const filteredItems = mediaItems?.filter(Boolean) || [];
            return [...prevItems, ...filteredItems];
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchData(currentPage, props.filter);
  }, [props.filter]);

  useEffect(() => {
    // Fetch data when currentPage changes, but only if currentPage is within total pages range
    if (currentPage <= Math.ceil(totalCount / 48)) {
      fetchData(currentPage);
    }
  }, [currentPage]);
  // useEffect(()=>{
  //   let mediaItems = res?.data?.data?.map((item) => {
  //     const mediaType = item?.file?.mediaType;
  //     const original = process.env.REACT_APP_MEDIA + item?.file?.original;
  //     if (mediaType === "document" && item?.section) {
  //       return {
  //         file: {
  //           mediaType,
  //           original: item?.file?.original,
  //           firstName: item?.firstName || "",
  //           class: item?.class || "",
  //           name: item?.file?.name,
  //           school: item?.school,
  //           teacherName: item?.teacherName,
  //           description: item?.description,
  //         },
  //       };
  //     } else if (item?.section && (mediaType === "sketch" || mediaType === "video" || mediaType === "image")) {
  //       return {
  //         file: {
  //           mediaType,
  //           original,
  //           firstName: item?.firstName || "",
  //           class: item?.class || "",
  //           school: item?.school,
  //           teacherName: item?.teacherName,
  //           description: item?.description,
  //         },
  //       };
  //     } else if (item?.section) {
  //       return {
  //         file: {
  //           mediaType: mediaType || "essay1",
  //           original: item?.file?.original ? original : essayIcon,
  //           firstName: item?.firstName || "",
  //           class: item?.class || "",
  //           essay: item?.essay,
  //           school: item?.school,
  //           teacherName: item?.teacherName,
  //           description: item?.description,
  //         },
  //       };
  //     } else {
  //       return {
  //         file: {
  //           type1: "post",
  //           mediaType: item?.files[0]?.mediaType,
  //           original: process.env.REACT_APP_MEDIA + item?.files[0]?.original,
  //           firstName: item?.createdBy?.firstName || "",
  //           description: item?.description,
  //           ...item,
  //         },
  //       };
  //     }
  //   });
  //   setGalleryItems((prevItems) => {
  //     const filteredItems = mediaItems?.filter(Boolean) || [];
  //     return [...prevItems, ...filteredItems];
  //   });
  // },[media])
  useEffect(() => {
    if (gridRef.current) {
      masonryRef.current = new Masonry(gridRef.current, {
        itemSelector: ".grid-item",
        percentPosition: true,
      });

      const imgLoad = imagesLoaded(gridRef.current);
      imgLoad.on("progress", () => {
        masonryRef.current?.layout();
      });

      return () => {
        masonryRef.current?.destroy();
      };
    }
  }, [galleryItems]);

  const handleClick = (item) => {
    setViewData(item);
    toggleViewModal();
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  function handleScroll(e) {
    e.preventDefault();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getDefaultDocIcon = (extension) => {
    switch (extension) {
      case "docx":
        return docx;
      case "pdf":
        return pdf;
      case "txt":
        return txt;
      case "xls":
        return xls;
      case "xlsx":
        return xlxs;
      default:
        return doc;
    }
  };

  const handleMediaLoad = () => {
    // Handle media load if needed
  };
  return (
    <div className="gallery-map w-100 d-flex ms-3" style={{ marginTop: "-3%" }}>
      {initialLoading || loadingData ? (
        <div className="skeleton-container d-flex flex-wrap mb-3 mt-4 pt-2" style={{ gap: "20px" }}>
          {Array.from({ length: 18 }, (_, index) => (
            <Skeleton borderRadius={"10px"} height="250px" width="330px" />
          ))}
        </div>
      ) : (
        <div className="grid d-flex" ref={gridRef}>
          <div className="grid-sizer"></div>
          {
            // Render gallery items once initial loading is complete
            galleryItems && galleryItems.length > 0
              ? galleryItems.map((item, idx) => {
                  const mediaType = item?.file.mediaType;
                  const imageOriginal = item?.file.original;
                  const ext = item?.file?.original?.match(/\.([^.]+)$/)?.[1];
                  const defaultDocIcon = mediaType === "document" ? getDefaultDocIcon(ext) : "";
                  const nameDoc = mediaType === "document" ? item?.file?.name : "";

                  return (
                    <div className="grid-item" key={idx}>
                      {item.file.type1 === "post" ? (
                        <div className="eap-box" data-bs-toggle="modal" data-bs-target="#galleryModal1">
                          <div
                            className={`${
                              item?.file?.firstName || item.file.description ? "eap-img-box" : "eap-img-box mb-0"
                            }`}
                            onClick={
                              mediaType !== "document"
                                ? () => {
                                    handleClick(item);
                                  }
                                : () => {}
                            }
                          >
                            {mediaType === "image" && (
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={imageOriginal}
                                alt=""
                                onLoad={handleMediaLoad}
                              />
                            )}
                            {mediaType === "video" && (
                              <>
                                {imageOriginal && imageOriginal.startsWith("https") ? (
                                  <ReactPlayer
                                    className="w-auto h-auto"
                                    url={imageOriginal}
                                    controls={false}
                                    onReady={handleMediaLoad}
                                  />
                                ) : null}
                                {imageOriginal && !imageOriginal.startsWith("https") ? (
                                  <video onLoad={handleMediaLoad}>
                                    <source src={process.env.REACT_APP_MEDIA + imageOriginal} />
                                  </video>
                                ) : null}
                              </>
                            )}
                          </div>
                          {item?.file?.firstName ||
                            (item.file.description && (
                              <div className={`eap-text-box p-2 pt-0 rounded-3 ${!mediaType ? "doc-background" : ""}`}>
                                <div
                                  onClick={
                                    mediaType !== "document"
                                      ? () => {
                                          handleClick(item);
                                        }
                                      : () => {}
                                  }
                                  className="cursor-pointer"
                                >
                                  <h5 className="text-capitalize text-transfrom ">{`${capitalizeContentInBrackets(
                                    item?.file?.firstName,
                                  )}`}</h5>
                                  {!mediaType && <p className="mb-2">{stripHtmlTags(item.file.description)}</p>}
                                </div>
                                <div className="eap-list d-flex flex-wrap">
                                  {sectionsArray.map((sectionName) => {
                                    if (item.file[sectionName.name]) {
                                      return item.file[sectionName.name].map((sectionItem, idx) => (
                                        <div className="eap-item text-capitalize" key={idx}>
                                          <Link
                                            to={{
                                              pathname: `${sectionName.path}/${sectionItem.slug}`,
                                              state: { id: sectionItem._id },
                                            }}
                                          >
                                            #{capitalizeContentInBrackets(sectionItem.name)}
                                          </Link>
                                        </div>
                                      ));
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div className="eap-box" data-bs-toggle="modal" data-bs-target="#galleryModal1">
                          <div
                            className={`${item?.file?.firstName ? "eap-img-box" : "eap-img-box mb-0"}`}
                            onClick={
                              mediaType !== "document"
                                ? () => {
                                    handleClick(item);
                                  }
                                : () => {}
                            }
                          >
                            {mediaType === "image" && (
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={imageOriginal}
                                alt=""
                                onLoad={handleMediaLoad}
                              />
                            )}
                            {mediaType === "sketch" && (
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={imageOriginal}
                                alt=""
                                onLoad={handleMediaLoad}
                              />
                            )}
                            {mediaType === "video" && (
                              <>
                                {imageOriginal && imageOriginal.startsWith("https") ? (
                                  <ReactPlayer
                                    className="w-auto h-auto"
                                    url={imageOriginal}
                                    controls={false}
                                    onReady={handleMediaLoad}
                                  />
                                ) : null}
                                {imageOriginal && !imageOriginal.startsWith("https") ? (
                                  <video onLoad={handleMediaLoad}>
                                    <source src={process.env.REACT_APP_MEDIA + imageOriginal} />
                                  </video>
                                ) : null}
                              </>
                            )}
                            {mediaType === "document" && imageOriginal && (
                              <a
                                href={process.env.REACT_APP_MEDIA + imageOriginal}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-100 h-100 object-fit-cover"
                              >
                                <div className="doc-background">
                                  <img
                                    src={defaultDocIcon}
                                    alt="Default Document Icon"
                                    className="w-100 h-100 object-fit-cover"
                                  />
                                  <div
                                    className="media-text-box mt-3 text-center text-capitalize"
                                    title={nameDoc || ""}
                                  >
                                    {nameDoc !== undefined
                                      ? dotDesc(HTMLReactParser(removeTags(nameDoc)), 15)
                                      : "\u00A0"}
                                  </div>
                                </div>
                              </a>
                            )}
                            {mediaType === "essay" && (
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={imageOriginal}
                                alt=""
                                onLoad={handleMediaLoad}
                              />
                            )}
                            {mediaType === "essay1" && (
                              <div className="doc-background">
                                <img
                                  className="w-100 h-100 object-fit-cover"
                                  src={essayIcon}
                                  alt=""
                                  onLoad={handleMediaLoad}
                                />
                              </div>
                            )}
                          </div>
                          {item?.file?.firstName && (
                            <div className="eap-text-box p-2 pt-0">
                              <div
                                onClick={
                                  mediaType !== "document"
                                    ? () => {
                                        handleClick(item);
                                      }
                                    : () => {}
                                }
                                className="cursor-pointer"
                              >
                                <h5 className="text-capitalize text-transfrom">{`${capitalizeContentInBrackets(
                                  item?.file?.firstName,
                                )}`}</h5>
                                {item?.file?.class && <p>Grade : {item?.file?.class}</p>}
                              </div>
                              <div className="eap-list d-flex flex-wrap">
                                <div className="eap-item"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              : props.page !== "home" && (
                  <div className="no-records-container d-flex align-items-center justify-content-center">
                    <div className="no-records-box text-center">
                      <div className="nrb-image">
                        <img src={noRecord} alt="" />
                      </div>
                      <h6>No Records Found</h6>
                    </div>
                  </div>
                )
          }
          <ViewModal
            show={viewModal}
            onHide={toggleViewModal}
            heading="Essay"
            id={props.id}
            section={props.section}
            data={viewData}
            sectionsArray={sectionsArray}
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getEducationMedia: (params, callback) => dispatch(getEducationMedia(params, callback)),
});

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PostListCard));
