import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"; // Correct location import
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { updateYourProfile } from "../../store/users"; // Assuming the action exists
import { getSpeciesDetails } from "../../store/species";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
import { createsponsor, getSubscription } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import LoadingBar from "react-top-loading-bar";
import SpeciesAndCategoryEditModal from "../../components/common/feedEditModals/speciesAndCategoryEditModal";
import Sponsor from "../../components/speciesDetailsPages/sponsor";
import SponsorView from "./sponsorView";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getProfile } from "../../utils/localStorageServices";
import SponsorSpeciesModal from "../../components/common/subscription/sponsorSpeciesModal";
import { getSpeciesList, sideBarApisListings } from "../../store/sidebarApis";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const AddSponsor = (props) => {
  const location = useLocation(); // To access location.state
  const sampleSustainability = "/aviation-AI.pdf";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    logo: null,
    sponsorType: "individual",
  });
  const ref = useRef();
  const [id, setId] = useState(undefined);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [follow, setFollow] = useState(false);
  const [speciesData, setSpeciesData] = useState(null);
  const [speciesCatData, setSpeciesCatData] = useState(null);
  const [displayName, setDisPlayName] = useState("");
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);

  const [tempSelectSpeciesName1, setTempSelectSpeciesName1] = useState([]);
  const [showSpeciesAndCategories1, setshowSpeciesAndCategories1] = useState(false);
  const [tempSelectSpeciesCategory1, setTempSelectSpeciesCategory1] = useState([]);
  const [tempSelectSpecies1, setTempSelectSpecies1] = useState([]);

  const [tempSelectSpeciesName2, setTempSelectSpeciesName2] = useState([]);
  const [showSpeciesAndCategories2, setshowSpeciesAndCategories2] = useState(false);
  const [tempSelectSpeciesCategory2, setTempSelectSpeciesCategory2] = useState([]);
  const [tempSelectSpecies2, setTempSelectSpecies2] = useState([]);

  const [tempSelectSpeciesName3, setTempSelectSpeciesName3] = useState([]);
  const [showSpeciesAndCategories3, setshowSpeciesAndCategories3] = useState(false);
  const [tempSelectSpeciesCategory3, setTempSelectSpeciesCategory3] = useState([]);
  const [tempSelectSpecies3, setTempSelectSpecies3] = useState([]);
  const profile = getProfile();
  const speciesDropdownDataList = props?.sideBarApisListings?.speciesList?.data;

  const pathSegments = window.location.pathname.split("/");
  const isSponsorWithContent = pathSegments[1] === "addsponsor" && pathSegments[2]?.trim();
  useEffect(() => {
    if (location?.state?.id) {
      setId(location?.state?.id);
      const speciesId = location.state.id;
      fetchSpeciesData(speciesId);
    } else {
      setLoadingDetail(true);
      props.getSpeciesDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status === 200) {
          setLoadingDetail(false);
          ref?.current?.complete();
          setId(res?.data?.data?._id);
          setSpeciesData(res?.data?.data);
        }
      });
    }
  }, [location]);
  useEffect(() => {
    if (!speciesDropdownDataList) {
      props.getSpeciesList({}, (res) => {
        if (res && res.status === 200) {
          if (location?.state?.id) {
            profile?.profile?.corporateSponsoredSpecies.forEach((id, index) => {
              const matchedItem = res?.data?.data.find((item) => item._id === id);
              if (matchedItem) {
                if (index === 0) {
                  setDisPlayName(matchedItem?.displayCommonName);
                } else if (index === 1) {
                  setTempSelectSpeciesName(matchedItem);
                  setTempSelectSpecies([id]);
                } else if (index === 2) {
                  setTempSelectSpeciesName1(matchedItem);
                  setTempSelectSpecies1([id]);
                }
              }
            });
          } else {
            profile?.profile?.corporateSponsoredSpecies.forEach((id, index) => {
              const matchedItem = speciesDropdownDataList.find((item) => item._id === id);
              if (matchedItem) {
                if (index === 0) {
                  setTempSelectSpeciesName2(matchedItem);
                  setTempSelectSpecies2([id]);
                } else if (index === 1) {
                  setTempSelectSpeciesName(matchedItem);
                  setTempSelectSpecies([id]);
                } else if (index === 2) {
                  setTempSelectSpeciesName3(matchedItem);
                  setTempSelectSpecies3([id]);
                }
              }
            });
          }
        }
      });
    } else {
      if (location?.state?.id) {
        profile?.profile?.corporateSponsoredSpecies?.forEach((id, index) => {
          const matchedItem = speciesDropdownDataList.find((item) => item._id === id);
          if (matchedItem) {
            if (index === 0) {
              setDisPlayName(matchedItem?.displayCommonName);
            } else if (index === 1) {
              setTempSelectSpeciesName(matchedItem);
              setTempSelectSpecies([id]);
            } else if (index === 2) {
              setTempSelectSpeciesName1(matchedItem);
              setTempSelectSpecies1([id]);
            }
          }
        });
      } else {
        profile?.profile?.corporateSponsoredSpecies?.forEach((id, index) => {
          const matchedItem = speciesDropdownDataList.find((item) => item._id === id);
          if (matchedItem) {
            if (index === 0) {
              setTempSelectSpeciesName2(matchedItem);
              setTempSelectSpecies2([id]);
            } else if (index === 1) {
              setTempSelectSpeciesName(matchedItem);
              setTempSelectSpecies([id]);
            } else if (index === 2) {
              setTempSelectSpeciesName3(matchedItem);
              setTempSelectSpecies3([id]);
            }
          }
        });
      }
    }
  }, []);
  const fetchSpeciesData = (speciesId) => {
    setLoadingDetail(true);
    ref?.current?.continuousStart();
    props.getSpeciesDetails(speciesId, (res) => {
      if (res && res.status === 200) {
        setLoadingDetail(false);
        ref?.current?.complete();
        setSpeciesData(res?.data?.data);
      }
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file (logo) upload
  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, logo: e.target.files[0] }));
  };
  const handleSponsorTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      sponsorType: value,
    }));
  };
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  const toggleSpeciesAndCategories1 = () => {
    setshowSpeciesAndCategories1(!showSpeciesAndCategories1);
  };
  const toggleSpeciesAndCategories2 = () => {
    setshowSpeciesAndCategories2(!showSpeciesAndCategories2);
  };
  const toggleSpeciesAndCategories3 = () => {
    setshowSpeciesAndCategories3(!showSpeciesAndCategories3);
  };
  const handleSubmit = (item) => {
    setLoadingDetail(true);
    ref?.current?.continuousStart();
    if (item === "corporate" && id) {
      const speciesCounts = [tempSelectSpecies?.length, tempSelectSpecies1?.length];
      if (speciesCounts.some((count) => count > 1)) {
        ref?.current?.complete();
        toast(<AlertError message="Each species dropdown should contain only one selected species." />);
        return;
      }
      if (speciesCounts.some((count) => count === 0 || count === undefined)) {
        // ref?.current?.complete();
        // toast(<AlertError message="Corporate sponsors must select one species in each dropdown." />);
        // return;
      }
    } else if (item === "individual" && id === undefined && (!tempSelectSpecies1 || tempSelectSpecies1.length !== 1)) {
      ref?.current?.complete();
      toast(<AlertError message="Individual sponsors must select exactly one species." />);
      return;
    }
    // else if (item === "corporate" && tempSelectSpecies?.length === 0) {
    //   ref?.current?.complete();
    //   toast(<AlertError message="Corporate sponsors must select three species." />);
    //   return;
    // }

    if (location?.state?.id || isSponsorWithContent) {
      const species = [tempSelectSpecies[0], tempSelectSpecies1[0]].filter(Boolean);
      if (species.length > 0) {
        sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
      }
    } else {
      if (item === "corporate") {
        const species = [tempSelectSpecies[0], tempSelectSpecies2[0], tempSelectSpecies3[0]].filter(Boolean);
        if (species.length > 0) {
          sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
        }
      } else {
        if (tempSelectSpecies1.length > 0) {
          const species = tempSelectSpecies1.filter(Boolean);
          if (species.length > 0) {
            sessionStorage.setItem("tempSelectSpecies", JSON.stringify(species));
          }
        }
      }
    }

    const species = id ? [id] : JSON.parse(sessionStorage.getItem("tempSelectSpecies")) || [];
    const payload = {
      speciesId:
        item === "corporate" && id
          ? [id, tempSelectSpecies[0], tempSelectSpecies1[0]].filter(Boolean)
          : item === "corporate" && !id
          ? [tempSelectSpecies[0], tempSelectSpecies2[0], tempSelectSpecies3[0]].filter(Boolean)
          : item === "individual" && !id
          ? tempSelectSpecies1
          : [id],
      isCompany: item === "corporate",
      return_url: id
        ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${item}`
        : `${window.location.origin}/profile?type=${item}`,
      cancel_url: id
        ? `${window.location.origin}/endangered-species/${speciesData?.slug}?type=${item}&cancel=true`
        : `${window.location.origin}/profile?type=${item}&cancel=true`,
    };
    if (item == "corporate" && formData?.description) {
      payload.description = formData.description;
    }
    props
      .createsponsor(payload, (res) => {
        if (res && res.status === 200) {
          setLoadingDetail(false);
          const redirectUrl = res.data.data?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            toast(<AlertError message="No redirection URL provided" />);
          }
        } else {
          setLoadingDetail(false);
          toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
        }
      })
      .catch((err) => {
        setLoadingDetail(false);
        toast(<AlertError message="Something Went Wrong" />);
      });
  };

  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const handleImageOpen = (e) => {
    e.preventDefault();
    window.open(sampleSustainability, "_blank", "noopener,noreferrer");
  };
  return (
    <PageLayout>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div className="page-panel-container font-family-poppins sponsored-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="mb-3">Sponsor</h1>
            </div>
            <div class="panel-head mb-0 ps-3">
              {(location?.state?.id || isSponsorWithContent) && (
                <div class="breadcrumb-row d-flex flex-wrap">
                  <div class="breadcrumb-box">
                    <Link to="/endangered-species">Endangered Species</Link>
                  </div>
                  <Link to={`/endangered-species/${speciesData?.slug}`} class="breadcrumb-box text-capitalize">
                    {speciesData?.displayCommonName || speciesData?.scientificName}
                  </Link>
                </div>
              )}
              {!isSponsorWithContent && (
                <div class="breadcrumb-row d-flex flex-wrap">
                  <div class="breadcrumb-box text-capitalize">
                    {location.state == "profile" ? (
                      <Link to="/profile">{location.state}</Link>
                    ) : location.state == "Environmental Press Corp" ? (
                      <Link to="/environmental-press-corp">{location.state}</Link>
                    ) : (
                      <Link to="/">Home</Link>
                    )}
                  </div>
                  <div class="breadcrumb-box">Add Sponsor</div>
                </div>
              )}
            </div>
          </div>
          <div className="row g-4 g-lg-0">
            <div className="col-lg-6">
              <div className="sponsored-text-box">
                {(location?.state?.id || isSponsorWithContent) && (
                  <div className="sponsored-details d-flex flex-column flex-sm-row">
                    <div className="sd-img-box flex-shrink-0">
                      {speciesData?.coverImage?.original ? (
                        <img src={process.env.REACT_APP_MEDIA + speciesData?.coverImage?.original} alt="" />
                      ) : (
                        <img src={getDefaultImage(speciesData?.speciesCategoryName)} alt="" />
                      )}
                    </div>
                    <div className="sd-text-box flex-grow-1 text-capitalize">
                      <h2>{speciesData?.dangerLevelName}</h2>
                      <h3 className="d-flex">
                        {speciesData?.displayCommonName || speciesData?.scientificName}{" "}
                        <img src={process.env.REACT_APP_MEDIA + speciesData?.dangerLevelImage?.small} alt=""></img>
                      </h3>
                      <div className="badge-holder d-flex align-items-center flex-wrap">
                        {speciesData?.regions.slice(0, 3).map((region, index) => (
                          <div key={index} className="badge green-badge">
                            {region.countryName === "United States" && region.state
                              ? `${region.state}, ${region.countryName}`
                              : region.countryName}
                            {index < Math.min(speciesData?.regions.length, 3) - 1 ? ", " : ""}
                          </div>
                        ))}
                        {speciesData?.regions.length > 3 && (
                          <div className="badge green-badge">+{speciesData.regions.length - 3}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="sponsor-content mt-2">
                  <p className="fw-bold500">Sponsor an Endangered Species: Make a Difference Today</p>
                  <p>
                    Join our mission to protect endangered species by becoming a sponsor. Your sponsorship directly
                    contributes to vital conservation efforts, helping to preserve habitats, protect animals from
                    poaching, and support research and breeding programs. By sponsoring a species, you’ll play a key
                    role in ensuring the survival of some of the planet’s most vulnerable wildlife.
                  </p>
                  <p className="fw-bold500">Why Sponsor?</p>
                  <ul>
                    <li>
                      <p className="fw-bold500">Conservation Impact:</p> Your sponsorship helps fund essential projects
                      aimed at protecting endangered species.
                    </li>
                    <li>
                      <p className="fw-bold500">Personal Connection:</p> Receive regular updates, photos, and stories
                      about the species you sponsor.
                    </li>
                    <li>
                      <p className="fw-bold500">Support Research:</p> Enable critical studies that aim to understand and
                      combat the threats facing endangered species.
                    </li>
                  </ul>
                  <p>
                    <p className="fw-bold500">Together, We Can Protect Wildlife</p>
                  </p>
                  <p>
                    Your support can help create a future where endangered species thrive in their natural habitats.
                    Take the first step today by sponsoring an endangered species and be part of the global effort to
                    preserve biodiversity for generations to come.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 ps-lg-5">
              <div className="sponsor-form-box">
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="fw-bold500">Individual ($150 /month)</div>
                  {location?.state?.id || isSponsorWithContent ? (
                    <button
                      onClick={() => handleSubmit("individual")}
                      className="btn btn-default h-40"
                      disabled={!speciesData}
                    >
                      Pay Now
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSubmit("individual")}
                      className="btn btn-default h-40"
                      disabled={tempSelectSpecies1.length == 0}
                    >
                      Pay Now
                    </button>
                  )}
                </div>
                {!location?.state?.id || !isSponsorWithContent ? (
                  <div className="form-floating-row d-flex flex-wrap mt-3">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName1?.length > 0
                              ? tempSelectSpeciesName1[0]?.displayCommonName
                              : "Select Species"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories1();
                          }}
                          placeholder="Categories of Species"
                        />
                        {tempSelectSpeciesName1?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              toggleSpeciesAndCategories1();
                            }}
                          >
                            Change
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => toggleSpeciesAndCategories1()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-floating-row d-flex flex-wrap mt-3">
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={speciesData?.displayCommonName}
                          readOnly
                        />
                        <button type="button" className="ff-change-link">
                          Selected
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {profile?.profile?.corporateSponsorCount == 3 && (
                  <p className="text-danger mb-2" style={{ fontSize: "12px" }}>
                    You have already sponsored three species and cannot sponsor additional ones.
                  </p>
                )}
                <div className="d-flex justify-content-between align-items-center mt-4">
                  <div className="fw-bold500">Corporate ($500 /month)</div>
                  {location?.state?.id || isSponsorWithContent ? (
                    <button
                      onClick={() => handleSubmit("corporate")}
                      className={`btn btn-default h-40 ${
                        profile?.profile?.corporateSponsorCount >= 3 || !speciesData ? "disabled" : ""
                      }`}
                    >
                      Pay Now
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSubmit("corporate")}
                      className={`btn btn-default h-40 ${
                        profile?.profile?.corporateSponsorCount >= 3 ? "disabled" : ""
                      }`}
                      disabled={tempSelectSpeciesName2.length == 0}
                    >
                      Pay Now
                    </button>
                  )}
                </div>
                <div className="form-floating-row mt-3">
                  {location?.state?.id || isSponsorWithContent ? (
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={speciesData?.displayCommonName || displayName}
                          readOnly
                        />
                        <button type="button" className="ff-change-link">
                          Selected
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName2.length > 0
                              ? tempSelectSpeciesName2[0]?.displayCommonName
                              : "Select Species 1"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories2();
                          }}
                        />
                        {tempSelectSpeciesName2?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              toggleSpeciesAndCategories2();
                            }}
                          >
                            Change
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => toggleSpeciesAndCategories2()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="ff-column w-100">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="text"
                        className="form-control text-capitalize"
                        placeholder="Select Species 2"
                        value={
                          tempSelectSpeciesName.length > 0
                            ? tempSelectSpeciesName[0]?.displayCommonName
                            : "Select Species 2"
                        }
                        readOnly
                        onClick={() => {
                          toggleSpeciesAndCategories();
                        }}
                      />
                      {tempSelectSpeciesName?.length > 0 ? (
                        <button
                          type="button"
                          className="ff-change-link"
                          onClick={() => {
                            toggleSpeciesAndCategories();
                          }}
                        >
                          Change
                        </button>
                      ) : (
                        <button type="button" className="ff-change-link" onClick={() => toggleSpeciesAndCategories()}>
                          Select
                        </button>
                      )}
                    </div>
                  </div>
                  {location?.state?.id || isSponsorWithContent ? (
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName1?.length > 0
                              ? tempSelectSpeciesName1[0]?.displayCommonName
                              : "Select Species 3"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories1();
                          }}
                          placeholder="Categories of Species"
                        />
                        {tempSelectSpeciesName1?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              toggleSpeciesAndCategories1();
                            }}
                          >
                            Change
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => toggleSpeciesAndCategories1()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName3?.length > 0
                              ? tempSelectSpeciesName3[0]?.displayCommonName
                              : "Select Species 3"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories3();
                          }}
                          placeholder="Categories of Species"
                        />
                        {tempSelectSpeciesName3?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              toggleSpeciesAndCategories3();
                            }}
                          >
                            Change
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => toggleSpeciesAndCategories3()}
                          >
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Describe any particular things that interest you regarding this species."
                        id="floatingTextarea2"
                        style={{ height: "115px" }}
                        value={formData.description}
                        name="description"
                        onChange={handleInputChange}
                      />
                      <label htmlFor="floatingTextarea2">
                        Describe any particular things that interest you regarding this species.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-2 default-heading">
                  <a
                    href="/sample-sustainability-page"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-dark-green"
                  >
                    Check the sample sustainability page
                  </a>
                </div>
              </div>
            </div>

            {/* {location?.state?.id && (
              <div className="sponsor-content mt-3">
                <p className="fw-bold500">Sponsor an Endangered Species: Make a Difference Today</p>
                <p>
                  Join our mission to protect endangered species by becoming a sponsor. Your sponsorship directly
                  contributes to vital conservation efforts, helping to preserve habitats, protect animals from
                  poaching, and support research and breeding programs. By sponsoring a species, you’ll play a key role
                  in ensuring the survival of some of the planet’s most vulnerable wildlife.
                </p>
                <p className="fw-bold500">Why Sponsor?</p>
                <ul>
                  <li>
                    <p className="fw-bold500">Conservation Impact:</p> Your donation helps fund essential projects aimed
                    at protecting endangered species.
                  </li>
                  <li>
                    <p className="fw-bold500">Personal Connection:</p> Receive regular updates, photos, and stories
                    about the species you sponsor.
                  </li>
                  <li>
                    <p className="fw-bold500">Support Research:</p> Enable critical studies that aim to understand and
                    combat the threats facing endangered species.
                  </li>
                </ul>
                <p className="fw-bold500">Together, We Can Protect Wildlife</p>
                <p>
                  Your support can help create a future where endangered species thrive in their natural habitats. Take
                  the first step today by sponsoring an endangered species and be part of the global effort to preserve
                  biodiversity for generations to come.
                </p>
              </div>
            )} */}
            {(location?.state?.id || isSponsorWithContent) && <SponsorView id={id} section="species" />}
          </div>
        </div>
        <SponsorSpeciesModal
          show={showSpeciesAndCategories}
          onHide={toggleSpeciesAndCategories}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
          tempSelectSpecies={tempSelectSpecies}
          setTempSelectSpecies={setTempSelectSpecies}
          tempSelectSpeciesName={tempSelectSpeciesName}
          setTempSelectSpeciesName={setTempSelectSpeciesName}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories1}
          onHide={toggleSpeciesAndCategories1}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory1}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory1}
          tempSelectSpecies={tempSelectSpecies1}
          setTempSelectSpecies={setTempSelectSpecies1}
          tempSelectSpeciesName={tempSelectSpeciesName1}
          setTempSelectSpeciesName={setTempSelectSpeciesName1}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories2}
          onHide={toggleSpeciesAndCategories2}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory2}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory2}
          tempSelectSpecies={tempSelectSpecies2}
          setTempSelectSpecies={setTempSelectSpecies2}
          tempSelectSpeciesName={tempSelectSpeciesName2}
          setTempSelectSpeciesName={setTempSelectSpeciesName2}
          isPost={true}
          postType={props?.postType}
        />
        <SponsorSpeciesModal
          show={showSpeciesAndCategories3}
          onHide={toggleSpeciesAndCategories3}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory3}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory3}
          tempSelectSpecies={tempSelectSpecies3}
          setTempSelectSpecies={setTempSelectSpecies3}
          tempSelectSpeciesName={tempSelectSpeciesName3}
          setTempSelectSpeciesName={setTempSelectSpeciesName3}
          isPost={true}
          postType={props?.postType}
        />
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  createsponsor: (data, callback) => {
    dispatch(createsponsor(data, callback));
  },
  getSpeciesList: (params, callback) => {
    dispatch(getSpeciesList(params, callback));
  },
});
const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
  sideBarApisListings: sideBarApisListings(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AddSponsor));
