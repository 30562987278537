import React, { useEffect, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";

import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Details from "../../components/wetMarketSubTabs/details";
import Extinct from "../../components/wetMarketSubTabs/extinct";
import {
  getSpeciesWetMarket,
  getWetMarketLiveFeeds,
  getWetMarketMedia,
  getwetmarketDetails,
  getwetmarketReports,
  wetMarketData,
} from "../../store/wetMarket";
import UserUpload from "../../components/common/userUpload/userUpload";
import App from "../../common/maps/map";
import Reports from "../../components/organizationDetail/reports";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import Skeleton from "react-loading-skeleton";
import { getMisc, getReferences, getSetting } from "../../store/misc";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetterWord,
  cleanAndFormatHtml,
} from "../../utils/helperFunctions";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import ShareModal from "../../components/common/sharemodal/shareModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import shareSvg from "../../include/images/share.svg";
import editModeIcon from "../../include/images/edit.svg";
import { getProfile } from "../../utils/localStorageServices";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import Media from "../../components/common/education/media";

const WetMarketDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("1");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");
  const [mapData, setMapData] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [openShareModal, setOpenShareModal] = useState(false);
  const profile = getProfile();
  const [isEdit, setIsEdit] = useState(false);
  const wetMarketSpecies = props.wetMarketData && props.wetMarketData.species && props.wetMarketData.species.data;
  const endengeredCount = wetMarketSpecies?.filter(
    (item) => item?.dangerLevel?.name == "endangered" || item?.dangerLevel?.name == "near to extinct",
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      setId(id);
      setIsEdit(profile?.profile?.interestedWetMarkets?.includes(id));
      setLoadingDetail(true);
      props.getwetmarketDetails(id, (res) => {
        if (res && res.status == 200) {
          setMapData([
            {
              name: res?.data?.data?.name,
              coordinates: res?.data?.data?.geoLocation,
            },
          ]);
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getwetmarketDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setMapData([
            {
              name: res?.data?.data?.name,
              coordinates: res?.data?.data?.geoLocation,
            },
          ]);
          setIsEdit(profile?.profile?.interestedWetMarkets?.includes(res?.data?.data?._id));
          setId(res?.data?.data?._id);
          setLoadingDetail(false);
        }
      });
    }
  }, []);
  Useauthredirect();
  useEffect(() => {
    if (id) {
      const ids = location?.state?.id || id;
      props.getwetmarketReports(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getWetMarketLiveFeeds({ id: ids, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getSpeciesWetMarket(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: id,
        page: 1,
        section: "wetMarket",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);
  const details = props.wetMarketData && props.wetMarketData?.details.data;
  const reports = props.wetMarketData && props.wetMarketData?.reports;
  const liveFeeds = props.wetMarketData && props.wetMarketData.liveFeeds;
  const species = props.wetMarketData && props.wetMarketData.species;
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const [editMode, setEditMode] = useState(false);
  const combinedData = [
    ...(species?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "endangered-species",
      image: item?.coverImage?.small,
    })),
  ];

  const wetMapData = [
    {
      id: details?._id,
      name: details?.name,
      coordinates: details?.geoLocation,
      slug: details?.slug,
      section: "war-on-the-environment-threats",
      image: details?.coverImage?.small,
    },
  ];
  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "1", target: "#pills-one", label: "Details", count: null, data: [1, 2] },
    { id: "2", target: "#pills-two", label: "Species", count: species?.data?.length, data: species?.data },
    { id: "3", target: "#pills-three", label: "Reports", count: reports?.data?.length, data: reports?.data },
    // { id: "4", target: "#pills-four", label: "Media", count: null, data: [1, 2] },
    { id: "5", target: "#pills-five", label: "Live Feeds", count: liveFeeds?.totalCount, data: liveFeeds?.data },
    { id: "6", target: "#pills-six", label: "Map", count: null, data: [1, 2] },
    { id: "7", target: "#pills-seven", label: "Media and Posts", count: null, data: [1, 2] },
    { id: "8", target: "#pills-eight", label: "References", count: null, data: references },
    { id: "9", target: "#pills-nine", label: "Education", count: null, data:[1,2] },
  ];
  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);
  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
  };
  return (
    <PageLayout>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <main id="main">
        <div class="content-container region-heading-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="rhc-box d-flex flex-wrap justify-content-between">
                  <div class="rhc-box-left">
                    <span class="text-title text-green">Wet Market</span>
                    {loadingDetail ? (
                      <h1 className="text-capitalize">
                        <Skeleton borderRadius={"10px"} height={40} width={200} />
                      </h1>
                    ) : (
                      <h1 className="text-capitalize d-flex">
                        {details?.name && capitalizeContentInBrackets(details?.name)}
                        <div className="ml-20">
                          {/* <Link onClick={() => setOpenShareModal(true)}><img
                          src={shareSvg} alt="" /></Link> */}
                          {isEdit && (
                            <Link
                              onClick={() => {
                                setEditMode(true);
                              }}
                            >
                              <img src={editModeIcon} alt="" />
                            </Link>
                          )}
                        </div>
                      </h1>
                    )}
                  </div>
                  <div class="rhc-box-right">
                    <div class="rhc-box-inner d-flex flex-wrap">
                      {/* <div
                        class={`rhc-statics-box nav-link  cursor-pointer ${menuItem == "3" && "active"}`}
                        id="pills-four-tab"
                        onClick={() => setMenuItem("2".toString())}
                      >
                        <p>Species Sold</p>
                        {loadingDetail ? (
                          <h6>
                            <Skeleton borderRadius={"10px"} height={40} width={200} />
                          </h6>
                        ) : (
                          <h6>{details?.species.length > 0 ? details?.species.length : "-"}</h6>
                        )}
                      </div> */}
                      <div
                        class={`rhc-statics-box nav-link  cursor-pointer ${menuItem == "3" && "active"}`}
                        id="pills-five-tab"
                        onClick={() => setMenuItem("2".toString())}
                      >
                        <p>Endangered Species</p>
                        {loadingDetail ? (
                          <h6>
                            <Skeleton borderRadius={"10px"} height={40} width={200} />
                          </h6>
                        ) : (
                          <h6>{endengeredCount?.length > 0 ? endengeredCount.length : "-"}</h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loadingDetail ? (
          <div class="tabs-block-container">
            <div class="tbc-head ">
              <Skeleton
                borderRadius={"10px"}
                height={"7.5vh"}
                baseColor="var(--dark-green-color)"
                highlightColor="#0cb40c"
              />
            </div>
            <div class="container mt-5">
              {" "}
              <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
            </div>
          </div>
        ) : (
          <div class="tabs-block-container new-tabs-block-container">
            <div class="tbc-head mob-height-container">
              <div class="container">
                <div class="row">
                  <div className="col-md-12">
                    <form className="d-lg-none">
                      <select
                        className="form-select"
                        id="tabSelector"
                        onChange={(e) => handleMenuItemClick(e.target.value)}
                        value={menuItem}
                      >
                        {items.map(
                          (item) =>
                            (editMode || item.data?.length > 0) && (
                              <option key={item.id} value={item.id}>
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </option>
                            ),
                        )}
                      </select>
                    </form>
                    <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                      {items.map(
                        (item) =>
                          (editMode || item.data?.length > 0) && (
                            <li className="nav-item" key={item.id} role="presentation">
                              <div
                                className={`nav-link ${menuItem === item.id && "active"}`}
                                id={`pills-${item.id}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-${item.id}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-${item.id}`}
                                aria-selected={menuItem === item.id}
                                onClick={() => handleMenuItemClick(item.id)}
                              >
                                {item.label} {item.count > 0 && `(${item.count})`}
                              </div>
                            </li>
                          ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="tbc-body overflow-hidden">
              <div class="tab-content tab-content-slider" id="pills-tabContent">
                {menuItem == "1" && (
                  <div class={`tab-pane fade ${menuItem == "1" && "active show"}`} id="pills-one">
                    <Details menuItem={menuItem} />
                  </div>
                )}
                {menuItem == "2" && (
                  <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                    <Extinct menuItem={menuItem} id={id} editMode={editMode} />
                  </div>
                )}
                {menuItem == "3" && (
                  <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                    <Reports menuItem={menuItem} id={id} page="wetMarket" setSelectedId={setSelectedId} />
                  </div>
                )}
                {menuItem == "4" && (
                  <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                    <UserUpload menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="wetMarket" />
                  </div>
                )}
                {menuItem == "5" && (
                  <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                    <LiveFeeds menuItem={menuItem} id={id} section="wetMarket" setSelectedId={setSelectedId} />
                  </div>
                )}
                {menuItem == "6" && (
                  <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                    <div className="container">
                      <div class="tbc-map-full detail-page-map region-detail-map">
                        <div class="map-hero-image map-wet-market">
                          {combinedData && (
                            <App
                              data={combinedData}
                              data1={wetMapData}
                              mapType="detail-page"
                              isMultiMarker={true}
                              zoom={"zoom"}
                              singleMap={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {menuItem == "7" && (
                  <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                    <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="wetMarket" data={details} />
                  </div>
                )}
                {menuItem == "8" && (
                  <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                    <References
                      menuItem={menuItem}
                      id={id}
                      setSelectedId={setSelectedId}
                      section="wetMarket"
                      isEdit={editMode}
                    />
                  </div>
                )}
                {menuItem == "9" && (
                  <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                    <Media
                      menuItem={menuItem}
                      id={id}
                      setSelectedId={setSelectedId}
                      section="wetMarket"
                      data={details}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ShareModal
          show={openShareModal}
          onHide={() => setOpenShareModal(!openShareModal)}
          name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.name))}
          id={details?._id}
          category="wetMarket"
        />
        <div class="container custom-citation">
          <div className="custom-citation-text">Citations</div>
          <div
            className="custom-citation-text"
            dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
          ></div>
        </div>
      </main>
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getwetmarketDetails: (params, callback) => dispatch(getwetmarketDetails(params, callback)),
  getwetmarketReports: (params, callback) => dispatch(getwetmarketReports(params, callback)),
  getWetMarketMedia: (params, callback) => dispatch(getWetMarketMedia(params, callback)),
  getWetMarketLiveFeeds: (params, callback) => dispatch(getWetMarketLiveFeeds(params, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getSpeciesWetMarket: (params, data, callback) => dispatch(getSpeciesWetMarket(params, data, callback)),
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
});

const mapStateToProps = (state) => ({
  wetMarketData: wetMarketData(state),
  getMisc: getMisc(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(WetMarketDetail));
