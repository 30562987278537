import React, { useEffect, useReducer, useRef, useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getSpeciesDetails,
  speciesListings,
  getDonation,
  getEnvironmentalist,
  getNews,
  getOrganizations,
  getScienceAndEducation,
  getZoo,
  getRegion,
  getMedia,
  getWetMarkets,
  getSpeciesLiveFeeds,
  updateSpeciesDetails,
} from "../../store/species";
import { getSpeciesList, getZooList, sideBarApisListings, getDangerLevelDropdown } from "../../store/sidebarApis";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Details from "../../components/speciesDetailsPages/details";
import Threats from "../../components/speciesDetailsPages/threats";
import Organizations from "../../components/speciesDetailsPages/organizations";
import People from "../../components/speciesDetailsPages/people";
import Contribute from "../../components/speciesDetailsPages/contribute";
import Region from "../../components/speciesDetailsPages/region";
import ScienceAndEducation from "../../components/speciesDetailsPages/scienceAndEducation";
import Zoo from "../../components/speciesDetailsPages/zoo";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import WetMarkets from "../../components/speciesDetailsPages/wetMarkets";
import { getMisc, getReferences, getSetting, trackArrReport, updateDonationStatus } from "../../store/misc";
import defaultImage from "../../include/images/default_image_species.svg";
import UserUpload from "../../components/common/userUpload/userUpload";
import App from "../../common/maps/map";
import USMap from "../../common/maps/regionDetail/usMap";
import WorldMap from "../../common/maps/regionDetail/worldMap";
import ShowMoreModal from "../../components/common/showMoreModal/showMoreModal";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetterWord,
  validateFiles,
  transformDropdownData,
  cleanAndFormatHtml,
} from "../../utils/helperFunctions";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import ShareModal from "../../components/common/sharemodal/shareModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import shareSvg from "../../include/images/share.svg";
import editIcon from "../../include/images/edit.svg";
import { getProfile, setProfile } from "../../utils/localStorageServices";
import EditSpeciesDetail from "./edit-species-detail";
import AlertSuccess from "../../common/alerts/alertSuccess";
import { Amplify, Auth, Storage } from "aws-amplify";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import { Helmet } from "react-helmet-async";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import { Dropdown } from "react-bootstrap";
import Sponsor from "../../components/speciesDetailsPages/sponsor";
import { getProfileInfo, updateYourProfile } from "../../store/users";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import {
  getsponsor,
  getstory,
  getSubscription,
  updateTransaction,
  updateTransactionStory,
} from "../../store/subscription";
import CorporateSponsor from "../../common/alerts/sponsorAlert";
import SponsorGreetings from "../../components/common/congratsModal/greetingsModal";
import Media from "../../components/common/education/media";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192",
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const SpeciesDetail = (props) => {
  const location = useLocation();
  let sliderRef = useRef();
  const [menuItem, setMenuItem] = useState("11");
  const [id, setId] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathSegments = url.pathname.split("/");
  const [type, setType] = useState("world");
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const profile = getProfile();
  const [isEditable, setIsEditable] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editState, setEditState] = useState({});
  const [follow, setFollow] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [isSponsor, setisSponsor] = useState(false);
  const [isStory, setisStory] = useState(false);
  const [showSponsorAlert, setSponsorAlert] = useState(false);
  const [showGreeting, setShowGreeting] = useState(false);
  const [showStoryGreeting, setShowStoryGreeting] = useState(false);

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  const allRegionDetails = props?.sideBarApisListings?.regionList?.data;

  const usRegions =
    allRegionDetails && allRegionDetails.length > 0 && allRegionDetails.filter((item) => item.country === "US");

  useEffect(() => {
    try {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const params = new URLSearchParams(url.search);
      const redirectParam = params.get("redirect");

      if (redirectParam) {
        const decodedContent1 = atob(redirectParam);
        const pairs = decodedContent1.split("&");
        const decodedContent = {};

        pairs.forEach((pair) => {
          const [key, value] = pair.split("=");

          if (key == "token") {
            decodedContent[key] = pair.slice(6);
          } else {
            decodedContent[key] = decodeURIComponent(value);
          }
        });

        const { speciesName, type, track, species, token } = decodedContent;

        if (type == "donate") {
          const payload = {
            for_donation: true,
            token: token,
          };
          props.updateDonationStatus(payload, (res) => {
            if (res && res.status === 200) {
            }
          });
        } else if (type === "species" && track) {
          const payload = {
            type: type,
            species: species,
            token: token,
          };
          props.trackArrReport(payload, (res) => {
            if (res && res.status === 200) {
            }
          });
        }

        const pathSegments = url.pathname.split("/");
        const lastPathSegment = pathSegments[pathSegments.length - 1];
        const visitedBefore = localStorage.getItem("visitedBefore");

        if (speciesName != lastPathSegment) {
          if (visitedBefore) {
            localStorage.removeItem("visitedBefore");
          }
          // window.location.href = "/";
        } else {
          setMenuItem("2");
          localStorage.setItem("visitedBefore", "true");
        }
      }
    } catch (error) {
      toast(<AlertError message="Error decoding content:" />);
    }
  }, [currentUrl]);

  const areAllArraysEmpty = (obj) => {
    const keys = [
      "interestedEnvironmentalists",
      "interestedOrganizations",
      "interestedRegions",
      "interestedScienceAndEducations",
      "interestedSpecies",
      "interestedWetMarkets",
      "interestedZoos",
    ];

    for (const key of keys) {
      if (!Array.isArray(obj[key]) || obj[key]?.length > 0) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.id) {
      const id = location?.state?.id;
      if (profile?.profile) {
        setIsEditable(profile?.profile?.interestedSpecies?.includes(id) || areAllArraysEmpty(profile?.profile));
        setisSponsor(profile?.profile?.individualSponsoredSpecies?.includes(id));
        setisStory(profile?.profile?.speciesStoryIds?.includes(id));
      }
      if (profile?.profile?.preferredSpecies?.includes(id)) {
        setFollow(true);
      }
      setLoadingDetail(true);
      setId(id);
      props.getSpeciesDetails(id, (res) => {
        if (res && res.status == 200) {
          setLoadingDetail(false);
        }
      });
    } else {
      setLoadingDetail(true);
      props.getSpeciesDetails(pathSegments[pathSegments.length - 1], (res) => {
        if (res && res.status == 200) {
          setId(res?.data?.data?._id);
          if (profile?.profile) {
            setIsEditable(
              profile?.profile?.interestedSpecies?.includes(res?.data?.data?._id) ||
                areAllArraysEmpty(profile?.profile),
            );
            setisSponsor(profile?.profile?.individualSponsoredSpecies?.includes(id));
            setisStory(profile?.profile?.speciesStoryIds?.includes(id));
            if (profile?.profile?.preferredSpecies?.includes(id)) {
              setFollow(true);
            }
          }
          setLoadingDetail(false);
        }
      });
    }
  }, [currentUrl]);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const subscription_id = queryParams.get("subscription_id");
    const baToken = queryParams.get("ba_token");
    const token = queryParams.get("token");
    const type = queryParams.get("type");
    const cancel = queryParams.get("cancel");
    const tempSelectSpecies = JSON.parse(sessionStorage.getItem("tempSelectSpecies") || "[]");

    if (subscription_id && id && token) {
      const speciesId = tempSelectSpecies.length > 0 ? [id, ...tempSelectSpecies] : [id];
      const params = {
        subscription_id,
        speciesId,
        token,
      };

      if (type === "individual" || type === "corporate") {
        params.isCompany = type === "corporate";
        props.updateTransaction(params, (res) => {
          if (res && res.status === 200) {
            if (cancel) {
              toast(<AlertError message={res?.data?.data} />);
            } else {
              setShowGreeting(true);
              setisSponsor(true);
              // toast(<AlertSuccess message={res?.data?.data} />);
            }
          } else {
            console.log("Error updating transaction", res);
          }
        });
      } else {
        params.speciesId = id;
        props.updateTransactionStory(params, (res) => {
          if (res && res.status === 200) {
            if (cancel) {
              toast(<AlertError message={res?.data?.data} />);
            } else {
              setisStory(true);
              setShowStoryGreeting(true);
              toast(<AlertSuccess message={res?.data?.data} />);
            }
            if (!profile?.profile?.organizationName && !profile?.profile?.organizationLogo) {
              setSponsorAlert(true);
            }
          } else {
            console.log("Error updating transaction", res);
          }
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const ids = location?.state?.id || id;
      props.getEnvironmentalist(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getDonation(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getOrganizations(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getScienceAndEducation(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getZoo(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getRegion(ids, (res) => {
        if (res && res.status == 200) {
        }
      });

      props.getWetMarkets(ids, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getSpeciesLiveFeeds({ id: ids, page: 1 }, (res) => {
        if (res && res.status == 200) {
        }
      });
      const params = {
        order: "desc",
        created: "createdAt",
        id: ids,
        page: 1,
        section: "species",
      };
      props.getReferences(params, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getsponsor({ id: ids, page: 1, isActive: true }, (res) => {
        if (res && res.status == 200) {
        }
      });
      props.getstory({ id: ids, page: 1, isActive: true }, (res) => {
        if (res && res.status == 200) {
        }
      });
    }
  }, [id]);

  const details = props.speciesListings && props.speciesListings.speciesDetail;
  const date = new Date(details?.data?.updatedAtVolunteer);
  const organizations = props.speciesListings && props.speciesListings?.organization;

  const contributeOrg =
    props.speciesListings &&
    props.speciesListings?.organization?.data?.filter((item) => !!item?.donationUrl && item?.isAddedToDonation);

  const people = props.speciesListings && props.speciesListings.environmentalist;

  const news = props.speciesListings && props.speciesListings.news?.data;

  const zoo = props.speciesListings && props.speciesListings.zoo;

  const contribute = props.speciesListings && props.speciesListings.donation?.data;

  const science = props.speciesListings && props.speciesListings.scienceAndEducation;

  const region = props.speciesListings && props.speciesListings.region;

  const wetMarket = props.speciesListings && props.speciesListings.wetMarket;
  const sponsorData = props?.getSubscription?.sponsor;
  const storyData = props?.getSubscription?.story;
  let miscSettingCitation = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data?.citation;
  let splashText = props.getMisc && props.getMisc?.setting?.data && props.getMisc?.setting?.data;
  const references = props.getMisc && props.getMisc?.references?.data && props.getMisc?.references?.data;
  const zooList = props.sideBarApisListings && props.sideBarApisListings.zoo;

  const regionsName =
    details?.data &&
    details?.data?.regions &&
    details?.data?.regions.length &&
    details?.data?.regions.map((item) => item);

  const usState =
    regionsName && regionsName.length && regionsName.filter((item) => item.countryName === "United States");

  const livefeeds = props.speciesListings && props.speciesListings?.liveFeeds;
  const dangerLevelDropdown = transformDropdownData(props.sideBarApisListings?.dangerLevel?.data || []);
  const isNumberOrNumericString = (value) =>
    (typeof value === "number" && !isNaN(value)) || (!isNaN(value) && !isNaN(parseFloat(value)));

  const addTargetBlankToLinks = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {
    if (!miscSettingCitation) {
      props.getSetting((res) => {
        if (res && res.status === 200) {
        }
      });
    }
    if (!props.sideBarApisListings.zoo.data) {
      props.getZooList({}, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
    if (!props.sideBarApisListings.dangerLevel.data) {
      props.getDangerLevelDropdown({}, (res) => {
        if (res && res.status === 200) {
        }
      });
    }
  }, []);
  useEffect(() => {
    setEditState({
      lifeSpan: details?.data?.lifeSpan || "",
      weight: details?.data?.weight || "",
      population: details?.data?.population || "",
      name: details?.data?.name || "",
      displayCommonName: details?.data?.displayCommonName || "",
      scientificName: details?.data?.scientificName || "",
      habitat: details?.data?.habitat || "",
      regions: details?.data?.regions || "",
      zoo: zoo?.data,
      description: details?.data?.description || "",
      coverImage: details?.data?.coverImage?.original ? details?.data?.coverImage?.original : defaultImage,
      detailTabImage: details?.data?.detailTabImage?.original
        ? details?.data?.detailTabImage?.original
        : details?.data?.coverImage?.original,
      dangerLevel: details?.data?.dangerLevel,
      humanThreat: details?.data?.humanThreat,
      naturalThreat: details?.data?.naturalThreat,
      climateChangeThreat: details?.data?.climateChangeThreat,
    });
  }, [editMode, details]);

  const combinedData = [
    ...(organizations?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "organizations",
      image: item?.coverImage?.small,
    })),
    ...(people?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "environmentalists",
      image: item?.coverImage?.small,
    })),
    ...(zoo?.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "zoos-and-wildlife-reserves",
      image: item?.coverImage?.small,
    })),
    ...(wetMarket.data || []).map((item) => ({
      id: item?._id,
      name: item.name,
      coordinates: item.geoLocation,
      slug: item?.slug,
      section: "war-on-the-environment-threats",
      image: item?.coverImage?.small,
    })),
  ];
  const speciesMapData = [
    {
      id: details.data?._id,
      name: details.data?.name,
      coordinates: details.data?.geoLocation,
      slug: details.data?.slug,
      section: "endangered-species",
      image: details.data?.coverImage?.small,
    },
  ];

  //Pill Tab

  const handleMenuItemClick = (index) => {
    setMenuItem(index.toString());
  };

  const items = [
    { id: "11", target: "#pills-eleven", label: "Media and Posts", count: null, data: [1, 2] },
    {
      id: "2",
      target: "#pills-two",
      label: "Organizations & Donating",
      count: organizations?.totalCount,
      data: organizations?.data,
    },
    { id: "3", target: "#pills-three", label: "Scientists & Activists", count: people?.totalCount, data: people?.data },
    { id: "4", target: "#pills-four", label: "Region", count: region?.totalCount, data: region?.data },
    { id: "5", target: "#pills-five", label: "Science & Education", count: science?.totalCount, data: science?.data },
    { id: "6", target: "#pills-six", label: "Zoos & Wildlife Reserves", count: zoo?.totalCount, data: zoo?.data },
    {
      id: "7",
      target: "#pills-seven",
      label: "War on the Environment – Threats",
      count: wetMarket?.totalCount,
      data: wetMarket?.data,
    },
    {
      id: "8",
      target: "#pills-eight",
      label: "Sponsors",
      count: sponsorData?.data?.length + storyData?.totalCount,
      data: [1, 2],
    },
    { id: "9", target: "#pills-nine", label: "Live Feeds", count: livefeeds?.totalCount, data: livefeeds?.data },
    { id: "10", target: "#pills-ten", label: "Map", count: null, data: [1, 2] },
    { id: "12", target: "#pills-twelve", label: "References", count: null, data: references },
    { id: "13", target: "#pills-thirteen", label: "Education", count: null, data: [1, 2] },
  ];
  function capitalizeText(text) {
    return text
      ?.split(",")
      .map((word) => {
        const trimmedWord = word.trim();
        return trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);
      })
      .join(", ");
  }

  const handleSave = (e) => {
    e.preventDefault();
    setEditMode(false);
    setLoadingDetail(true);

    const filesToValidate = [];
    if (editState?.coverImage && editState?.coverImage instanceof File) filesToValidate.push(editState.coverImage);
    if (editState?.detailTabImage && editState?.detailTabImage instanceof File)
      filesToValidate.push(editState.detailTabImage);

    if (!validateFiles(filesToValidate)) {
      setLoadingDetail(false);
      return;
    }

    const uploadPromises = [];
    const newEditState = { ...editState };

    const handleFileUpload = (file, key) => {
      const ext = file.name.split(".").pop();
      const fileName = `${uuidv4()}.${ext}`;
      newEditState[key] = `public/${fileName}`;
      return Storage.put(fileName, file, {
        completeCallback: () => {},
        progressCallback: () => {},
        errorCallback: (err) => {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        },
      });
    };

    if (editState.coverImage && editState.coverImage instanceof File) {
      uploadPromises.push(handleFileUpload(editState.coverImage, "coverImage"));
    }

    if (editState.detailTabImage && editState.detailTabImage instanceof File) {
      uploadPromises.push(handleFileUpload(editState.detailTabImage, "detailTabImage"));
    }

    Promise.all(uploadPromises).then(() => {
      const payload = {};
      const compareAndUpdate = (key, newValue, oldValue) => {
        // Only append to payload if the new value is not an empty string and has changed
        if (newValue !== oldValue && newValue !== "") {
          payload[key] = newValue;
        }
      };
      if (newEditState.coverImage && newEditState.coverImage.startsWith("public/")) {
        compareAndUpdate("coverImage", newEditState.coverImage, details.data.coverImage);
      }

      if (newEditState.detailTabImage && newEditState.detailTabImage.startsWith("public/")) {
        compareAndUpdate("detailTabImage", newEditState.detailTabImage, details.data.detailTabImage);
      }
      compareAndUpdate("name", newEditState.name, details.data.name);
      compareAndUpdate("displayCommonName", newEditState.displayCommonName, details.data.displayCommonName);
      compareAndUpdate("scientificName", newEditState.scientificName, details.data.scientificName);
      compareAndUpdate("population", newEditState.population, details.data.population);
      compareAndUpdate("habitat", newEditState.habitat, details.data.habitat);
      compareAndUpdate("lifeSpan", newEditState.lifeSpan, details.data.lifeSpan);
      compareAndUpdate("weight", newEditState.weight, details.data.weight);
      compareAndUpdate("humanThreat", newEditState.humanThreat, details.data.humanThreat);
      compareAndUpdate("naturalThreat", newEditState.naturalThreat, details.data.naturalThreat);
      compareAndUpdate("climateChangeThreat", newEditState.climateChangeThreat, details.data.climateChangeThreat);
      compareAndUpdate("description", newEditState.description, details.data.description);
      compareAndUpdate("dangerLevel", newEditState.dangerLevel.value, details.data.dangerLevel);
      compareAndUpdate(
        "defaultRegion",
        newEditState.defaultRegion?._id || newEditState.defaultRegion,
        details.data.defaultRegion,
      );

      // Proceed with API call
      props.updateSpeciesDetails(details.data._id, payload, (res) => {
        if (res.status === 200) {
          props.getSpeciesDetails(details.data._id, (res1) => {
            setMenuItem("11");
            setLoadingDetail(false);
            if (res1.status === 200) {
              toast(<AlertSuccess message="Information Saved" />);
            } else {
              toast(<AlertError message="Something Went Wrong" />);
            }
          });
        } else {
          setLoadingDetail(false);
          toast(<AlertError message="Something Went Wrong" />);
        }
      });
    });
  };

  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/endangered-species"
        : "https://www.environmentalinclusion.com/endangered-species";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };
  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };

  const displayRegions = details?.data?.regions?.slice(0, 3);
  const remainingRegions = details?.data?.regions?.slice(3);
  const handleSpeciesFollow = (e) => {
    e.preventDefault();
    const payload = {
      preferredSpecies: [details?.data?._id, ...(profile?.profile?.preferredSpecies || [])],
    };
    props.updateYourProfile(payload, (res) => {
      if (res && res.status === 200) {
        toast(<AlertSuccess message="You are now following this Species." />);
        setFollow(true);
      } else {
        toast(<AlertError message={res?.data?.message || "Something went wrong. Please try again later."} />);
      }
    });
  };

  return (
    <PageLayout>
      <Helmet>
        <meta property="og:url" content={getShareUrl(details?.data?.slug)} />
        <meta property="og:type" content="page" />
        <meta property="og:title" content={details?.data?.name} />
        <meta property="og:description" content={details?.data?.description} />
        <meta property="og:image" content={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={details?.data?.name} />
        <meta name="twitter:description" content={details?.data?.description} />
        <meta name="twitter:image" content={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original} />
      </Helmet>
      {editMode && (
        <div class="page-bar yellow-bg text-white">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center justify-content-between">
                <h2 class="page-bar-label">Edit Mode</h2>
                <Link
                  onClick={(e) => {
                    handleSave(e);
                  }}
                  class="btn btn-default btn-xs"
                >
                  Save
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {editMode ? (
        <EditSpeciesDetail
          loadingDetail={loadingDetail}
          setLoadingDetail={setLoadingDetail}
          editMode={editMode}
          details={details}
          setOpenShareModal={setOpenShareModal}
          zoo={zoo}
          menuItem={menuItem}
          setMenuItem={setMenuItem}
          capitalizeText={capitalizeText}
          handleMenuItemClick={handleMenuItemClick}
          items={items}
          id={id}
          setSelectedId={setSelectedId}
          splashText={splashText}
          combinedData={combinedData}
          speciesMapData={speciesMapData}
          toggleShowMoreModal={toggleShowMoreModal}
          usState={usState}
          regionsName={regionsName}
          type={type}
          usRegions={usRegions}
          allRegionDetails={allRegionDetails}
          setType={setType}
          showMoreModal={showMoreModal}
          openShareModal={openShareModal}
          addTargetBlankToLinks={addTargetBlankToLinks}
          miscSettingCitation={miscSettingCitation}
          zooDropdown={zooList.data}
          editState={editState}
          setEditState={setEditState}
          dangerLevelDropdown={dangerLevelDropdown}
          isEditable={isEditable}
          setEditMode={setEditMode}
          region={region}
          getDefaultImage={getDefaultImage}
        />
      ) : (
        <main id="main">
          <div class="content-container overflow-hidden pt-4">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="listing-details-container d-flex flex-wrap justify-content-between">
                    {loadingDetail ? (
                      <div class="ldc-image species-detail-image">
                        <Skeleton className="detail-img-skeleton" />
                      </div>
                    ) : (
                      <div class="ldc-image species-detail-image">
                        <div class="species-details-img">
                          {details?.data?.coverImage?.original ? (
                            <img src={process.env.REACT_APP_MEDIA + details?.data?.coverImage?.original} alt="" />
                          ) : (
                            <img src={getDefaultImage(details?.data?.speciesCategoryName)} alt="" />
                          )}
                        </div>
                      </div>
                    )}
                    <div class="ldc-text">
                      <div className="ldc-text-head">
                        {loadingDetail ? (
                          <Skeleton borderRadius={"10px"} />
                        ) : (
                          <span className="text-title text-red text-capitalize text-dangerLevel d-flex align-items-center justify-content-start">
                            {details?.data?.dangerLevelName}
                            {!isMobile && (
                              <div className={`${isMobile ? "ms-4 mb-2" : "ms-5"}`}>
                                <button
                                  onClick={!follow ? handleSpeciesFollow : undefined}
                                  className="btn btn-outline-green h-40"
                                >
                                  {follow ? "Following" : "Follow"}
                                </button>
                                <Link
                                  className={`btn btn-outline-green h-40 pt-10 ${isSponsor ? "disabled" : ""}`}
                                  to={
                                    profile?.profile
                                      ? {
                                          pathname: `/addsponsor/${details?.data?.slug}`,
                                          state: { id: details?.data?._id },
                                        }
                                      : "/login"
                                  }
                                >
                                  {isSponsor ? "Sponsored" : "Sponsor"}
                                </Link>
                                <Link
                                  className={`btn btn-outline-green h-40 pt-10 margin-1024 ${
                                    isStory ? "disabled" : ""
                                  }`}
                                  to={
                                    profile?.profile
                                      ? { pathname: "/story", state: { id: details?.data?._id } }
                                      : "/login"
                                  }
                                >
                                  {isStory ? "Story Created" : "Create a Story"}
                                </Link>
                              </div>
                            )}
                          </span>
                        )}
                        <div className="ldc-text-main species-name d-flex">
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={40} width={200} />
                          ) : (
                            <>
                              <div>
                                <h1>
                                  <span className="text-capitalize d-flex">
                                    {details?.data?.displayCommonName || details?.data?.scientificName
                                      ? capitalizeContentInBrackets(
                                          details?.data?.displayCommonName || details?.data?.scientificName,
                                        )
                                      : details?.data?.name}
                                  </span>{" "}
                                  <div
                                    className="ml-20 d-flex"
                                    style={isMobile ? { position: "absolute", right: "10px", top: "90%" } : {}}
                                  >
                                    {" "}
                                    <Link onClick={() => setOpenShareModal(true)}>
                                      <img src={shareSvg} alt="" />
                                    </Link>
                                    {isEditable && profile?.profile?.isVolunteer && (
                                      <Link
                                        className="ml-10"
                                        onClick={() => {
                                          setEditMode(true);
                                        }}
                                      >
                                        <img src={editIcon} alt="" />
                                      </Link>
                                    )}
                                  </div>
                                </h1>
                              </div>
                              <div>
                                <img
                                  src={process.env.REACT_APP_MEDIA + details?.data?.dangerLevelImage?.small}
                                  className="ldc-text-main-icon"
                                  alt=""
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <small className="text-capitalize pointer-event-none scientific-text">
                          {details?.data?.displayCommonName && (
                            <span>{details?.data?.scientificName ? details?.data?.scientificName : "-"}</span>
                          )}
                        </small>
                        <small className="text-capitalize pointer-event-none">
                          {(!loadingDetail || details?.data?.speciesCategoryName) && (
                            <>
                              {details?.data?.speciesCategoryName && (
                                <span>
                                  ESA Categories -
                                  {details?.data?.speciesCategoryName ? details?.data?.speciesCategoryName : "-"}
                                </span>
                              )}
                            </>
                          )}
                        </small>
                        {!loadingDetail && isMobile && (
                          <div className="d-block d-md-flex mt-2">
                            <Link
                              onClick={!follow ? handleSpeciesFollow : undefined}
                              className="btn btn-outline-green h-40 pt-10"
                            >
                              {follow ? "Following" : "Follow"}
                            </Link>
                            <Link
                              className={`btn btn-outline-green h-40 pt-10 ${isSponsor ? "disabled" : ""}`}
                              to={
                                profile?.profile
                                  ? {
                                      pathname: `/addsponsor/${details?.data?.slug}`,
                                      state: { id: details?.data?._id },
                                    }
                                  : "/login"
                              }
                            >
                              {isSponsor ? "Sponsored" : "Sponsor"}
                            </Link>
                            <Link
                              className={`btn btn-outline-green h-40 pt-10 mt-2 ms-0  mt-sm-0 ms-sm-2 ${
                                isStory ? "disabled" : ""
                              }`}
                              to={
                                profile?.profile ? { pathname: "/story", state: { id: details?.data?._id } } : "/login"
                              }
                            >
                              {isStory ? "Story Created" : "Create a Story"}
                            </Link>
                          </div>
                        )}
                      </div>
                      <div class="ldc-text-body">
                        <ul class="ltb-details-list d-flex flex-wrap">
                          <li class="ltb-details-item">
                            <div
                              class={
                                zoo?.data?.length
                                  ? `ltb-details-box cursor-pointer ${menuItem == "6" && "active"}`
                                  : `ltb-details-box pointer-event-none`
                              }
                              onClick={() => setMenuItem("6")}
                            >
                              <h4 className="">National Parks/ Sanctuaries</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : (
                                <>
                                  {zoo && zoo?.data?.length > 0 ? (
                                    <>
                                      {zoo.data.map(
                                        (item, idx) =>
                                          idx <= 1 && (
                                            <p className={`caps-text ${idx === 1 ? "mt-0" : ""}`} key={idx}>
                                              {item?.name}
                                            </p>
                                          ),
                                      )}
                                      {zoo.data.length > 4 && <span>...</span>}
                                    </>
                                  ) : (
                                    <h4>-</h4>
                                  )}
                                </>
                              )}
                            </div>
                          </li>
                          <li class="ltb-details-item">
                            <div className="ltb-details-box pointer-event-none">
                              <h4>Common Names</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : details?.data?.name ? (
                                <p className="caps-text" data-tooltip={capitalizeText(details?.data?.name)}>
                                  {details?.data?.name?.split(",")?.slice(0, 8).join(", ")}
                                  {details?.data?.name?.split(",")?.length > 8 && " ..."}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>
                          <li className="ltb-details-item">
                            <div className="ltb-details-box">
                              <h4>Regions</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : (
                                <>
                                  {details?.data?.regions?.length > 0 ? (
                                    <>
                                      <p className="caps-text">
                                        {displayRegions.map((region, index) => (
                                          <span key={region._id} className="d-block">
                                            {/* Check if the country is United States, then show both state and country */}
                                            {region.countryName === "United States" && region.state
                                              ? `${region.state}, ${region.countryName}`
                                              : region.countryName}
                                            {index < displayRegions.length - 1 ? ", " : ""}
                                          </span>
                                        ))}
                                      </p>

                                      {remainingRegions.length > 0 && (
                                        <div className="td-link-text fw-semibold">
                                          <Dropdown bsPrefix="header-name-box fw-medium dropdown cursor-pointer">
                                            <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle">
                                              <p
                                                className="td-a-icon"
                                                style={{ color: "#47AD1D", cursor: "pointer" }}
                                                data-bs-toggle="dropdown"
                                                aria-expanded={false}
                                              >
                                                <u>View More</u>
                                              </p>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                              bsPrefix="dropdown-menu dropdown-menu-end"
                                              style={{ background: "#fff", maxHeight: "200px", overflowY: "auto" }}
                                            >
                                              {remainingRegions.map((region) => (
                                                <Dropdown.Item key={region._id}>
                                                  <tr>
                                                    {/* Check if the country is United States, then show both state and country */}
                                                    {region.countryName === "United States" && region.state
                                                      ? `${region.state}, ${region.countryName}`
                                                      : region.countryName}
                                                  </tr>
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <h4>-</h4>
                                  )}
                                </>
                              )}
                            </div>
                          </li>
                          <li className="ltb-details-item">
                            <div className="ltb-details-box pointer-event-none">
                              <h4>Population</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : details?.data?.population ? (
                                <p>
                                  {isNumberOrNumericString(details.data.population)
                                    ? Number(details.data.population).toLocaleString("en-US")
                                    : details.data.population}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>

                          <li class="ltb-details-item">
                            <div class="ltb-details-box pointer-event-none">
                              <h4>Habitat</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : details?.data?.habitat ? (
                                <p className="caps-text" data-tooltip={capitalizeText(details?.data?.habitat)}>
                                  {details?.data?.habitat.split(",").slice(0, 8).join(", ")}
                                  {details?.data?.habitat.split(",").length > 8 && " ..."}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>

                          <li class="ltb-details-item">
                            <div class="ltb-details-box pointer-event-none">
                              <h4>Weight(in lbs) and Life Span</h4>
                              {loadingDetail ? (
                                <Skeleton borderRadius={"10px"} height={20} width={150} />
                              ) : details?.data?.weight || details?.data?.lifeSpan ? (
                                <p>
                                  {details?.data?.weight
                                    ? details?.data?.weight.length > 20
                                      ? details?.data?.weight.slice(0, 20) + "..."
                                      : details?.data?.weight
                                    : ""}

                                  {details?.data?.weight && details?.data?.lifeSpan && " and "}

                                  {details?.data?.lifeSpan ? details?.data?.lifeSpan : ""}
                                </p>
                              ) : (
                                <h4>-</h4>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <Details loading={loadingDetail} menuItem={menuItem} id={id} setSelectedId={setSelectedId} />
                </div>
              </div>
            </div>
          </div>
          {loadingDetail ? (
            <div class="tabs-block-container">
              <div class="tbc-head ">
                <Skeleton
                  borderRadius={"10px"}
                  height={"7.5vh"}
                  baseColor="var(--dark-green-color)"
                  highlightColor="#0cb40c"
                />
              </div>
              <div class="tbc-body overflow-hidden">
                <div className="container d-sm-block d-lg-none">
                  <Skeleton
                    borderRadius={"10px"}
                    height={"80vh"}
                    width={"100%"}
                    style={{ marginTop: "40px", display: "flex" }}
                  />
                </div>
                <div class="tab-content tab-content-slider d-none d-lg-block" id="pills-tabContent">
                  <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tbc-body-inner">
                            <div class="education-container d-flex">
                              <div className="w-25">
                                <Skeleton borderRadius={"10px"} height={"80vh"} width={"100%"} />
                              </div>
                              <div className="education-body custom-mt-5" id="mapTab" role="tablist">
                                {[1, 2, 3, 4].map((skeletonItem) => (
                                  <div class="widget d-flex flex-wrap" key={skeletonItem}>
                                    <div class="widget-icon z-0">
                                      <Skeleton borderRadius={"50%"} height={45} width={45} zIndex={0} />
                                    </div>
                                    <div class="widget-content z-0">
                                      <div class="widget-text-holder">
                                        <div class="w-heading-block position-relative">
                                          <div class="w-heading-box d-flex align-items-center flex-wrap ">
                                            <div className="w-label-box fw-semibold text-capitalize">
                                              <Skeleton borderRadius={"10px"} height={45} width={150} />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-image-holder mt-35 z-0">
                                          <div className="d-flex flex-wrap">
                                            <div
                                              className=" position-relative"
                                              style={{
                                                width: "100%",
                                              }}
                                            >
                                              <Skeleton borderRadius={"10px"} height={"40vh"} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div id="tabBtnContainer" className={`education-right`} style={{ right: "0px" }}>
                                <Skeleton borderRadius={"10px"} height={"80vh"} width={"100%"} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="tabs-block-container new-tabs-block-container">
              <div class="tbc-head ">
                <div class="container">
                  <div class="row">
                    <div className="col-md-12">
                      <form className="d-lg-none">
                        <select
                          className="form-select"
                          id="tabSelector"
                          onChange={(e) => handleMenuItemClick(e.target.value)}
                          value={menuItem}
                        >
                          {items.map(
                            (item) =>
                              item.data?.length > 0 && (
                                <option key={item.id} value={item.id}>
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </option>
                              ),
                          )}
                        </select>
                      </form>
                      <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                        {items.map(
                          (item) =>
                            item.data?.length > 0 && (
                              <li className="nav-item" key={item.id} role="presentation">
                                <div
                                  className={`nav-link ${menuItem === item.id && "active"}`}
                                  id={`pills-${item.id}-tab`}
                                  data-bs-toggle="pill"
                                  data-bs-target={`#pills-${item.id}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`pills-${item.id}`}
                                  aria-selected={menuItem === item.id}
                                  onClick={() => handleMenuItemClick(item.id)}
                                >
                                  {item.label} {item.count > 0 && `(${item.count})`}
                                </div>
                              </li>
                            ),
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tbc-body overflow-hidden">
                <div class="tab-content tab-content-slider" id="pills-tabContent">
                  {menuItem == "2" && (
                    <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                      <Organizations
                        menuItem={menuItem}
                        id={id}
                        name={details?.data?.name}
                        slug={details?.data?.slug}
                        splashData={splashText}
                      />
                    </div>
                  )}
                  {menuItem == "3" && (
                    <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                      <People menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "4" && (
                    <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                      <Region
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        loading={loadingDetail}
                        region={details.data.regions}
                      />
                    </div>
                  )}
                  {menuItem == "5" && (
                    <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                      <ScienceAndEducation menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "6" && (
                    <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                      <Zoo menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "7" && (
                    <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                      <WetMarkets menuItem={menuItem} id={id} />
                    </div>
                  )}
                  {menuItem == "8" && (
                    <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                      <Sponsor menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                    </div>
                  )}
                  {menuItem == "9" && (
                    <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                      <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                    </div>
                  )}
                  {menuItem == "10" && (
                    <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                      <div className="container">
                        <div class="tbc-map-full detail-page-map region-detail-map">
                          <div class="map-hero-image map-wet-market">
                            {combinedData && (
                              <App
                                data={combinedData}
                                data1={speciesMapData}
                                mapType="detail-page"
                                isMultiMarker={true}
                                zoom={"zoom"}
                                singleMap={true}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {menuItem == "11" && (
                    <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                      <Education
                        loading={loadingDetail}
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        section="species"
                        data={details?.data}
                      />
                    </div>
                  )}
                  {menuItem == "12" && (
                    <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                      <References
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        section="species"
                        isEdit={editMode}
                      />
                    </div>
                  )}
                  {menuItem == "13" && (
                    <div class={`tab-pane fade ${menuItem == "13" && "active show"}`} id="pills-thirteen">
                      <Media
                        loading={loadingDetail}
                        menuItem={menuItem}
                        id={id}
                        setSelectedId={setSelectedId}
                        section="species"
                        data={details?.data}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {menuItem == "6" && (
            <div className="container">
              <div className="d-flex space-between species-region-text" style={{ marginTop: "20px" }}>
                <p className="map-switch cursor-pointer" onClick={() => toggleShowMoreModal()}>
                  View All Regions Name
                </p>

                {usState?.length > 0 && (
                  <p
                    className="map-switch cursor-pointer"
                    onClick={() => {
                      if (type == "us") {
                        setType("world");
                      } else if (type == "world") {
                        setType("us");
                      }
                    }}
                  >
                    Switch to {type === "us" ? "All " : "US"} region map
                  </p>
                )}
              </div>
              <div class="tbc-map-full">
                <div class="map-hero-image">
                  {(regionsName && regionsName?.length === 1 && regionsName[0]?.countryName === "United States") ||
                  type == "us" ? (
                    <USMap
                      name={
                        (regionsName && regionsName.length === 1) || type == "us"
                          ? usState?.map((item) => item?.state)
                          : usState?.map((item) => item?.countryName)
                      }
                      data={usRegions}
                      setSelectedId={setSelectedId}
                      page="species"
                      isNotClickable={true}
                    />
                  ) : (
                    <WorldMap
                      name={regionsName && regionsName?.length && regionsName?.map((item) => item?.countryName)}
                      data={allRegionDetails}
                      setSelectedId={setSelectedId}
                      page="species"
                      isNotClickable={true}
                      setType={setType}
                      showAllMap={true}
                    />
                  )}
                </div>
                <ShowMoreModal
                  show={showMoreModal}
                  onHide={toggleShowMoreModal}
                  listData={regionsName}
                  heading={`${details?.data?.name} found in these regions`}
                  isNonEditable={true}
                  type="regions"
                />
              </div>
            </div>
          )}
          <ShareModal
            show={openShareModal}
            onHide={() => setOpenShareModal(!openShareModal)}
            name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
            id={details?.data?._id}
            category="species"
          />
          <div className="container custom-citation">
            <div className="custom-citation-text">Citations</div>
            <div
              className="custom-citation-text"
              dangerouslySetInnerHTML={{ __html: cleanAndFormatHtml(miscSettingCitation) }}
            ></div>
            {details?.data?.updatedByVolunteer?.firstName && (
              <div className="last-edited">
                {" "}
                Last edited on {date.toLocaleString()} by{" "}
                <span className="text-capitalize">
                  {details?.data?.updatedByVolunteer?.firstName} {details?.data?.updatedByVolunteer?.lastName}{" "}
                </span>
              </div>
            )}
          </div>
        </main>
      )}
      <CorporateSponsor
        show={showSponsorAlert}
        onHide={() => {
          setSponsorAlert(!showSponsorAlert);
        }}
        loading={loadingDetail}
      />
      <SponsorGreetings
        show={showGreeting}
        onHide={() => {
          setShowGreeting(!showGreeting);
        }}
        data="Thank you for sponsoring this species! Your generous contribution helps protect and preserve endangered species.
                We truly appreciate your support!"
        loading={loadingDetail}
        slug={details?.data?.slug}
      />
      <SponsorGreetings
        show={showStoryGreeting}
        onHide={() => {
          setShowStoryGreeting(!showStoryGreeting);
        }}
        data="Thank you for creating a story to raise awareness! Your contribution plays a crucial role in protecting and preserving endangered species, and we appreciate your support in sharing their stories."
        loading={loadingDetail}
        slug={details?.data?.slug}
      />
    </PageLayout>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getDonation: (params, callback) => dispatch(getDonation(params, callback)),
  getEnvironmentalist: (params, callback) => dispatch(getEnvironmentalist(params, callback)),
  getNews: (params, callback) => dispatch(getNews(params, callback)),
  getOrganizations: (params, callback) => dispatch(getOrganizations(params, callback)),
  getWetMarkets: (params, callback) => dispatch(getWetMarkets(params, callback)),
  getScienceAndEducation: (params, callback) => dispatch(getScienceAndEducation(params, callback)),
  getZoo: (params, data, callback) => dispatch(getZoo(params, data, callback)),
  getRegion: (params, data, callback) => dispatch(getRegion(params, data, callback)),
  getMedia: (params, data, callback) => dispatch(getMedia(params, data, callback)),
  getSetting: (callback) => dispatch(getSetting(callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getSpeciesLiveFeeds: (params, callback) => dispatch(getSpeciesLiveFeeds(params, callback)),
  updateDonationStatus: (data, callback) => dispatch(updateDonationStatus(data, callback)),
  trackArrReport: (data, callback) => dispatch(trackArrReport(data, callback)),
  updateSpeciesDetails: (params, data, callback) => dispatch(updateSpeciesDetails(params, data, callback)),
  getDangerLevelDropdown: (params, callback) => dispatch(getDangerLevelDropdown(params, callback)),
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getReferences: (params, callback) => dispatch(getReferences(params, callback)),
  updateTransaction: (params, callback) => dispatch(updateTransaction(params, callback)),
  updateTransactionStory: (params, callback) => dispatch(updateTransactionStory(params, callback)),
  getsponsor: (params, callback) => dispatch(getsponsor(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
  getProfileInfo: (params, callback) => dispatch(getProfileInfo(params, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  sideBarApisListings: sideBarApisListings(state),
  getMisc: getMisc(state),
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(SpeciesDetail));
