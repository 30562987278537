import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import close from "../../../include/images/x-close-24x24.svg";
import close1 from "../../../include/images/close-24x24.svg";
import { useRef } from "react";
import { connect } from "react-redux";
import { postReports } from "../../../store/post";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";

const ReportPost = (props) => {
  var ref = useRef(null);
  const [reportPostDetails, setReportPostDetails] = useState({
    selectedOption: "",
    comments: "",
  });
  const [loading, setLoading] = useState(false);

  const handleReport = () => {
    const id = props?.selectedItem?._id;
    const payload = {
      option: reportPostDetails?.selectedOption,
      description: reportPostDetails?.comments,
    };
    setLoading(true);
    ref?.current?.continuousStart();
    props?.postReports(id, payload, (res) => {
      if (res && res.status === 200) {
        setReportPostDetails({ selectedOption: "", comments: "" });
        toast(<AlertSuccess message="Post Reported Successfully" />);
        props?.onHide();
        ref?.current?.complete();
        setLoading(false);
      } else {
        setLoading(false);
        ref?.current?.complete();
        toast(<AlertError message={res?.data?.message ? res.data.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Modal
        className="modal fade signup-modal font-family-poppins"
        id="reportPostModal"
        dialogClassName="modal-dialog modal-dialog-centered xs-modal"
        contentClassName="modal-content"
        show={props?.show}
        onHide={() => props?.onHide()}
      >
        <div class="signup-modal-parent d-flex flex-wrap">
          <div class="signup-modal-box">
            <div className="signup-modal-header">
              <div class="smh-insider position-relative">
                <h4>Report this Post</h4>
                {props.page == "viewpost" && (
                  <div
                    class="signup-modal-close ms-auto position-absolute top-0 end-0"
                    data-bs-dismiss="modal"
                    onClick={() => props?.onHide()}
                  >
                    <img src={close1} alt="x close icon" />
                  </div>
                )}
                <div class="modal_close-box d-lg-none">
                  <div class="signup-modal-close ms-auto" data-bs-dismiss="modal" onClick={() => props?.onHide()}>
                    <img src={close1} alt="x close icon" />
                  </div>
                </div>
              </div>
              <p>
                Please help us keep Environmental Inclusion dedicated to helping endangered animals and kid-friendly. If
                you see a post that is not appropriate, please report it, so we can investigate.
              </p>
            </div>
            <Modal.Body className="signup-modal-body pt-0">
              <div class="page-modal-check-group">
                <div class="page-modal-check-item">
                  <div class="custom-form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="modalCheck__1"
                      checked={reportPostDetails?.selectedOption == "irrelevant"}
                      onChange={() => {
                        const data = { ...reportPostDetails };
                        data.selectedOption = "irrelevant";
                        setReportPostDetails(data);
                      }}
                    />
                    <label class="form-check-label" for="modalCheck__1">
                      Irrelevant
                    </label>
                  </div>
                </div>
                <div class="page-modal-check-item">
                  <div class="custom-form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="modalCheck__2"
                      checked={reportPostDetails?.selectedOption == "falseInformation"}
                      onChange={() => {
                        const data = { ...reportPostDetails };
                        data.selectedOption = "falseInformation";
                        setReportPostDetails(data);
                      }}
                    />
                    <label class="form-check-label" for="modalCheck__2">
                      False-information
                    </label>
                  </div>
                </div>
                <div class="page-modal-check-item">
                  <div class="custom-form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="modalCheck__3"
                      checked={reportPostDetails?.selectedOption == "racistSexistAndHateSpeech"}
                      onChange={() => {
                        const data = { ...reportPostDetails };
                        data.selectedOption = "racistSexistAndHateSpeech";
                        setReportPostDetails(data);
                      }}
                    />
                    <label class="form-check-label" for="modalCheck__3">
                      Racist, Sexist, or Hate Speech
                    </label>
                  </div>
                </div>
                <div class="page-modal-check-item">
                  <div class="custom-form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="modalCheck__4"
                      checked={reportPostDetails?.selectedOption == "profanityAndNudity"}
                      onChange={() => {
                        const data = { ...reportPostDetails };
                        data.selectedOption = "profanityAndNudity";
                        setReportPostDetails(data);
                      }}
                    />
                    <label class="form-check-label" for="modalCheck__4">
                      Profanity and Nudity
                    </label>
                  </div>
                </div>
              </div>
              <div class="page-modal-text-area">
                <textarea
                  class="form-control"
                  id="FormControlTextarea1"
                  placeholder="Tell us more about it"
                  value={reportPostDetails?.comments}
                  onChange={(e) => {
                    const data = { ...reportPostDetails };
                    data.comments = e.target.value;
                    setReportPostDetails(data);
                  }}
                ></textarea>
              </div>
            </Modal.Body>
            <div class="signup-modal-footer d-flex align-items-start justify-content-end">
              <a
                href="javascript:void(0);"
                class={`${
                  loading || !reportPostDetails?.selectedOption || !reportPostDetails?.comments ? "disabled" : ""
                } btn btn-default btn-md`}
                onClick={() => handleReport()}
              >
                Save
              </a>
            </div>
          </div>
          {props.page != "viewpost" && (
            <div class="signup-modal-close-box d-none d-lg-block">
              <div class="signup-modal-close ms-auto" data-bs-dismiss="modal">
                <img src={close} alt="x close icon" onClick={() => props?.onHide()} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  postReports: (id, parmas, callback) => dispatch(postReports(id, parmas, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ReportPost));
