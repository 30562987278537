import React, { useEffect, useState } from "react";
import noRecord from "../../include/images/nrb-img.svg";
import { Link } from "react-router-dom";
import paypalIcon from "../../include/images/paypal.svg";
import { connect } from "react-redux";
import { getTransaction, getUser } from "../../store/users";
import Skeleton from "react-loading-skeleton";

const Transaction = (props) => {
  const data = props?.getUser?.transaction?.data;
  const [page, setPage] = useState(1);

  useEffect(() => {
    const payload = {
      isActive: false,
      page: page,
    };
    if (page > 1) {
      props.getTransaction(payload, (res) => {
        if (res.status === 200) {
          // Handle success
        } else {
          // Handle failure
        }
      });
    }
  }, [page]);
  return (
    <div className="transaction-content-wrapper d-flex flex-column">
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton height={150} width="100%" className="" />
          <Skeleton height={150} width="100%" className="mt-2" />
        </div>
      ) : (
        <>
          {data && data.length > 0 ? (
            data.map((transaction, index) => (
              <div key={transaction._id} className="transaction-content-head">
                <div className="transaction-head-box d-flex flex-column justify-content-between">
                  <div className="tcb-upper d-flex flex-wrap justify-content-between">
                    <div className="tcb-upper-left d-flex">
                      <div className="tcb-upper-icon">
                        <img src={paypalIcon} alt="Paypal Icon" />
                      </div>
                      <div className="tcb-upper-text">
                        <div className="tcb-upper-head d-flex align-items-center">
                          <h6 className="mb-0">PayPal</h6>
                          {/* <a
                        href={transaction?.billing_info?.links[1].href}
                        className="btn-edit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Edit
                      </a> */}
                        </div>
                        <div className="tcb-upper-body">
                          Subscription ID : ******** {transaction?.subscription_id?.slice(-4)}
                        </div>
                      </div>
                    </div>
                    {transaction?.status == "COMPLETED" && (
                      <div className="tcb-upper-right">
                        <h6>Next Payment Date</h6>
                        {transaction?.next_billing_date && (
                          <h5 className="mb-0">{new Date(transaction?.next_billing_date).toLocaleDateString()}</h5>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="tcb-lower d-flex flex-wrap">
                    <div className="tcb-lower-box text-center">
                      <h6>Amount</h6>
                      <h5 className="mb-0">{transaction.isCompany ? "$600" : "$150"}</h5>
                    </div>

                    <div className="tcb-lower-box text-center">
                      {transaction?.status === "COMPLETED" && transaction?.last_payment?.time && (
                        <>
                          <h6>Sponsorship Start Date</h6>
                          <h5 className="mb-0">{new Date(transaction?.last_payment?.time).toLocaleDateString()}</h5>
                        </>
                      )}
                    </div>
                    <div className="tcb-lower-box text-center">
                      <h6>Status</h6>
                      <h5 className="mb-0">{transaction?.status}</h5>
                    </div>
                  </div>
                </div>
                <div className="transaction-content-body">
                  {/* <div className="transaction-content-list d-flex flex-column">
                {transaction.billing_info.cycle_executions?.map((cycle, cycleIndex) => (
                  <div key={cycleIndex} className="transaction-content-item">
                    <div className="transaction-content-box d-flex flex-wrap align-items-center">
                      <div className="tcb-left flex-grow-1">
                        <h5>Transaction ID - {transaction?.billing_info.id}</h5>
                        <h6 className="mb-0">
                          {new Date(transaction?.billing_info?.last_payment.time).toLocaleDateString()}
                        </h6>
                      </div>
                      <div className="tcb-right flex-shrink-0">
                        - ${transaction.billing_info?.last_payment?.amount?.value}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
                </div>
              </div>
            ))
          ) : (
            <div className="panel-empty-wrapper d-flex flex-column align-items-center text-center mx-auto pt-5">
              <img src={noRecord} alt="No Record" className="pew-icon icon-lg ms-0" />
              <h5>No Transactions Found</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTransaction: (param, callback) => {
    dispatch(getTransaction(param, callback));
  },
});
const mapStateToProps = (state) => ({
  getUser: getUser(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Transaction));
