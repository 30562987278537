import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../components/forgotPassword/layout";
import Select from "react-select";
import {
  getEnvironmentalistList,
  getOrganizationList,
  getRegionList,
  getScienceAndEducationList,
  getSpeciesList,
  getWetMarketList,
  getZooList,
  sideBarApisListings,
} from "../../store/sidebarApis";
import { connect } from "react-redux";
import NextButton from "../../common/form/nextButton";
import { getProfile, removeKey, setProfile, setVolunteerPreference } from "../../utils/localStorageServices";
import { useHistory } from "react-router-dom";
import { getProfileInfo, volunteerPreference } from "../../store/users";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { capitalizeContentInBrackets, transformDropdownData } from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
const customStyles1 = {
  indicatorSeparator: () => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 42,
    borderColor: "transparent",
    borderBottom: state.isFocused ? "2px solid #47AD1D" : "1px solid #e4e4e4",
    borderRadius: 0,
    boxShadow: "none",
    "&:hover": {
      borderBottom: "2px solid #47AD1D",
    },
  }),
  multiValueRemove: (base) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
  menu: (base) => ({
    ...base,
    marginTop: 0,
    borderRadius: 0,
  }),
};

const VolunteerPreference = (props) => {
  const [selectedPreference, setSelectedPreference] = useState({
    species: [],
    organization: [],
    region: [],
    zoo: [],
    wetMarket: [],
    environmentalist: [],
    science: [],
  });
  const [allContentChecked, setAllContentChecked] = useState(false);
  const profile = getProfile();
  const history = useHistory();
  const isLoggedIn = !!localStorage.getItem("data");
  const isMobile = useCheckMobileScreen();
  const transformDropdownDataSpecies = (data, labelKey = "scientificName", valueKey = "_id") => {
    return data
      .filter((item) => item[labelKey] || item.displayCommonName)
      .map((item) => ({
        label: capitalizeContentInBrackets(item.displayCommonName || item[labelKey]),
        value: item[valueKey],
      }));
  };
  const speciesDropdown = transformDropdownDataSpecies(props.sideBarApisListings?.speciesList?.data || []);
  const organizationDropdown = transformDropdownData(props.sideBarApisListings?.organizationList?.data || []);
  const regionsDropdown = transformDropdownData(props.sideBarApisListings?.regionList?.data || []);
  const zooDropdown = transformDropdownData(props.sideBarApisListings?.zoo?.data || []);
  const wetMarketDropdown = transformDropdownData(props.sideBarApisListings?.wetMarket?.data || []);
  const environmentalistDropdown = transformDropdownData(props.sideBarApisListings?.environmentalist?.data || []);

  const extractLabel = useCallback((interestedArray, dropdownArray) => {
    return interestedArray
      ?.map((id) => {
        const foundObject = dropdownArray?.find((obj) => obj?.value === id);
        return foundObject ? { value: foundObject.value, label: foundObject.label } : null;
      })
      .filter((item) => item);
  }, []);
  const areAllArraysEmpty = (obj) => {
    const keys = [
      "interestedEnvironmentalists",
      "interestedOrganizations",
      "interestedRegions",
      "interestedScienceAndEducations",
      "interestedSpecies",
      "interestedWetMarkets",
      "interestedZoos",
    ];

    for (const key of keys) {
      if (!Array.isArray(obj[key]) || obj[key]?.length > 0) {
        return false;
      }
    }

    return true;
  };
  Useauthredirect();
  useEffect(() => {
    if (!props.sideBarApisListings?.regionList?.data) {
      props.getRegionList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.organizationList?.data) {
      props.getOrganizationList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.zoo?.data) {
      props.getZooList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.wetMarket?.data) {
      props.getWetMarketList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.environmentalist?.data) {
      props.getEnvironmentalistList({}, (res) => {});
    }
    if (!props.sideBarApisListings?.speciesList?.data) {
      props.getSpeciesList({}, (res) => {});
    }
    props.getProfileInfo((res) => {
      if (res && res.status === 200) {
        const {
          interestedSpecies,
          interestedOrganizations,
          interestedRegions,
          interestedZoos,
          interestedWetMarkets,
          interestedEnvironmentalists,
        } = res.data.data;
        setSelectedPreference({
          species: extractLabel(interestedSpecies, speciesDropdown),
          organization: extractLabel(interestedOrganizations, organizationDropdown),
          region: extractLabel(interestedRegions, regionsDropdown),
          zoo: extractLabel(interestedZoos, zooDropdown),
          wetMarket: extractLabel(interestedWetMarkets, wetMarketDropdown),
          environmentalist: extractLabel(interestedEnvironmentalists, environmentalistDropdown),
        });
      }
    });
  }, []);
  useEffect(() => {
    if (isLoggedIn && profile?.profile?.isVolunteer) {
      if (areAllArraysEmpty(profile?.profile)) {
        setAllContentChecked((prevState) => !prevState);
        if (!allContentChecked) {
          setSelectedPreference({
            species: [],
            organization: [],
            region: [],
            zoo: [],
            wetMarket: [],
            environmentalist: [],
            science: [],
          });
        }
      } else {
        const {
          interestedSpecies,
          interestedOrganizations,
          interestedRegions,
          interestedZoos,
          interestedWetMarkets,
          interestedEnvironmentalists,
        } = profile.profile;
        setSelectedPreference({
          species: extractLabel(interestedSpecies, speciesDropdown),
          organization: extractLabel(interestedOrganizations, organizationDropdown),
          region: extractLabel(interestedRegions, regionsDropdown),
          zoo: extractLabel(interestedZoos, zooDropdown),
          wetMarket: extractLabel(interestedWetMarkets, wetMarketDropdown),
          environmentalist: extractLabel(interestedEnvironmentalists, environmentalistDropdown),
        });
      }
    }
  }, []);

  const handleSelectChange = useCallback(
    (type, selectedOptions) => {
      if (!allContentChecked) {
        setSelectedPreference((prevState) => ({
          ...prevState,
          [type]: selectedOptions,
        }));
      }
    },
    [allContentChecked],
  );

  const handleCheckboxChange = () => {
    setAllContentChecked((prevState) => !prevState);
    if (!allContentChecked) {
      setSelectedPreference({
        species: [],
        organization: [],
        region: [],
        zoo: [],
        wetMarket: [],
        environmentalist: [],
        science: [],
      });
    }
  };

  const extractIds = (selectedOptions) => {
    return selectedOptions.map((option) => option.value);
  };

  const handleSubmit = () => {
    let payload = {
      species: [],
      organizations: [],
      regions: [],
      zoos: [],
      wetMarkets: [],
      environmentalists: [],
    };

    if (!allContentChecked) {
      payload = {
        species: extractIds(selectedPreference.species),
        organizations: extractIds(selectedPreference.organization),
        regions: extractIds(selectedPreference.region),
        zoos: extractIds(selectedPreference.zoo),
        wetMarkets: extractIds(selectedPreference.wetMarket),
        environmentalists: extractIds(selectedPreference.environmentalist),
      };
    }

    props.volunteerPreference(profile?.profile._id, payload, (res) => {
      if (res && res.status === 200) {
        if (isLoggedIn) {
          let updatedProfile = { ...profile };
          updatedProfile.profile.interestedSpecies = payload.species;
          updatedProfile.profile.interestedOrganizations = payload.organizations;
          updatedProfile.profile.interestedRegions = payload.regions;
          updatedProfile.profile.interestedZoos = payload.zoos;
          updatedProfile.profile.interestedWetMarkets = payload.wetMarkets;
          updatedProfile.profile.interestedEnvironmentalists = payload.environmentalists;
          setProfile(updatedProfile);
        }
        toast(
          <AlertSuccess
            message={
              isLoggedIn && profile?.profile?.isVolunteer
                ? "Information Saved"
                : "Congratulations! Your Volunteer request is now pending for admin approval."
            }
          />,
        );
        removeKey("preferences");
        history.push("/");
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleNext = () => {
    setVolunteerPreference(selectedPreference);
    history.push("/signup");
  };
  return (
    <Layout>
      <div className="page-outer-wrapper font-family-poppins grey-bg min-vh-100">
        <div className="registration-flow-container fw-medium">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="rfc-content-holder mx-auto">
                  <div className="rf-head-row  d-flex flex-wrap justify-content-center">
                    <h1>Content that you are interested in updating or adding.</h1>
                  </div>
                  <div className="rf-form-row-group d-flex flex-wrap justify-content-center custom-volunteer-form">
                    <div className="w-50 intern-program-container">
                      <h6>We have introduced a new intern program</h6>
                      <p>
                        Designed for university students to be involved in the development of the first social media
                        site dedicated to endangered species. We have over 14,000 species from the animal kingdom
                        including mammals, reptiles, birds, crustations, amphibians, sharks & rays, reef corals, and
                        insect classes that are in danger of extinction. We are building pages for each animal including
                        photos, videos, science papers, zoo reports, conservation reports, animal art, organizations,
                        biologist and scientists to bring awareness about the programs and threats to each species.
                      </p>
                      <h6>Here is a summary of the program:</h6>
                      <ol>
                        <li>
                          Register on Environmental Inclusion and select species of interest. Let us know the
                          university, department and professors involved.
                          <a
                            href="https://www.environmentalinclusion.com/signup?type=volunteer"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://www.environmentalinclusion.com/signup?type=volunteer
                          </a>
                        </li>
                        <li>
                          The student will then have full access to develop and build one species page with the most
                          current information available.
                        </li>
                        <li>
                          Student will engage scientists, biologists, environmentalist, zoos, non-profits,
                          preservations, and advocates to collect and collaborate on EnvironmentalInclusion.com.
                        </li>
                        <li>
                          The goal is to organize, develop and promote the conservation efforts to save this animal
                          through research and journalism.
                        </li>
                        <li>
                          After the internship completion, Environmental Inclusion will supply the professor with a
                          detailed report showing the results and comments regarding the project.
                        </li>
                      </ol>
                    </div>

                    <div
                      className={`form-floating-row d-flex flex-wrap justify-content-center ${
                        isMobile ? "w-100" : "w-50"
                      }`}
                    >
                      <div className="ff-column w-100">
                        <label className="form-label filter-content-box filter-content-box-1 fw-medium">
                          <input type="checkbox" checked={allContentChecked} onChange={handleCheckboxChange} />
                          <span class="checkmark" style={{ height: "24px", width: "24px", top: "2px" }}></span>
                          All Content
                        </label>
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="species" className="form-label">
                          Species
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={speciesDropdown}
                          value={selectedPreference.species}
                          onChange={(selectedOptions) => handleSelectChange("species", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      {/* <div className="ff-column w-100">
                        <label htmlFor="organization" className="form-label">
                          Organizations
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={organizationDropdown}
                          value={selectedPreference.organization}
                          onChange={(selectedOptions) => handleSelectChange("organization", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div> */}
                      <div className="ff-column w-100">
                        <label htmlFor="region" className="form-label">
                          Regions
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={regionsDropdown}
                          value={selectedPreference.region}
                          onChange={(selectedOptions) => handleSelectChange("region", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      {/* <div className="ff-column w-100">
                        <label htmlFor="zoo" className="form-label">
                          Zoos
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={zooDropdown}
                          value={selectedPreference.zoo}
                          onChange={(selectedOptions) => handleSelectChange("zoo", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="wetMarket" className="form-label">
                          War on the Environment - Threats
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={wetMarketDropdown}
                          value={selectedPreference.wetMarket}
                          onChange={(selectedOptions) => handleSelectChange("wetMarket", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div>
                      <div className="ff-column w-100">
                        <label htmlFor="environmentalist" className="form-label">
                          Environmentalists
                        </label>
                        <Select
                          className="basic-single text-capitalize"
                          classNamePrefix="select-search"
                          isMulti
                          placeholder="Select"
                          styles={customStyles1}
                          options={environmentalistDropdown}
                          value={selectedPreference.environmentalist}
                          onChange={(selectedOptions) => handleSelectChange("environmentalist", selectedOptions)}
                          isDisabled={allContentChecked}
                        />
                      </div> */}
                    </div>
                  </div>
                  <div className="rf-form-btn d-flex align-items-center justify-content-end w-100">
                    <div className="me-4">
                      <Link to="/">Back to Home</Link>
                    </div>
                    <NextButton
                      customizeMessgae={"Processing..."}
                      classData={`btn btn-default btn-lg `}
                      label={"Submit"}
                      handleSubmit={profile?.profile ? handleSubmit : handleNext}
                      page={"signup"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getSpeciesList: (params, callback) => dispatch(getSpeciesList(params, callback)),
  getZooList: (params, callback) => dispatch(getZooList(params, callback)),
  getOrganizationList: (params, callback) => dispatch(getOrganizationList(params, callback)),
  getEnvironmentalistList: (params, callback) => dispatch(getEnvironmentalistList(params, callback)),
  getWetMarketList: (params, callback) => dispatch(getWetMarketList(params, callback)),
  getScienceAndEducationList: (params, callback) => dispatch(getScienceAndEducationList(params, callback)),
  volunteerPreference: (params, data, callback) => dispatch(volunteerPreference(params, data, callback)),
  getProfileInfo: (params, callback) => dispatch(getProfileInfo(params, callback)),
});

const mapStateToProps = (state) => ({
  sideBarApisListings: sideBarApisListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(VolunteerPreference));
