import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getSpeciesDetails, getMedia, speciesListings } from "../../../store/species";
import { getRegionList, sideBarApisListings } from "../../../store/sidebarApis";
import { getRegionMedia, regionListings } from "../../../store/region";
import { getMediaDetails, organizationListing } from "../../../store/organization";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getWetMarketMedia, wetMarketData } from "../../../store/wetMarket";
import { getProfile } from "../../../utils/localStorageServices";
import UploadModal from "./uploadModal";
import Listcard from "./listcard";
import { getEducationMedia } from "../../../store/misc";
import imageIcon from "../../../include/images/Education Tab/images.svg";
import docIcon from "../../../include/images/Education Tab/doc.svg";
import essayIcon from "../../../include/images/Education Tab/essay.svg";
import videoIcon from "../../../include/images/Education Tab/videos.svg";
import animalArtIcon from "../../../include/images/Education Tab/animalArt.svg";
import Skeleton from "react-loading-skeleton";
import useCheckMobileScreen from "../../../common/customHooks/useCheckMobileScreen";
import RightSideUpload from "./rightSideUpload";
import { getMyFeedData } from "../../../store/users";
import PostUpload from "./postUpload";
import PostuploadModal from "./postuploadModal";
import PostListCard from "../postHome/postListCard";

const MediaEducation = (props) => {
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [activeSection, setActiveSection] = useState("images");
  const [media, setMedia] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [showPostUpload, setShowPostUpload] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const topElementRef = useRef(null);
  const [activeTab, setActiveTab] = useState("mapTab1");
  const isMobile = useCheckMobileScreen();
  const [loadingPost, setloadingPost] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [uploadPost, setUploadPost] = useState(false);
  const profile = getProfile();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const toggleUploadModal = () => {
    setShowUpload(!showUpload);
  };
  const togglePostUploadModal = () => {
    setShowPostUpload(!showPostUpload);
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const handleChange = (filter, tabId) => {
    setLoadingDetail(true);
    setloadingPost(true);
    setSelectedFilter(filter);
    setActiveTab(tabId);
  };
  return (
    <>
      <div className="container" ref={topElementRef}>
        <div className="row">
          <div className="col-md-12">
            <div className="tbc-body-inner">
              {isMobile ? (
                <div class="map-tab-container">
                  <div id="tabBtnContainer" style={{ right: "0px" }}>
                    <div id="tabButton1" className="tabs">
                      <div className="d-flex justify-content-end mt-3">
                        <button onClick={toggleUploadModal} className="btn btn-default h-40">
                          Add Media
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="nav nav-tabs custom-nav-tabs justify-content-start d-lg-flex"
                    id="mapTab"
                    role="tablist"
                  >
                    {isMobile && (
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link mapTab pe-0`}
                          id="mapTab"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane"
                          role="tab"
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-label nav-link-filter flex-grow-1">Filters :</div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab1" ? "active" : ""}`}
                        id="mapTab1"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane1"
                        role="tab"
                        aria-selected={activeTab === "mapTab1"}
                        onClick={() => handleChange("All", "mapTab1")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.8">
                                <path
                                  d="M21.5 4V8H17.5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M2.5 21V17H6.5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M2.18555 12.1016C2.18555 6.57871 6.6627 2.10156 12.1855 2.10156C16.0491 2.10156 19.401 4.29264 21.0663 7.5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M22.3809 13C22.3809 18.5228 17.9037 23 12.3809 23C8.51727 23 5.16543 20.8089 3.50015 17.6016"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">All</div>
                        </div>
                      </div>
                    </div>
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab2" ? "active" : ""}`}
                        id="mapTab2"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane2"
                        role="tab"
                        aria-selected={activeTab === "mapTab2"}
                        onClick={() => handleChange("image", "mapTab2")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M21 15L16 10L5 21"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">Image</div>
                        </div>
                      </div>
                    </div>
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab3" ? "active" : ""}`}
                        id="mapTab3"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane3"
                        role="tab"
                        aria-selected={activeTab === "mapTab3"}
                        onClick={() => handleChange("sketch", "mapTab3")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M10.5117 19.2835L10.5127 19.283C10.8062 18.9886 11.0371 18.7225 11.23 18.4578C11.8056 17.6937 12.1215 16.7921 12.1535 15.9136L13.0103 15.2283C14.5191 14.013 15.9961 12.6848 17.4004 11.2801C18.8052 9.87584 20.1333 8.39884 21.3482 6.89054C22.355 5.64204 22.336 3.92174 21.3033 2.88904C20.2701 1.85534 18.5499 1.83584 17.3028 2.84364C14.2374 5.31144 11.4317 8.11704 8.96197 11.1844L8.27647 12.0412C7.38787 12.0822 6.49307 12.3905 5.73737 12.9603C4.02647 14.2391 2.91657 16.204 2.76907 18.2166L2.51367 21.6785L5.97457 21.4231C7.64357 21.3006 9.25487 20.5403 10.5117 19.2835ZM10.5215 12.4363C12.8999 9.48324 15.6035 6.77964 18.5576 4.40074C19.0005 4.04284 19.5854 4.00034 19.8891 4.30304C20.1923 4.60624 20.1494 5.19124 19.791 5.63504C18.6206 7.08814 17.3403 8.51204 15.9863 9.86604C14.6323 11.22 13.209 12.5003 11.7583 13.6687L11.6362 13.7664C11.6206 13.7408 11.5963 13.7204 11.5801 13.6951C11.4419 13.48 11.288 13.274 11.103 13.0887C10.9164 12.9021 10.709 12.7475 10.4931 12.6086C10.4694 12.5934 10.4503 12.5706 10.4263 12.556L10.5215 12.4363ZM4.67867 19.5135L4.76317 18.3636C4.86957 16.9202 5.68117 15.4989 6.93797 14.5594C7.39747 14.2132 7.92867 14.0233 8.46087 14.0233C8.47117 14.0233 8.48137 14.0238 8.49167 14.0238C8.50247 14.0227 8.51417 14.0242 8.52507 14.0243L8.60097 14.0535C8.75107 14.0909 8.90637 14.1705 9.04817 14.2829C9.19917 14.4021 9.31657 14.5402 9.42377 14.6652L9.68847 14.5023C9.95807 14.7724 10.1184 15.124 10.1582 15.5609L10.1605 15.5865C10.1653 15.6273 10.1689 15.6663 10.1685 15.725C10.1758 16.2426 9.98537 16.786 9.62307 17.267C9.48347 17.4589 9.31647 17.6503 9.09717 17.87C8.17917 18.7875 7.01807 19.3412 5.82767 19.4291L4.67867 19.5135Z"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">Animal Art</div>
                        </div>
                      </div>
                    </div>
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab4" ? "active" : ""}`}
                        id="mapTab4"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane4"
                        role="tab"
                        aria-selected={activeTab === "mapTab4"}
                        onClick={() => handleChange("video", "mapTab4")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M23 7L16 12L23 17V7Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">Video</div>
                        </div>
                      </div>
                    </div>
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab5" ? "active" : ""}`}
                        id="mapTab5"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane5"
                        role="tab"
                        aria-selected={activeTab === "mapTab5"}
                        onClick={() => handleChange("essay", "mapTab5")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 19L19 12L22 15L15 22L12 19Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18 13L16.5 5.5L2 2L5.5 16.5L13 18L18 13Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M2 2L9.586 9.586"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 12.1046 9.89543 13 11 13Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">Essay</div>
                        </div>
                      </div>
                    </div>
                    <div className="nav-item" role="presentation">
                      <div
                        className={`nav-link ${activeTab === "mapTab6" ? "active" : ""}`}
                        id="mapTab6"
                        data-bs-toggle="tab"
                        data-bs-target="#map-tab-pane6"
                        role="tab"
                        aria-selected={activeTab === "mapTab6"}
                        onClick={() => handleChange("document", "mapTab6")}
                      >
                        <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                          <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M9 3V21"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="nav-link-label flex-grow-1">Research Paper</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PostListCard
                    media={media}
                    setMedia={setMedia}
                    section={props.section}
                    id={props.id}
                    loading={loadingDetail}
                    setLoading={setLoadingDetail}
                    scrollToTop={scrollToTop}
                    filter={selectedFilter}
                    loadingPost={loadingPost}
                  />
                </div>
              ) : (
                <div class="map-tab-container education-container d-flex">
                  <div className="education-left education-left-new">
                    <div
                      className="nav nav-tabs custom-nav-tabs custom-nav-tabs-lg justify-content-start d-block education-right-filter mb-3"
                      id="mapTab"
                      role="tablist"
                    >
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link mapTab pe-0`}
                          id="mapTab"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane"
                          role="tab"
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-label nav-link-filter flex-grow-1">Filters</div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab1" ? "active" : ""}`}
                          id="mapTab1"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane1"
                          role="tab"
                          aria-selected={activeTab === "mapTab1"}
                          onClick={() => handleChange("All", "mapTab1")}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g opacity="0.8">
                                  <path
                                    d="M21.5 4V8H17.5"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.5 21V17H6.5"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.18555 12.1016C2.18555 6.57871 6.6627 2.10156 12.1855 2.10156C16.0491 2.10156 19.401 4.29264 21.0663 7.5"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M22.3809 13C22.3809 18.5228 17.9037 23 12.3809 23C8.51727 23 5.16543 20.8089 3.50015 17.6016"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">All</div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab2" ? "active" : ""}`}
                          id="mapTab2"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane2"
                          role="tab"
                          aria-selected={activeTab === "mapTab2"}
                          onClick={() => handleChange("image", "mapTab2")}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 15L16 10L5 21"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">Image</div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab3" ? "active" : ""}`}
                          id="mapTab3"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane3"
                          role="tab"
                          aria-selected={activeTab === "mapTab3"}
                          onClick={() => handleChange("sketch", "mapTab3")}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M10.5117 19.2835L10.5127 19.283C10.8062 18.9886 11.0371 18.7225 11.23 18.4578C11.8056 17.6937 12.1215 16.7921 12.1535 15.9136L13.0103 15.2283C14.5191 14.013 15.9961 12.6848 17.4004 11.2801C18.8052 9.87584 20.1333 8.39884 21.3482 6.89054C22.355 5.64204 22.336 3.92174 21.3033 2.88904C20.2701 1.85534 18.5499 1.83584 17.3028 2.84364C14.2374 5.31144 11.4317 8.11704 8.96197 11.1844L8.27647 12.0412C7.38787 12.0822 6.49307 12.3905 5.73737 12.9603C4.02647 14.2391 2.91657 16.204 2.76907 18.2166L2.51367 21.6785L5.97457 21.4231C7.64357 21.3006 9.25487 20.5403 10.5117 19.2835ZM10.5215 12.4363C12.8999 9.48324 15.6035 6.77964 18.5576 4.40074C19.0005 4.04284 19.5854 4.00034 19.8891 4.30304C20.1923 4.60624 20.1494 5.19124 19.791 5.63504C18.6206 7.08814 17.3403 8.51204 15.9863 9.86604C14.6323 11.22 13.209 12.5003 11.7583 13.6687L11.6362 13.7664C11.6206 13.7408 11.5963 13.7204 11.5801 13.6951C11.4419 13.48 11.288 13.274 11.103 13.0887C10.9164 12.9021 10.709 12.7475 10.4931 12.6086C10.4694 12.5934 10.4503 12.5706 10.4263 12.556L10.5215 12.4363ZM4.67867 19.5135L4.76317 18.3636C4.86957 16.9202 5.68117 15.4989 6.93797 14.5594C7.39747 14.2132 7.92867 14.0233 8.46087 14.0233C8.47117 14.0233 8.48137 14.0238 8.49167 14.0238C8.50247 14.0227 8.51417 14.0242 8.52507 14.0243L8.60097 14.0535C8.75107 14.0909 8.90637 14.1705 9.04817 14.2829C9.19917 14.4021 9.31657 14.5402 9.42377 14.6652L9.68847 14.5023C9.95807 14.7724 10.1184 15.124 10.1582 15.5609L10.1605 15.5865C10.1653 15.6273 10.1689 15.6663 10.1685 15.725C10.1758 16.2426 9.98537 16.786 9.62307 17.267C9.48347 17.4589 9.31647 17.6503 9.09717 17.87C8.17917 18.7875 7.01807 19.3412 5.82767 19.4291L4.67867 19.5135Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">Animal Art</div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab5" ? "active" : ""}`}
                          id="mapTab5"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane5"
                          role="tab"
                          aria-selected={activeTab === "mapTab5"}
                          onClick={() => handleChange("essay", "mapTab5")}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M12 19L19 12L22 15L15 22L12 19Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18 13L16.5 5.5L2 2L5.5 16.5L13 18L18 13Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M2 2L9.586 9.586"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C9.89543 9 9 9.89543 9 11C9 12.1046 9.89543 13 11 13Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">Essay</div>
                          </div>
                        </div>
                      </div>
                      <div className="nav-item" role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab4" ? "active" : ""}`}
                          id="mapTab4"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane4"
                          role="tab"
                          aria-selected={activeTab === "mapTab4"}
                          onClick={() => handleChange("video", "mapTab4")}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M23 7L16 12L23 17V7Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14 5H3C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H14C15.1046 19 16 18.1046 16 17V7C16 5.89543 15.1046 5 14 5Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">Video</div>
                          </div>
                        </div>
                      </div>
                      <div role="presentation">
                        <div
                          className={`nav-link ${activeTab === "mapTab6" ? "active" : ""}`}
                          id="mapTab6"
                          data-bs-toggle="tab"
                          data-bs-target="#map-tab-pane6"
                          role="tab"
                          aria-selected={activeTab === "mapTab6"}
                          onClick={() => handleChange("document", "mapTab6")}
                          style={{ paddingBottom: "10px" }}
                        >
                          <div className="nav-link-box w-100 h-100 d-flex align-items-center">
                            <div className="nav-link-icon flex-shrink-0 d-flex align-items-center justify-content-center">
                              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M17 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9 3V21"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="nav-link-label flex-grow-1">Research Paper</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showMedia ? (
                      <RightSideUpload
                        heading="Upload Media"
                        id={props.id}
                        section={props.section}
                        setShowMedia={setShowMedia}
                      />
                    ) : (
                      <div
                        className="btn btn-default h-40 w-100"
                        style={{ paddingTop: "10px" }}
                        onClick={() => setShowMedia(true)}
                      >
                        Add Media
                      </div>
                    )}
                  </div>
                  <PostListCard
                    media={media}
                    setMedia={setMedia}
                    section={props.section}
                    id={props.id}
                    loading={loadingDetail}
                    setLoading={setLoadingDetail}
                    scrollToTop={scrollToTop}
                    filter={selectedFilter}
                    loadingPost={loadingPost}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <UploadModal
        show={showUpload}
        onHide={toggleUploadModal}
        heading="Upload Files"
        id={props.id}
        section={props.section}
      />
      <PostuploadModal
        show={showPostUpload}
        onHide={togglePostUploadModal}
        heading="Add Post"
        id={props.id}
        section={props.section}
        setloadingPost={setloadingPost}
        setMedia={setMedia}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMedia: (params, callback) => dispatch(getMedia(params, callback)),
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  getRegionList: (params, callback) => dispatch(getRegionList(params, callback)),
  getWetMarketMedia: (params, callback) => dispatch(getWetMarketMedia(params, callback)),
  getRegionMedia: (params, callback) => dispatch(getRegionMedia(params, callback)),
  getMediaDetails: (params, callback) => dispatch(getMediaDetails(params, callback)),
  getEducationMedia: (params, callback) => dispatch(getEducationMedia(params, callback)),
});

const mapStateToProps = (state) => ({
  speciesListings: speciesListings(state),
  regionListings: regionListings(state),
  organizationListing: organizationListing(state),
  sideBarApisListings: sideBarApisListings(state),
  wetMarketData: wetMarketData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MediaEducation));
