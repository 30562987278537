import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "subscription",
    initialState: {
      sponsor: {},
      transaction: {},
      story: {},
      sponsorDetail:{},
      sponsorFeature:{},
      sponsorList:{},
      loading: false,
    },
    reducers: {
      sponsorRequested: (misc, action) => {
        misc.loading = true;
      },
      sponsorReceived: (misc, action) => {
        misc.sponsor = action.payload;
        misc.loading = false;
      },
      sponsorRequestFailed: (misc, action) => {
        misc.loading = false;
      },
      sponsorListRequested: (misc, action) => {
        misc.loading = true;
      },
      sponsorListReceived: (misc, action) => {
        misc.sponsorList = action.payload;
        misc.loading = false;
      },
      sponsorListRequestFailed: (misc, action) => {
        misc.loading = false;
      },
      storyRequested: (misc, action) => {
        misc.loading = true;
      },
      storyReceived: (misc, action) => {
        misc.story = action.payload;
        misc.loading = false;
      },
      storyRequestFailed: (misc, action) => {
        misc.loading = false;
      },
      // For createsponsor
      sponsorCreateRequested: (misc, action) => {
        misc.loading = true;
      },
      sponsorCreateSuccess: (misc, action) => {
        misc.sponsor = action.payload;
        misc.loading = false;
      },
      sponsorCreateFailed: (misc, action) => {
        misc.loading = false;
      },
  
      transactionRequested: (misc, action) => {
        misc.loading = true;
      },
      transactionReceived: (misc, action) => {
        misc.transaction = action.payload;
        misc.loading = false;
      },
      transactionRequestFailed: (misc, action) => {
        misc.loading = false;
      },
      sponsorDetailRequested: (misc, action) => {
        misc.loading = true;
      },
      sponsorDetailReceived: (misc, action) => {
        misc.sponsorDetail = action.payload;
        misc.loading = false;
      },
      sponsorDetailRequestFailed: (misc, action) => {
        misc.loading = false;
      },
      sponsorFeatureRequested: (misc, action) => {
        misc.loading = true;
      },
      sponsorFeatureReceived: (misc, action) => {
        misc.sponsorFeature = action.payload;
        misc.loading = false;
      },
      sponsorFeatureRequestFailed: (misc, action) => {
        misc.loading = false;
      },
    },
  });
  
  export const {
    sponsorRequested,
    sponsorReceived,
    sponsorRequestFailed,
    sponsorListRequested,
    sponsorListReceived,
    sponsorListRequestFailed,
    storyRequested,
    storyReceived,
    storyRequestFailed,
    sponsorCreateRequested,
    sponsorCreateSuccess,
    sponsorCreateFailed,
    transactionRequested,
    transactionReceived,
    transactionRequestFailed,
    sponsorDetailRequested,
    sponsorDetailReceived,
    sponsorDetailRequestFailed,
    sponsorFeatureRequested,
    sponsorFeatureReceived,
    sponsorFeatureRequestFailed,
  } = slice.actions;
  export default slice.reducer;
  
  // Action Creators
  const baseUrl = "subscriptions";
  
  // Getsponsor action
  export const getsponsor = (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsors/${params.id}?page=${params.page}`,
        method: "get",
        callback,
        onStart: sponsorRequested.type,
        onSuccess: sponsorReceived.type,
        onError: sponsorRequestFailed.type,
      })
    );
  };
  export const getsponsorListing = (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsors`,
        method: "get",
        params,
        callback,
        onStart: sponsorListRequested.type,
        onSuccess: sponsorListReceived.type,
        onError: sponsorListRequestFailed.type,
      })
    );
  };
  export const getstory = (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/story/${params.id}?isActive=${params.isActive}&page=${params.page}`,
        method: "get",
        callback,
        onStart: storyRequested.type,
        onSuccess: storyReceived.type,
        onError: storyRequestFailed.type,
      })
    );
  };
  // Createsponsor action
  export const createsponsor = (data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsorship`,
        method: "post",
        data,
        callback,
        onStart: sponsorCreateRequested.type,
        onSuccess: sponsorCreateSuccess.type,
        onError: sponsorCreateFailed.type,
      })
    );
  };
  export const createStory = (params, data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/story/${params}`,
        method: "post",
        data,
        callback,
      })
    );
  };
  // Gettransaction action
  export const updateTransaction = (data, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/transaction/update-transaction-details`,
        method: "post",
        data,
        callback,
      })
    );
  };
  
  export const updateTransactionStory = (params, callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/transaction/update-story-transaction-details`,
        method: "post",
        params,
        callback,
      })
    );
  };
  export const cancelSponsor = (param,data,callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsors/cancel/${param}`,
        method: "post",
        data,
        callback,
      })
    );
  };
  export const cancelStory = (param,data,callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/story/cancel/${param}`,
        method: "post",
        data,
        callback,
      })
    );
  };
  export const getsponsorDetails = (callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsors/sponsoredDetails`,
        method: "get",
        callback,
        onStart: sponsorDetailRequested.type,
        onSuccess: sponsorDetailReceived.type,
        onError: sponsorDetailRequestFailed.type,
      })
    );
  };
  export const getFeaturedSponsors = (callback) => (dispatch) => {
    return dispatch(
      apiCallBegan({
        url: `${baseUrl}/sponsors/featuredSponsors`,
        method: "get",
        callback,
        onStart: sponsorFeatureRequested.type,
        onSuccess: sponsorFeatureReceived.type,
        onError: sponsorFeatureRequestFailed.type,
      })
    );
  };
  // Subscription Selector
  export const getSubscription = createSelector(
    (state) => state.entities.subscription,
    (subscription) => subscription
  );
  