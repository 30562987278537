import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeContentInBrackets, getRelativeTime } from "../../../utils/helperFunctions";
import editIcon from "../../../include/images/heart.svg";
import { getProfile } from "../../../utils/localStorageServices";
import AwesomeSlider from "react-awesome-slider";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import feedIcon from "../../../include/images/feed-Icon.svg";
import heart from "../../../include/images/heart.svg";
import wIcon from "../../../include/images/w-icon-1.svg";
import wIcon2 from "../../../include/images/w-icon-2.svg";
import wIcon3 from "../../../include/images/w-icon-3.svg";
import wIcon4 from "../../../include/images/w-icon-4.svg";
import wIcon5 from "../../../include/images/w-icon-5.svg";
import wImage2 from "../../../include/images/w-image-2.jpg";
import volume from "../../../include/images/w-volume-2.svg";
import liked from "../../../include/images/fill-heart-icon.svg";
import HTMLReactParser from "html-react-parser";

const PostModal = (props) => {
  const { selectedItem, likeAndUnlikePost, toggleShowReport, toggleDmPosts, location } = props;
  const profile = getProfile();
  const history = useHistory();
  const [readMore, setReadMore] = useState(false);

  const getShareUrl = (slug) => {
    const baseUrl =
      process.env.REACT_APP_MODE === "development"
        ? "https://www.dev.environmentalinclusion.com/posts"
        : "https://www.environmentalinclusion.com/posts";
    return `${baseUrl}/${encodeURIComponent(slug)}`;
  };

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const renderDescription = (description) => {
    if (!description) return "";
    const characterLimit = 200;
    if (description.length <= characterLimit) {
      return HTMLReactParser(description);
    }

    const shortDescription = description.substring(0, characterLimit) + "... ";
    return (
      <>
        {HTMLReactParser(readMore ? description : shortDescription)}
        <p onClick={toggleReadMore} className="read-more-detail">
          {readMore ? "Read Less" : "Read More"}
        </p>
      </>
    );
  };
  const sectionsArray = [
    { name: "regions", path: "/regions" },
    { name: "species", path: "/endangered-species" },
    { name: "organizations", path: "/organizations" },
    { name: "wetMarkets", path: "/war-on-the-environment-threats" },
    { name: "zoos", path: "/zoos-and-wildlife-reserves" },
    { name: "scienceAndEducations", path: "/science-education" },
    { name: "environmentalists", path: "/environmentalists" },
  ];
  return (
    <div class="widget-content">
      <div class="widget-text-holder h-100 d-flex flex-column">
        <div class="w-heading-block position-relative">
          <div class="w-heading-box d-flex align-items-center flex-wrap">
            {selectedItem?.createdByAdmin != null ? (
              <div className="w-label-box fw-semibold text-capitalize">Environmental Inclusion</div>
            ) : (
              <div class="w-label-box fw-semibold text-capitalize">
                {selectedItem?.createdBy
                  ? `${selectedItem?.createdBy.firstName} ${selectedItem?.createdBy.lastName}`
                  : selectedItem?.name}
              </div>
            )}
            <span class="w-dot-box"></span>
            <div class="w-time-box d-flex align-items-center">
              <span class="w-time-count">{getRelativeTime(selectedItem?.createdAt)}</span>{" "}
            </div>
          </div>
          {selectedItem?.createdBy?._id == profile?.profile?._id && (
            <div class="w-h-button-box d-flex align-items-center justify-content-center">
              {!location?.pathname.includes("/posts") && (
                <Dropdown className="btn-group heart-icon-box  d-flex align-items-center justify-content-center cursor-pointer">
                  <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle ">
                    <img src={editIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                    <Dropdown.Item
                      className="dropdown-item"
                      eventKey={"1"}
                      onClick={() => {
                        history.push({
                          pathname: "/edit-post",
                          item: selectedItem,
                          postType: "edit",
                        });
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/edit-post",
                          item: selectedItem,
                          postType: "edit",
                        }}
                      >
                        Edit
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="dropdown-item delete"
                      onClick={() => props.deleteHandler(selectedItem)}
                      eventKey={"2"}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          )}
        </div>
        <div class="w-text-box w-text-read-more text-capitalize">
          <p>{renderDescription(selectedItem?.description)}</p>
          {selectedItem?.isAddedFromMedia && selectedItem?.class && (
            <div className="w-text-box w-text-read-more text-capitalize">Grade : {selectedItem?.class ? selectedItem?.class : ""}</div>
          )}
          {selectedItem?.isAddedFromMedia && selectedItem?.school &&  (
            <div className="w-text-box w-text-read-more text-capitalize">School : {selectedItem?.school ? selectedItem?.school : ""}</div>
          )}
          {selectedItem?.isAddedFromMedia && selectedItem?.teacherName && (
            <div className="w-text-box w-text-read-more text-capitalize">Teacher Name : {selectedItem?.teacherName ? selectedItem?.teacherName : ""}</div>
          )}
          {selectedItem && (
            <div className="eap-list d-flex flex-wrap mt-3 mb-2">
              {sectionsArray.map((sectionName) => {
                const sectionItems = selectedItem[sectionName?.name];
                if (Array.isArray(sectionItems) && sectionItems?.length > 0) {
                  return sectionItems.map((sectionItem, idx) => (
                    <div className="eap-item text-capitalize" key={idx}>
                      <Link
                        to={{
                          pathname: `${sectionName.path}/${sectionItem.slug}`,
                          state: { id: sectionItem._id },
                        }}
                      >
                        #{capitalizeContentInBrackets(sectionItem.name)}
                      </Link>
                    </div>
                  ));
                }
                return null;
              })}
            </div>
          )}
        </div>
        <div class="w-iconlist d-flex flex-wrap align-items-center justify-content-between">
          <div class="w-iconlist-item">
            <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
              <div
                class="w-iconl-box"
                onClick={() => {
                  toggleShowReport();
                }}
              >
                <img src={wIcon} alt="w icon 1" />
              </div>
            </div>
          </div>
          <div class="w-iconlist-item">
            <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
              <div class="w-iconl-box">
                <img src={wIcon2} alt="w icon 2" />
              </div>
              <div class="w-textl-box">{selectedItem?.commentCount == 0 ? "" : selectedItem?.commentCount}</div>
            </div>
          </div>
          <div class="w-iconlist-item">
            <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
              <div
                class="w-iconl-box"
                onClick={() => {
                  likeAndUnlikePost(selectedItem?.isLiked ? "unlike" : "like", selectedItem?._id);
                }}
              >
                <img src={selectedItem?.isLiked ? liked : wIcon3} alt="w icon 3" />
              </div>
              <div class="w-textl-box">{selectedItem?.likeCount == 0 ? "" : selectedItem?.likeCount}</div>
            </div>
          </div>
          <div class="w-iconlist-item">
            <div class="w-iconlist-box w-100 h-100 d-flex flex-wrap align-items-center">
              <div class="w-iconl-box">
                <img src={wIcon4} alt="w icon 4" />
              </div>
              <div class="w-textl-box">{selectedItem?.viewCount == 0 ? "" : selectedItem?.viewCount}</div>
            </div>
          </div>
          <div class="w-iconlist-item">
            <Dropdown className="btn-group d-flex align-items-center justify-content-center cursor-pointer">
              <Dropdown.Toggle as="span" id="dropdown-basic" className="w-iconl-box dropdown-toggle">
                <img
                  src={wIcon5}
                  alt="w icon 5"
                  onClick={(e) => {
                    if (!profile?.profile) {
                      history.push("/login");
                    }
                  }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu share-post">
                <FacebookShareButton
                  url={getShareUrl(selectedItem?.slug)}
                  description={selectedItem?.description}
                  className="custom-share-post"
                >
                  <Dropdown.Item className="dropdown-item" eventKey={"1"}>
                    <Link to="#" class="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                      <div class="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.7974 16.1444V10.7915H12.5942L12.8637 8.70543H10.7974V7.37352C10.7974 6.76958 10.9652 6.35797 11.8313 6.35797H12.936V4.49269C12.4013 4.43612 11.8639 4.40872 11.3263 4.41061C9.73351 4.41061 8.64314 5.38274 8.64314 7.16813V8.70543H6.8418V10.7915H8.64314V16.1444H10.7974Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>

                      <div class="dropdown-item-text custom-share-dropdown-text">Facebook</div>
                    </Link>
                  </Dropdown.Item>
                </FacebookShareButton>
                <TwitterShareButton url={getShareUrl(selectedItem?.slug)} className="custom-share-post">
                  <Dropdown.Item className="dropdown-item" eventKey={"2"}>
                    <Link to="#" className="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center">
                      <div className="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.0317 5L8.05085 11.6187L3 17H4.13674L8.55875 12.2886L12.1316 17H16L10.6984 10.009L15.3997 5H14.263L10.1905 9.33911L6.90008 5H3.0317ZM4.70336 5.8258H6.48051L14.3281 16.1741H12.5509L4.70336 5.8258Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>

                      <div className="dropdown-item-text custom-share-dropdown-text">Twitter</div>
                    </Link>
                  </Dropdown.Item>
                </TwitterShareButton>
                <Dropdown.Item className="dropdown-item" eventKey={"3"}>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDmPosts();
                    }}
                    class="dropdown-item-link w-100 h-100 d-flex flex-wrap align-items-center"
                  >
                    <div class="dropdown-item-icon d-flex flex-wrap align-items-center justify-content-center">
                      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14 7.83325C15.1046 7.83325 16 6.93782 16 5.83325C16 4.72868 15.1046 3.83325 14 3.83325C12.8954 3.83325 12 4.72868 12 5.83325C12 6.93782 12.8954 7.83325 14 7.83325Z"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6 12.5C7.10457 12.5 8 11.6046 8 10.5C8 9.39543 7.10457 8.5 6 8.5C4.89543 8.5 4 9.39543 4 10.5C4 11.6046 4.89543 12.5 6 12.5Z"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14 17.1666C15.1046 17.1666 16 16.2712 16 15.1666C16 14.0621 15.1046 13.1666 14 13.1666C12.8954 13.1666 12 14.0621 12 15.1666C12 16.2712 12.8954 17.1666 14 17.1666Z"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.72656 11.5066L12.2799 14.1599"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.2732 6.83997L7.72656 9.4933"
                          stroke="currentColor"
                          stroke-width="1.2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div class="dropdown-item-text">Direct</div>
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
