import React from "react";
import { Modal } from "react-bootstrap";
import Editor from "../../common/editor/editor";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const CorporateSponsor = (props) => {
  return (
    <Modal
      className=" fade custom-modal-popup "
      show={props.show}
      onHide={() => props.onHide(null)}
      enforceFocus={true}
      dialogClassName="modal-400w"
      backdropClassName={"delete-backdrop"}
      //   backdrop={uploadImageModal ? false : true}
      centered
    >
      <Modal.Body>
        <div class="modal-alert-box">
          <h4>Alert! </h4>
          <p>To have your organization featured on the Sponsor page for the species you’ve sponsored and on the sustainability page, please update your profile with your Organisation Name and Logo.</p>
        </div>
        <div class="modal-action-btn">
          <Link
            to={props.page =="profile" ?"/edit-profile":"/profile"}
            class={`btn btn-default h-40 pt-10`}
          >
            {props.page =="profile" ?"Edit Profile":"Go to Profile Page"}
          </Link>
          <a
            href="javascript:void(0);"
            style={{ background: "#fff" }}
            class="btn btn-cancel h-40 pt-10"
            onClick={() => props.onHide()}
          >
            Cancel
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CorporateSponsor;
