import React, { useRef, useState } from "react";
import { deleteOrganizations, getOrganizations, speciesListings, updateOrganizations } from "../../store/species";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import noSponsor from "../../include/images/sponsor.svg";
import noSponsorSmall from "../../include/images/sponsorsmall.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ShowMoreModal from "../common/showMoreModal/showMoreModal";
import Pagination from "../common/Pagination/pagination";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  extractIds,
  transformDropdownData,
} from "../../utils/helperFunctions";
import Contribute from "./contribute";
import salvexLogo from "../../include/images/Salvex Logo.svg";
import Select from "react-select";
import { getOrganizationList, sideBarApisListings } from "../../store/sidebarApis";
import organization from "../../store/organization";
import trash from "../../include/images/trash-24x24.svg";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import AddModal from "../common/addModal/addModal";
import useCheckMobileScreen from "../../common/customHooks/useCheckMobileScreen";
import { getsponsor, getstory, getSubscription } from "../../store/subscription";
import { getProfile } from "../../utils/localStorageServices";
import avaitionIcon from "../../include/images/avaition.svg";

const Sponsors = (props) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const topElementRef = useRef(null);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const isMobile = useCheckMobileScreen();
  const sponsorData = props?.getSubscription?.sponsor?.data;
  const storyData = props?.getSubscription?.story?.data;
  const [selectedData, setSelectedData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const profile = getProfile();
  const staticSpecies = ["66faa2afb126301e0344a422", "647fb22dd6091d1a635a36d3", "66fa9666b126301e03441d26"];
  const handleImageError = () => {
    setImageError(true);
  };

  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };

  useEffect(() => {
    if (!initialRender) {
      const params = {
        id: props.id,
        page: currentPage,
        isActive: true,
      };
      setLoading(true);
      props.getsponsor(params, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
        }
      });
    } else {
      setInitialRender(false);
    }
  }, [props.id, currentPage]);

  const organizationDetail = props.speciesListings && props.speciesListings.organization;

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const scrollToTop = () => {
    const topElement = topElementRef.current;
    if (topElement) {
      const topPosition = topElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition - 200,
        behavior: "smooth",
      });
    }
  };

  const pageLinks = Array.from(
    { length: Math.ceil((organizationDetail?.totalCount ?? 0) / (organizationDetail?.recordsPerPage ?? 1)) },
    (_, i) => i + 1,
  );

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      description = description.substring(0, limit) + dots;
      if (description.includes("h1")) {
        description = description.substring(0, limit);
      }
    }
    return description;
  };

  return (
    <div className="container" ref={topElementRef}>
      <div className="row">
        <div className="col-md-12">
          <div className={`${!sponsorData?.length && storyData?.length ? "" : "tbc-body-inner"} pb-0`}>
            {sponsorData && sponsorData.length > 0 && (
              <div className="ei-heading d-flex justify-content-between align-items-center mb-3 mt-0">
                <h4>Sponsors</h4>
              </div>
            )}
            {sponsorData.length == 0 && staticSpecies?.includes(props.id) && (
              <div className="ei-heading d-flex justify-content-between align-items-center mb-3 mt-0">
                <h4>Sponsors</h4>
              </div>
            )}
            {loading ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                {Array.from({ length: 42 }, (item, index) => (
                  <div key={index} className="org-skeleton">
                    <Skeleton borderRadius="10px" height="150px" />
                  </div>
                ))}
              </ul>
            ) : sponsorData && sponsorData.length > 0 ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                {staticSpecies?.includes(props.id) && (
                  <li className="organisation-item org-card position-relative">
                    <div className="organisation-box sponsor-box d-flex flex-wrap">
                      <div className="organisation-logo">
                        <img src={avaitionIcon} className="org-img sm-img-fix" style={{ padding: "5px" }} alt="" />
                      </div>
                      <div className="organisation-text">
                        <h6 className="text-transform text-capitalize mb-0">
                          <Link>Aviation AI</Link>
                        </h6>
                        <div className="organisation-text-footer mt-0">
                          <div className="d-flex space-between">
                            <small>Species :</small>
                          </div>
                          <p className="caps-text d-flex align-items-center space-between mb-2">
                            <span>Black-footed ferret, Pacific Ridley, Aberdare cisticola</span>
                          </p>
                          {/* <p className="caps-text d-flex align-items-center justify-content-between">
                            <span>{item?.description}</span>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                {sponsorData.map((item, index) => (
                  <li key={index} className="organisation-item org-card position-relative">
                    <div className="organisation-box sponsor-box d-flex flex-wrap">
                      <div className="organisation-logo">
                        <Link>
                          <img
                            src={`${process.env.REACT_APP_MEDIA}${
                              item?.organizationLogo?.small
                                ? item?.organizationLogo?.small
                                : item?.profilePicture?.small
                            }`}
                            onError={handleImageError}
                            className="org-img sm-img-fix"
                            alt="Sponsors Logo"
                          />
                        </Link>
                      </div>
                      <div className="organisation-text">
                        <h6 className="text-transform text-capitalize mb-0">
                          <Link>
                            {item?.sponsorName
                              ? capitalizeContentInBrackets(item?.sponsorName)
                              : item?.firstName + " " + item?.lastName}
                          </Link>
                        </h6>

                        <div className="organisation-text-footer mt-0">
                          {item?.linkedSpecies && (
                            <>
                              <div className="d-flex space-between">
                                <small>Species :</small>
                                {/* {item?.linkedSpecies?.length > 0 && (
                                  <span
                                    class=" map-switch view-all-btn1 fs-11 org-tab-mob link-text hover-underline"
                                    style={{
                                      marginTop: "3px",
                                      marginLeft: "10px",
                                    }}
                                    onClick={() => {
                                      if (item?.linkedSpecies?.length > 0) {
                                        setSelectedData(item?.linkedSpecies);
                                        setSelectedName(item?.sponsorName);
                                        toggleShowMoreModal();
                                      }
                                    }}
                                  >
                                    View All
                                  </span>
                                )} */}
                              </div>

                              <p className="caps-text d-flex align-items-center space-between mb-2">
                                <span>
                                  {item?.linkedSpecies && item?.linkedSpecies.length > 0
                                    ? item?.linkedSpecies.filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                        .length > 0 //
                                      ? item?.linkedSpecies
                                          .filter((ie) => ie?.displayCommonName || ie?.scientificName)
                                          .map((ie, r, filteredSpecies) =>
                                            r < 2 ? (
                                              <span key={r}>
                                                {ie?.displayCommonName || ie?.scientificName}
                                                {r + 1 < filteredSpecies.length && r < 1 ? ", " : ""}
                                                {r === 1 && filteredSpecies.length > 2 ? " ..." : ""}
                                              </span>
                                            ) : null,
                                          )
                                      : "No scientific names available"
                                    : "Not yet added"}
                                </span>
                              </p>
                            </>
                          )}
                          <p className="caps-text d-flex align-items-center justify-content-between">
                            <span>{dotDesc(item?.description,40)}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : sponsorData.length == 0 && staticSpecies?.includes(props.id) ? (
              <ul className="tbc-organization-list d-flex flex-wrap">
                <li className="organisation-item org-card position-relative">
                  <div className="organisation-box sponsor-box d-flex flex-wrap">
                    <div className="organisation-logo">
                      <img src={avaitionIcon} className="org-img sm-img-fix" style={{ padding: "5px" }} alt="" />
                    </div>
                    <div className="organisation-text">
                      <h6 className="text-transform text-capitalize mb-0">
                        <Link>Aviation AI</Link>
                      </h6>
                      <div className="organisation-text-footer mt-0">
                        <div className="d-flex space-between">
                          <small>Species :</small>
                        </div>
                        <p className="caps-text d-flex align-items-center space-between mb-2">
                          <span>Black-footed ferret, Pacific Ridley, Aberdare cisticola</span>
                        </p>
                        {/* <p className="caps-text d-flex align-items-center justify-content-between">
                            <span>{item?.description}</span>
                          </p> */}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            ) : !sponsorData?.length && !storyData?.length ? (
              isMobile ? (
                <div className="d-flex justify-content-center">
                  <Link
                    className="btn btn-default position-absolute bottom-0 h-40 pt-10"
                    to={profile?.profile ? { pathname: "/addsponsor", state: { id: props.id } } : "/login"}
                  >
                    Sponsor
                  </Link>
                  <img src={noSponsorSmall} alt="No Records" />
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <Link
                    className="btn btn-default h-40 pt-10 w-25 me-5"
                    to={profile?.profile ? { pathname: "/addsponsor", state: { id: props.id } } : "/login"}
                  >
                    Sponsor
                  </Link>
                  <div className="mx-auto w-75 ms-3">
                    <img src={noSponsor} alt="No Records" />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
        <div className="col-md-12">
          <div className="tbc-body-inner">
            <div className="tbc-contributor">
              {storyData && storyData.length > 0 && (
                <div className="ei-heading d-flex justify-content-between align-items-center">
                  <h4>Stories Funded By</h4>
                </div>
              )}
              {loading ? (
                <ul className="doner-list d-flex flex-wrap">
                  {[1, 2, 3, 4].map((item) => (
                    <li className="doner-item" key={item}>
                      <Skeleton borderRadius="10px" height="150px" />
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="tbc-organization-list d-flex flex-wrap mt-3">
                  {storyData &&
                    storyData.length > 0 &&
                    storyData.map((item) => (
                      <li key={item._id} className="organisation-item org-card position-relative">
                        <div className="organisation-box sponsor-box d-flex flex-wrap">
                          <div className="organisation-logo">
                            <Link>
                              <img
                                src={`${process.env.REACT_APP_MEDIA}${item?.logo?.small}`}
                                onError={handleImageError}
                                className="org-img sm-img-fix"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="organisation-text">
                            <h6 className="text-transform text-capitalize mb-0">
                              <Link>{capitalizeContentInBrackets(item?.name)}</Link>
                            </h6>
                            <div className="organisation-text-footer mt-0">
                              <div className="d-flex justify-content-between">
                                <small>Description :</small>
                              </div>
                              <p className="caps-text d-flex align-items-center justify-content-between">
                                <span>{dotDesc(item?.description,40)}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <ShowMoreModal
        show={showMoreModal}
        onHide={toggleShowMoreModal}
        listData={selectedData}
        heading={`Sponsored Species  by ${selectedName ? capitalizeContentInBrackets(selectedName) : ""}`}
        isNonEditable={true}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getsponsor: (params, callback) => dispatch(getsponsor(params, callback)),
  getstory: (params, callback) => dispatch(getstory(params, callback)),
});

const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Sponsors));
