import React, { useState } from "react";
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { useEffect } from "react";
import ListingCard from "../../components/common/listingCard/listingCard";
import leftArrow from "../../include/images/pagination-left-arrow.svg";
import rightArrow from "../../include/images/pagination-right-arrow.svg";
import leftArrowGreen from "../../include/images/pagination-left-arrow-green.svg";
import rightArrowGreen from "../../include/images/pagination-right-arrow-green.svg";
import closeIcon from "../../include/images/close.svg";
import { Link } from "react-router-dom";
import {
    sideBarApisListings,
    getSpeciesCategoryList,
    getSpeciesListLivefeeds,
    getRegionListLivefeeds,
    getOrganizationListLivefeeds,
    getZooListLivefeeds,
    getWetMarketListLivefeeds,
    getEnvironmentalistListLivefeeds,
} from "../../store/sidebarApis";
import noRecord from "../../include/images/nrb-img.svg";
import SpeciesSideBarFilter from "../../components/common/sideBarFilter/speciesSideBarFilter";
import ShowMoreRegionsModal from "../../components/common/showMoreModal/showMoreRegionsModal";
import SpeciesListCard from "../../components/common/listingCard/speciesListCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import ShowMoreModal from "../../components/common/showMoreModal/showMoreModal";
import { getLiveFeeds, getMisc } from "../../store/misc";
import { speciesListings } from "../../store/species";
import LiveFeedsSideBarFilter from "../../components/common/sideBarFilter/liveFeedsSidebar";
import OrganizationListCard from "../../components/common/listingCard/organizationListCard";
import LiveFeedsListCard from "../../components/common/listingCard/liveFeedsListCard";
import Useauthredirect from "../../common/customHooks/useAuthRedirect";
import ListingPagination from "../../components/common/Pagination/listingPagination";
const LiveFeedsListing = (props) => {
    const location = useLocation();
    const _id = location && location.id;
    const name = location && location.name;
    const type = location && location.type;
    const [showMenu, setShowMenu] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState("asc");
    const [sort, setSort] = useState("name");
    const [loadingMain, setLoadingMain] = useState(true);

    const [selectSpecies, setSelectedSpecies] = useState(
        _id && type == "species" ? [_id] : []
    );
    const [selectedSpeciesName, setSelectedSpeciesName] = useState(
        _id && type == "species" && name ? [{ _id, name }] : ""
    );
    const [selectZoo, setSelectedZoo] = useState([]);
    const [selectedZooName, setSelectedZooName] = useState([]);
    const [selectWetMarket, setSelectedWetMarket] = useState([]);
    const [selectedWetMarketName, setSelectedWetMarketName] = useState([]);
    const [selectEnvironmentalist, setSelectedEnvironmentalist] = useState([]);
    const [selectedEnvironmentalistName, setSelectedEnvironmentalistName] =
        useState([]);
    const [selectOrganization, setSelectedOrganization] = useState([]);
    const [selectedOrganizationName, setSelectedOrganizationName] = useState([]);
    const [selectRegion, setSelectedRegion] = useState(
        _id && type == "region" ? [_id] : []
    );
    const [selectRegionName, setSelectedRegionName] = useState(
        _id && type == "region" && name ? [{ _id, name }] : ""
    );
    const [selectSpeciesCategory, setSelectedSpeciesCategory] = useState(
        _id && !type ? [_id] : []
    );
    const [selectSpeciesCategoryName, setSelectedSpeciesCategoryName] = useState(
        _id && !type && name ? [{ _id, name }] : ""
    );

    const [USRegion, setUSRegion] = useState([]);
    const [otherRegion, setOtherRegion] = useState([]);
    //Modal

    const [showSpeciesViewAll, setShowSpeciesViewAll] = useState(false);
    const [showRegionsViewAll, setShowRegionsViewAll] = useState(false);
    const [showSpeciesCategoryViewAll, setShowSpeciesCategoryViewAll] =
        useState(false);
    const [showOrganizationsViewAll, setShowOrganizationsViewAll] =
        useState(false);
    const [showZooViewAll, setShowZooViewAll] = useState(false);
    const [showWetMarketViewAll, setShowWetMarketViewAll] = useState(false);
    const [showEnvironmentalistViewAll, setShowEnvironmentalistViewAll] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingOrg, setLoadingOrg] = useState(false);
    const [loadingZoo, setLoadingZoo] = useState(false);
    const [loadingWetMarket, setLoadingWetMarket] = useState(false);
    const [loadingEnv, setLoadingEnv] = useState(false);
    const [loadingSpecies, setLoadingSpecies] = useState(false);
    Useauthredirect();
    const toggleOrganizationsViewAll = () => {
        setShowOrganizationsViewAll(!showOrganizationsViewAll);
    };

    const toggleSpeciesViewAll = () => {
        setShowSpeciesViewAll(!showSpeciesViewAll);
    };

    const toggleRegionsViewAll = () => {
        setShowRegionsViewAll(!showRegionsViewAll);
    };

    const toggleSpeciesCategoryViewAll = () => {
        setShowSpeciesCategoryViewAll(!showSpeciesCategoryViewAll);
    };

    const toggleZooViewAll = () => {
        setShowZooViewAll(!showZooViewAll);
    };
    const toggleWetMarketViewAll = () => {
        setShowWetMarketViewAll(!showWetMarketViewAll);
    };

    const toggleEnvironmentalistViewAll = () => {
        setShowEnvironmentalistViewAll(!showEnvironmentalistViewAll);
    };

    const speciesName =
        props.sideBarApisListings && props.sideBarApisListings.livefeedspecies;
    const organization =
        props.sideBarApisListings && props.sideBarApisListings.livefeedsOrganization;
    const zooAndParks =
        props.sideBarApisListings && props.sideBarApisListings.livefeedsZoo;
    const wetMarketData =
        props.sideBarApisListings && props.sideBarApisListings.livefeedsWetmarket;
    const environmentalist =
        props.sideBarApisListings && props.sideBarApisListings.livefeedsEnv;
    const regions = props.sideBarApisListings && props.sideBarApisListings.livefeedsRegions;

    useEffect(() => {
        setLoadingMain(true);
        window.scrollTo(0, 0);
        const species = selectSpecies.map((item) => item).join(",");
        const organizations = selectOrganization.map((item) => item).join(",");
        const zoos = selectZoo.map((item) => item).join(",");
        const wetMarkets = selectWetMarket.map((item) => item).join(",");
        const environmentalists = selectEnvironmentalist
            .map((item) => item)
            .join(",");
        const regions = selectRegion.map((item) => item).join(",");
        const categories = selectSpeciesCategory.map((item) => item).join(",");
        const payload = {
            keyword,
            page: page,
            sort,
            order,
        };

        if (species) {
            payload.species = species;
        }
        if (organizations) {
            payload.organizations = organizations;
        }
        if (regions) {
            payload.regions = regions;
        }
        if (categories) {
            payload.categories = categories;
        }
        if (zoos) {
            payload.zoos = zoos;
        }
        if (wetMarkets) {
            payload.wetMarkets = wetMarkets;
        }
        if (environmentalists) {
            payload.environmentalists = environmentalists;
        }
        props.getLiveFeeds(payload, (res) => {
            if (res && res.status === 200) {
                setLoadingMain(false);
            }
        });
    }, [page, sort, order, keyword, selectRegion,
        selectSpeciesCategory,
        selectOrganization,
        selectEnvironmentalist,
        selectWetMarket,
        selectZoo,
        selectSpecies]);


    useEffect(() => {
        let payload = { liveFeed: true }
        if (organization && !organization.data) {
            setLoadingOrg(true);
            props.getOrganizationList(payload, (res) => {
                if (res && res.status === 200) {
                    setLoadingOrg(false);
                }
            });
        }

        if (zooAndParks && !zooAndParks.data) {
            setLoadingZoo(true);
            props.getZooList(payload, (res) => {
                if (res && res.status === 200) {
                    setLoadingZoo(false);
                }
            });
        }

        if (wetMarketData && !wetMarketData.data) {
            setLoadingWetMarket(true);
            props.getWetMarketList(payload, (res) => {
                if (res && res.status === 200) {
                    setLoadingWetMarket(false);
                }
            });
        }

        if (environmentalist && !environmentalist.data) {
            setLoadingEnv(true);
            props.getEnvironmentalistList(payload, (res) => {
                if (res && res.status === 200) {
                    setLoadingEnv(false);
                }
            });
        }

        if (speciesName && !speciesName.data) {
            setLoadingSpecies(true);
            props.getSpeciesList(payload, (res) => {
                if (res && res.status === 200) {
                    setLoadingSpecies(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (
            loadingOrg ||
            loadingSpecies ||
            loadingWetMarket ||
            loadingZoo ||
            loadingEnv
        ) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [loadingOrg, loadingSpecies, loadingWetMarket, loadingZoo, loadingEnv]);

    useEffect(() => {
        if (regions && regions.data) {
            const usRegions =
                regions.data &&
                regions.data.length > 0 &&
                regions.data.filter((item) => item.country === "US");
            const otherRegions =
                regions.data &&
                regions.data.length > 0 &&
                regions.data.filter((item) => item.country !== "US");
            setUSRegion(usRegions);
            setOtherRegion(otherRegions);
        } else {
            props.getRegionList({ liveFeed: true }, (res) => {
                if (res && res.status === 200) {
                    const usRegions =
                        res.data.data &&
                        res.data.data.length > 0 &&
                        res.data.data.filter((item) => item.country === "US");
                    const otherRegions =
                        res.data.data &&
                        res.data.data.length > 0 &&
                        res.data.data.filter((item) => item.country !== "US");
                    setUSRegion(usRegions);
                    setOtherRegion(otherRegions);
                }
            })
        }
    }, [regions]);

    const liveFeedsList =
        props.getMisc.liveFeeds && props.getMisc.liveFeeds.data;

    const totalPages =
        (props.getMisc.liveFeeds && props.getMisc.liveFeeds.totalCount) || 0;

    const dataPerPage =
        (props.getMisc.liveFeeds && props.getMisc.liveFeeds.recordsPerPage) ||
        0;

    const noOfPage = Math.ceil(totalPages / dataPerPage) || 0;

    useEffect(() => {
        if (showMenu) {
            document.body.classList.add("filter-open");
        } else {
            document.body.classList.remove("filter-open");
        }
    }, [showMenu]);
    return (
        <>
            {/* {loadingMain && <Loader />} */}
            <PageLayout >
                <main id="main">
                    <div class="listing-container">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="listing-container-inner d-flex flex-wrap">
                                        <div class="filter-sidebar">
                                            <div class="filter-header-mobile d-block d-lg-none">
                                                <h6>Filters</h6>
                                                <div class="close-btn">
                                                    <img
                                                        src={closeIcon}
                                                        alt=""
                                                        onClick={() => setShowMenu(false)}
                                                    />
                                                </div>
                                            </div>

                                            <LiveFeedsSideBarFilter
                                                showMenu={showMenu}
                                                setShowMenu={setShowMenu}
                                                USRegion={USRegion}
                                                otherRegion={otherRegion}
                                                selectRegion={selectRegion}
                                                setSelectedRegion={setSelectedRegion}
                                                setSelectedRegionName={setSelectedRegionName}
                                                selectRegionName={selectRegionName}
                                                selectSpeciesCategory={selectSpeciesCategory}
                                                setSelectedSpeciesCategory={setSelectedSpeciesCategory}
                                                selectSpeciesCategoryName={selectSpeciesCategoryName}
                                                setSelectedSpeciesCategoryName={
                                                    setSelectedSpeciesCategoryName
                                                }
                                                toggleRegionsViewAll={toggleRegionsViewAll}
                                                toggleSpeciesCategoryViewAll={
                                                    toggleSpeciesCategoryViewAll
                                                }
                                                organization={organization}
                                                setSelectedOrganization={setSelectedOrganization}
                                                selectOrganization={selectOrganization}
                                                selectedOrganizationName={selectedOrganizationName}
                                                setSelectedOrganizationName={
                                                    setSelectedOrganizationName
                                                }
                                                toggleOrganizationsViewAll={toggleOrganizationsViewAll}
                                                zooAndParks={zooAndParks}
                                                selectZoo={selectZoo}
                                                setSelectedZoo={setSelectedZoo}
                                                selectedZooName={selectedZooName}
                                                setSelectedZooName={setSelectedZooName}
                                                toggleZooViewAll={toggleZooViewAll}
                                                wetMarketData={wetMarketData}
                                                selectWetMarket={selectWetMarket}
                                                setSelectedWetMarket={setSelectedWetMarket}
                                                selectedWetMarketName={selectedWetMarketName}
                                                setSelectedWetMarketName={setSelectedWetMarketName}
                                                toggleWetMarketViewAll={toggleWetMarketViewAll}
                                                environmentalist={environmentalist}
                                                selectEnvironmentalist={selectEnvironmentalist}
                                                setSelectedEnvironmentalist={
                                                    setSelectedEnvironmentalist
                                                }
                                                selectedEnvironmentalistName={
                                                    selectedEnvironmentalistName
                                                }
                                                setSelectedEnvironmentalistName={
                                                    setSelectedEnvironmentalistName
                                                }
                                                toggleEnvironmentalistViewAll={
                                                    toggleEnvironmentalistViewAll
                                                }
                                                speciesName={speciesName}
                                                selectSpecies={selectSpecies}
                                                selectedSpeciesName={selectedSpeciesName}
                                                setSelectedSpecies={setSelectedSpecies}
                                                setSelectedSpeciesName={setSelectedSpeciesName}
                                                toggleSpeciesViewAll={toggleSpeciesViewAll}
                                                section={"liveFeeds"}
                                                setPage={setPage}
                                            />
                                            <div class="filter-footer-mob-btn d-flex d-lg-none">
                                                <Link
                                                    class="btn btn-grey"
                                                    onClick={() => {
                                                        if (
                                                            selectedSpeciesName &&
                                                            selectedSpeciesName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedSpecies(data);
                                                            setSelectedSpeciesName(data);
                                                        }
                                                        if (
                                                            selectRegionName &&
                                                            selectRegionName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedRegionName(data);
                                                            setSelectedRegion(data);
                                                        }
                                                        if (
                                                            selectSpeciesCategoryName &&
                                                            selectSpeciesCategoryName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedSpeciesCategoryName(data);
                                                            setSelectedSpeciesCategory(data);
                                                        }
                                                        if (
                                                            selectedOrganizationName &&
                                                            selectedOrganizationName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedOrganizationName(data);
                                                            setSelectedOrganization(data);
                                                        }

                                                        if (selectedZooName && selectedZooName.length > 0) {
                                                            const data = [];
                                                            setSelectedZooName(data);
                                                            setSelectedZoo(data);
                                                        }

                                                        if (
                                                            selectedWetMarketName &&
                                                            selectedWetMarketName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedWetMarketName(data);
                                                            setSelectedWetMarket(data);
                                                        }

                                                        if (
                                                            selectedEnvironmentalistName &&
                                                            selectedEnvironmentalistName.length > 0
                                                        ) {
                                                            const data = [];
                                                            setSelectedEnvironmentalistName(data);
                                                            setSelectedEnvironmentalist(data);
                                                        }
                                                    }}
                                                >
                                                    Clear All Filters
                                                </Link>
                                                <Link
                                                    class="btn btn-dark-green"
                                                    onClick={() => setShowMenu(!showMenu)}
                                                >
                                                    Apply
                                                </Link>
                                            </div>
                                        </div>
                                        <div class="listing-content-box">
                                            <div class="breadcrumb-row d-flex flex-wrap">
                                                <div class="breadcrumb-box">
                                                    <Link to="/">Home</Link>
                                                </div>
                                                <div class="breadcrumb-box">
                                                    <Link to="/endangered-species">
                                                        Live Feeds
                                                    </Link>
                                                </div>
                                            </div>

                                            <div class="ei-heading listing-content-heading-mob">
                                                <h4>Live Feeds ({totalPages || 0})</h4>
                                                <div class="ei-sort-filter d-lg-none d-flex">
                                                    <a href="javascript:void(0);">Sort By</a>

                                                    <ul
                                                        class="sorting-list d-flex flex-wrap"
                                                        style={{ marginLeft: "15px" }}
                                                    >
                                                        <li class="sorting-item ">
                                                            <div
                                                                class="sorting-box active cursor-pointer"
                                                                onClick={() => {
                                                                    if (order === "asc") {
                                                                        setOrder("desc");
                                                                    } else {
                                                                        setOrder("asc");
                                                                    }
                                                                }}
                                                            >
                                                                A to Z
                                                                <svg
                                                                    className={order === "desc" && "down-arrow "}
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            d="M7 11.0827V2.91602"
                                                                            stroke="black"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.9165 6.99935L6.99984 2.91602L11.0832 6.99935"
                                                                            stroke="black"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div
                                                className="d-lg-flex flex-wrap d-none space-between border-bottom-1"
                                                style={{ marginBottom: "10px", paddingBottom: "10px" }}
                                            >
                                                <div class="sorting-row d-lg-flex flex-wrap d-none border-0 sort-region-listing ">
                                                    <p>Sort By :</p>
                                                    <ul class="sorting-list d-flex flex-wrap">
                                                        <li class="sorting-item ">
                                                            <div
                                                                class="sorting-box active cursor-pointer"
                                                                onClick={() => {
                                                                    if (order === "asc") {
                                                                        setOrder("desc");
                                                                    } else {
                                                                        setOrder("asc");
                                                                    }
                                                                }}
                                                            >
                                                                A to Z
                                                                <svg
                                                                    className={order === "desc" && "down-arrow "}
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g>
                                                                        <path
                                                                            d="M7 11.0827V2.91602"
                                                                            stroke="black"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.9165 6.99935L6.99984 2.91602L11.0832 6.99935"
                                                                            stroke="black"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="ei-grid-list d-flex flex-wrap">
                                                {loadingMain
                                                    ? [1, 2, 3, 4, 5, 6, 7, 8, 8, 8].map((item) => (
                                                        <LiveFeedsListCard
                                                            img={item?.coverImage?.small}
                                                            name={item.name}
                                                            page={"liveFeeds-listing-page"}
                                                            id={item._id}
                                                            loading={loadingMain}
                                                            slug={item.url}
                                                        />
                                                    ))
                                                    : liveFeedsList &&
                                                    liveFeedsList.length > 0 &&
                                                    liveFeedsList.map((item) => (
                                                        <LiveFeedsListCard
                                                            img={item?.coverImage?.small}
                                                            previewImg={item?.camPreview?.small}
                                                            name={item.title}
                                                            page={"liveFeeds-listing-page"}
                                                            id={item._id}
                                                            slug={item.url}
                                                            address={item.address}
                                                            source={item.source}
                                                            info={item.info}
                                                        />
                                                    ))}
                                            </ul>
                                            <div class="pagination-row d-flex justify-content-between">
                                                {liveFeedsList && liveFeedsList.length === 0 ? (
                                                    <ul className="blog-list d-flex flex-wrap justify-content-center">
                                                        <div className="no-records-container d-flex align-items-center justify-content-center">
                                                            <div className="no-records-box text-center">
                                                                <div className="nrb-image">
                                                                    <img src={noRecord} alt="" />
                                                                </div>
                                                                <h6>No Records Found</h6>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                ) : (
                                                    <>
                                                        {liveFeedsList && noOfPage > 1 &&
                                                            <ListingPagination
                                                                noOfPage={noOfPage}
                                                                totalPages={totalPages}
                                                                page={page}
                                                                setPage={setPage}
                                                            />
                                                        }
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </PageLayout>
            {!loadingMain && <div class="filter-sort-btn d-block d-lg-none">
                <Link
                    class="btn btn-block btn-dark-green"
                    onClick={() => setShowMenu(!showMenu)}
                >
                    Filters
                </Link>
            </div>}
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getLiveFeeds: (params, callback) => dispatch(getLiveFeeds(params, callback)),
    getSpeciesList: (params, callback) =>
        dispatch(getSpeciesListLivefeeds(params, callback)),
    getSpeciesCategoryList: (params, callback) =>
        dispatch(getSpeciesCategoryList(params, callback)),
    getRegionList: (params, callback) =>
        dispatch(getRegionListLivefeeds(params, callback)),
    getOrganizationList: (params, callback) =>
        dispatch(getOrganizationListLivefeeds(params, callback)),
    getZooList: (params, callback) => dispatch(getZooListLivefeeds(params, callback)),
    getWetMarketList: (params, callback) =>
        dispatch(getWetMarketListLivefeeds(params, callback)),
    getEnvironmentalistList: (params, callback) =>
        dispatch(getEnvironmentalistListLivefeeds(params, callback)),
});

const mapStateToProps = (state) => ({
    speciesLists: speciesListings(state),
    sideBarApisListings: sideBarApisListings(state),
    getMisc: getMisc(state)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(LiveFeedsListing));
