import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import HTMLReactParser from "html-react-parser";
import { capitalizeContentInBrackets } from "../../../utils/helperFunctions";
import defaultOrgImg from "../../../include/images/organisation.png";
import ShowMoreModal from "../showMoreModal/showMoreModal";
const SponsorsListCard = (props) => {
  const [imageError, setImageError] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const handleImageError = () => {
    setImageError(true);
  };
  const toggleShowMoreModal = () => {
    setShowMoreModal(!showMoreModal);
  };
  const donationUrl = props?.donationUrl?.split("//").pop();

  const dotDesc = (description, limit) => {
    const dots = "...";
    if (description && description.length > limit) {
      if (description.includes("strong")) {
        description = description.substring(0, limit + 100) + dots;
      } else description = description.substring(0, limit) + dots;
    }
    return description;
  };

  return (
    <li class="organisation-item sponor-listcard">
      {props.loading ? (
        <div className="ei-grid-box">
          <Skeleton borderRadius={"10px"} height={"200px"} />
        </div>
      ) : (
        <div className="organisation-box d-flex flex-wrap" style={{ height: "200px" }}>
          <div class="organisation-logo ">
            <Link>
              {!imageError && props.img ? (
                <img
                  className="org-img sm-img-fix"
                  src={process.env.REACT_APP_MEDIA + props.img}
                  onError={handleImageError}
                  alt=""
                />
              ) : !imageError && props.img1 ? (
                <img className="org-img sm-img-fix" src={process.env.REACT_APP_MEDIA + props.img1} alt="" />
              ) : (
                <img className="org-img sm-img-fix" src={defaultOrgImg} alt="" />
              )}
            </Link>
          </div>
          <div class="organisation-text">
            <h6 className="mb-0">
              {" "}
              <Link className="text-capitalize">{`${capitalizeContentInBrackets(props?.name)}`}</Link>
            </h6>
            <div class="organisation-text-footer">
              {props?.linkedSpecies && (
                <>
                  <div className="d-flex space-between">
                    <small>Species :</small>
                    {/* {props?.linkedSpecies?.length > 0 && (
                      <span
                        class=" map-switch view-all-btn1 fs-11 org-tab-mob link-text hover-underline text-end"
                        style={{
                          marginTop: "3px",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          if (props?.linkedSpecies?.length > 0) {
                            setSelectedData(props?.linkedSpecies);
                            setSelectedName(props?.name);
                            toggleShowMoreModal();
                          }
                        }}
                      >
                        View All
                      </span>
                    )} */}
                  </div>

                  <p className="caps-text d-flex align-items-center space-between">
                    <span>
                      {props?.linkedSpecies && props?.linkedSpecies.length > 0
                        ? (() => {
                            const filteredSpecies = props.linkedSpecies.filter(
                              (ie) => ie?.displayCommonName || ie?.scientificName,
                            );
                            if (filteredSpecies.length === 0) return "";

                            let displayText = "";
                            let charCount = 0;
                            const speciesToDisplay = [];

                            filteredSpecies.forEach((ie, r) => {
                              const name = ie?.displayCommonName || ie?.scientificName || "";
                              const separator = r > 0 ? ", " : "";

                              if (charCount + separator.length + name.length <= 50) {
                                speciesToDisplay.push(name);
                                charCount += separator.length + name.length;
                              } else if (speciesToDisplay.length < 2) {
                                // Append as "..." if no species can fully fit within the 80-character limit.
                                speciesToDisplay.push("...");
                              }
                            });

                            displayText = speciesToDisplay.join(", ");
                            if (filteredSpecies.length > speciesToDisplay.length) displayText += " ...";

                            return displayText;
                          })()
                        : ""}
                    </span>
                  </p>
                </>
              )}
              {props?.description ? (
                <>
                  <small>Description :</small>
                  <p className="caps-text" style={{ minHeight: "40px" }}>
                    {dotDesc(props.description, 50)}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      <ShowMoreModal
        show={showMoreModal}
        onHide={toggleShowMoreModal}
        listData={selectedData}
        heading={`Sponsored Species by ${selectedName ? capitalizeContentInBrackets(selectedName) : ""}`}
        isNonEditable={true}
      />
    </li>
  );
};

export default SponsorsListCard;
