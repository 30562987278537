import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeFirstLetter } from "../../../utils/helperFunctions";
import searchClose from "../../../include/images/search-close-18x18.svg";
const ShowMoreModal = (props) => {
  const [value, setValue] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [textSearched, setTextSearched] = useState("");
  const [screenWidth, setWidth] = useState(0);
  useEffect(() => {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    setWidth(width);
  }, [screenWidth]);
  // Sorting the array based on the "name" property
  useEffect(() => {
    if (props.show) {
      if (props.selectedData) {
        const data = [...props.selectedData];
        setValue(data);
      }

      if (Array.isArray(props?.listData)) {
        const sortedSpeciesArray = [...props?.listData].sort((a, b) => {
          const nameA = a?.name?.toUpperCase();
          const nameB = b?.name?.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // names must be equal
        });

        setFilteredValues(sortedSpeciesArray);
      }
    }
  }, [props.show, props?.listData]);
  return (
    <Modal
      className="modal fade environmental-inclusion-modal custom-floating-input"
      id="addOrganizationModal"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      contentClassName="endangered-viewall"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div class="modal-header modal-head view-all-header">
          <h5 class="modal-title text-capitalize" style={{ width: "90%" }}>
            {props.heading}
          </h5>
          <button
            type="button"
            class="close-btn d-flex region-viewall-modal-close-btn align-items-center justify-content-center modal-close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" className="modal-close-btn" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ padding: "30px 0px" }}>
          <div class="two-row-column d-flex flex-wrap" style={{ maxHeight: "300px", overflowX: "auto" }}>
            <div class="tr-column  position-relative show-more-model">
              <div class="tr-column-content w-100 h-100 d-flex flex-column">
                <table class="table table-borderless align-middle table-left-align">
                  <tbody>
                    <tr className={"d-flex flex-wrap"}>
                      {filteredValues && filteredValues.length > 0
                        ? filteredValues
                            .filter((item) => item.name?.toLowerCase()?.includes(textSearched?.toLowerCase()))
                            .map((list, idx) => {
                              // Determine if it's a region or a species, and choose the correct value
                              const displayValue = props?.type === "regions" ? list?.name : list?.displayCommonName || list?.scientificName;

                              // Skip rendering if the relevant property is missing
                              if (!displayValue) return null;

                              return props.isNonEditable ? (
                                <>
                                  <tr
                                    className="pl-0 "
                                    style={{
                                      minWidth: "210px",
                                    }}
                                  >
                                    <td>
                                      <div class="form-check tick-checkbox pl-0">
                                        <label class="filter-content-box caps-text d-flex">
                                          <span className="bullet-points">&#8226;</span>
                                          <Link
                                            className="text-transfrom hover-underline"
                                            to={{
                                              pathname:
                                                props?.type === "regions"
                                                  ? `/regions/${list?.slug}`
                                                  : `/endangered-species/${list?.slug}`,
                                              state: { id: list?._id },
                                              name: capitalizeFirstLetter(displayValue),
                                              type: props?.type === "regions" ? "region" : "species",
                                            }}
                                          >
                                            {props?.type === "regions"
                                              ? list?.countryName === "United States"
                                                ? list?.name + " - United States"
                                                : list?.name
                                              : list?.displayCommonName || list?.scientificName}{" "}
                                          </Link>
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  <tr
                                    className="pl-0 "
                                    style={{
                                      width: screenWidth <= 700 ? "50%" : "33%",
                                    }}
                                  >
                                    <td>
                                      <div class="form-check tick-checkbox pl-0">
                                        <label class="filter-content-box">
                                          {displayValue}
                                          <input
                                            type="checkbox"
                                            checked={value.includes(list._id)}
                                            onChange={() => {
                                              const data = [...value];
                                              const data1 = [...props.selectedDataName];
                                              if (data.includes(list._id)) {
                                                const a = data.filter((item) => item !== list._id);
                                                const b = data1.filter((item1) => item1._id !== list._id);
                                                props.setSelectedDataName(b);
                                                setValue(a);
                                              } else {
                                                data1.push(list);
                                                props.setSelectedDataName(data1);
                                                data.push(list._id);
                                                setValue(data);
                                              }
                                            }}
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                        : ""}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {!props.isNonEditable && (
            <div class="row row-form-button justify-content-end">
              <div class="col-sm-6 ps-sm-0">
                <button
                  type="submit"
                  class="btn btn-default btn-block"
                  onClick={() => {
                    const data = [...value];
                    props.setSelectedData(data);
                    props.onHide();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default React.memo(ShowMoreModal);
