import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Correct location import
import PageLayout from "../../layout/PageLayout/pageLayout";
import { connect } from "react-redux";
import { updateYourProfile } from "../../store/users"; // Assuming the action exists
import { getSpeciesDetails } from "../../store/species";
import amphibiansImg from "../../include/images/Amphibians.png";
import birdsImg from "../../include/images/Birds.png";
import fishImg from "../../include/images/Fish.png";
import insectsImg from "../../include/images/Insects.png";
import mammalsImg from "../../include/images/Mammals.png";
import reefCoralsImg from "../../include/images/Reef Corals.png";
import reptilessImg from "../../include/images/Reptiles.png";
import sharksAndRaysImg from "../../include/images/Sharks & Rays.png";
import crustaceansImg from "../../include/images/Crustaceans.png";
import defaultImage from "../../include/images/default_image_species.svg";
import { createsponsor, createStory, getSubscription } from "../../store/subscription";
import { toast } from "react-toastify";
import AlertSuccess from "../../common/alerts/alertSuccess";
import AlertError from "../../common/alerts/alertError";
import { v4 as uuidv4 } from "uuid";
import { Amplify, Auth, Storage } from "aws-amplify";
import SpeciesAndCategoryEditModal from "../../components/common/feedEditModals/speciesAndCategoryEditModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SponsorSpeciesModal from "../../components/common/subscription/sponsorSpeciesModal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});
Auth.configure({
  Auth: {
    identityPoolId: "us-east-2:fa1d4017-2701-482a-9559-34a69f57d192", //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_S3_REGION,
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_S3_REGION,
  },
});

const CreateStory = (props) => {
  const location = useLocation(); // To access location.state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    logo: null,
  });

  const [id, setId] = useState(null);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [follow, setFollow] = useState(false);
  const [speciesData, setSpeciesData] = useState(null);
  const [tempSelectSpeciesName, setTempSelectSpeciesName] = useState([]);
  const [showSpeciesAndCategories, setshowSpeciesAndCategories] = useState(false);
  const [speciesCatData, setSpeciesCatData] = useState(null);
  const [tempSelectSpeciesCategory, setTempSelectSpeciesCategory] = useState([]);
  const [tempSelectSpecies, setTempSelectSpecies] = useState([]);
  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    if (location?.state?.id) {
      setId(location?.state?.id);
      const speciesId = location.state.id;
      fetchSpeciesData(speciesId);
    }
  }, [location]);

  const fetchSpeciesData = (speciesId) => {
    setLoadingDetail(true);
    props.getSpeciesDetails(speciesId, (res) => {
      if (res && res.status === 200) {
        setLoadingDetail(false);
        setSpeciesData(res?.data?.data);
      }
    });
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file (logo) upload
  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, logo: e.target.files[0] }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingDetail(true);

    if (tempSelectSpeciesName?.length > 1) {
      toast(<AlertError message="You can select only one Species." />);
      return;
    }

    if (!location?.state?.id) {
      sessionStorage.setItem("tempSelectSpecies", JSON.stringify(tempSelectSpecies));
    }

    const { logo, name } = formData;

    if (logo) {
      const file = logo;
      const fSize = Math.round(file.size / 1048576); // Size in MB
      const fType = file.type;
      const ext = file.name.split(".").pop();

      if (fSize > 25) {
        toast(<AlertError message="Size exceeds maximum allowable size. Maximum allowable size is 25MB." />);
        setLoadingDetail(false);
        return;
      } else if (!["image/jpeg", "image/jpg", "image/png", "image/webp"].includes(fType)) {
        toast(
          <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpg, jpeg, png and webp." />,
        );
        setLoadingDetail(false);
        return;
      } else {
        const fileName = uuidv4() + "." + ext;

        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            setLoadingDetail(false);
            toast(<AlertError message={"Something Went Wrong"} />);
          },
        })
          .then((result) => {
            const payload = {
              logo: "public/" + result.key,
              name: name || undefined, // Include name only if it's provided
              description: formData?.description,
              return_url: location?.state?.id
                ? `${window.location.origin}/endangered-species/${speciesData?.slug}`
                : `${window.location.origin}/profile`,
              cancel_url: location?.state?.id
                ? `${window.location.origin}/endangered-species/${speciesData?.slug}?cancel=true`
                : `${window.location.origin}/profile?cancel=true`,
            };
            submitPayload(payload);
          })
          .catch((err) => {
            setLoadingDetail(false);
            toast(<AlertError message={"Something Went Wrong"} />);
          });
      }
    } else {
      const payload = {
        description: formData?.description,
        return_url: location?.state?.id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}`
          : `${window.location.origin}/profile`,
        cancel_url: location?.state?.id
          ? `${window.location.origin}/endangered-species/${speciesData?.slug}?cancel=true`
          : `${window.location.origin}/profile?cancel=true`,
      };
      submitPayload(payload);
    }
  };

  const submitPayload = (payload) => {
    props.createStory(location?.state?.id ? id : tempSelectSpecies[0], payload, (res) => {
      if (res && res.status === 200) {
        setLoadingDetail(false);
        const redirectUrl = res.data.data?.url;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          toast(<AlertError message="No redirection URL provided" />);
        }
      } else {
        setLoadingDetail(false);
        toast(<AlertError message={res?.data?.message || "Something went wrong"} />);
      }
    });
  };

  const getDefaultImage = (fileName) => {
    switch (fileName) {
      case "birds":
        return birdsImg;
      case "amphibians":
        return amphibiansImg;
      case "fish":
        return fishImg;
      case "insects":
        return insectsImg;
      case "mammals":
        return mammalsImg;
      case "reef corals":
        return reefCoralsImg;
      case "reptiles":
        return reptilessImg;
      case "sharks & rays":
        return sharksAndRaysImg;
      case "crustaceans":
        return crustaceansImg;
      default:
        return defaultImage;
    }
  };
  const toggleSpeciesAndCategories = () => {
    setshowSpeciesAndCategories(!showSpeciesAndCategories);
  };
  return (
    <PageLayout>
      <div className="page-panel-container font-family-poppins sponsored-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Create A Story</h1>
            </div>
            <div class="panel-head mb-0 ps-3">
              {location?.state?.id && (
                <div class="breadcrumb-row d-flex flex-wrap">
                  <div class="breadcrumb-box">
                    <Link to="/endangered-species">Endangered Species</Link>
                  </div>
                  <Link to={`/endangered-species/${speciesData?.slug}`} class="breadcrumb-box text-capitalize">
                    {speciesData?.displayCommonName || speciesData?.scientificName}
                  </Link>
                </div>
              )}
              {!location?.state?.id && (
                <div class="breadcrumb-row d-flex flex-wrap">
                  <div class="breadcrumb-box text-capitalize">
                    {location.state == "profile" ? (
                      <Link to="/profile">{location.state}</Link>
                    ) : (
                      <Link to="/environmental-press-corp">{location.state}</Link>
                    )}
                  </div>
                  <div class="breadcrumb-box">Add Story</div>
                </div>
              )}
            </div>
          </div>
          <div className="row g-4 g-lg-0">
            <div className="col-lg-6">
              <div className="w-100 intern-program-container p-0">
                <h6>We have introduced a new intern program</h6>
                <p>
                  Designed for university students to be involved in the development of the first social media site
                  dedicated to endangered species. We have over 14,000 species from the animal kingdom including
                  mammals, reptiles, birds, crustations, amphibians, sharks & rays, reef corals, and insect classes that
                  are in danger of extinction. We are building pages for each animal including photos, videos, science
                  papers, zoo reports, conservation reports, animal art, organizations, biologist and scientists to
                  bring awareness about the programs and threats to each species.
                </p>
                <h6>Here is a summary of the program:</h6>
                <ol>
                  <li>
                    Register on Environmental Inclusion and select species of interest. Let us know the university,
                    department and professors involved.
                    <a
                      href="https://www.environmentalinclusion.com/signup?type=volunteer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.environmentalinclusion.com/signup?type=volunteer
                    </a>
                  </li>
                  <li>
                    The student will then have full access to develop and build one species page with the most current
                    information available.
                  </li>
                  <li>
                    Student will engage scientists, biologists, environmentalist, zoos, non-profits, preservations, and
                    advocates to collect and collaborate on EnvironmentalInclusion.com.
                  </li>
                  <li>
                    The goal is to organize, develop and promote the conservation efforts to save this animal through
                    research and journalism.
                  </li>
                  <li>
                    After the internship completion, Environmental Inclusion will supply the professor with a detailed
                    report showing the results and comments regarding the project.
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-6 ps-lg-5">
              <div className="sponsor-form-box position-relative">
                <div class="text-sponsor fw-bold d-flex justify-content-end">$600/Story</div>
                {/* <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={formData.name}
                        name="name"
                        onChange={handleInputChange}
                        placeholder="First name"
                      />
                      <label htmlFor="firstName">Name</label>
                    </div>
                  </div>
                  {/* <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        value={formData.email}
                        name="email"
                        onChange={handleInputChange}
                        placeholder="Email Address"
                      />
                      <label htmlFor="emailAddress">Email</label>
                    </div>
                  </div> 
                </div> */}

                {/* <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <input
                        type="text"
                        className="form-control"
                        value={formData.logo ? formData.logo.name : ""}
                        readOnly
                        onClick={() => document.getElementById("floatingfile").click()}
                        placeholder="Click here to upload a logo"
                      />
                      <label htmlFor="floatingfile">Upload Logo</label>

                      <input type="file" id="floatingfile" onChange={handleFileChange} style={{ display: "none" }} />

                      <button
                        type="button"
                        className="ff-change-link"
                        onClick={() => document.getElementById("floatingfile").click()}
                      >
                        Browse
                      </button>
                    </div>
                  </div>
                </div>

                {formData.logo && (
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="ffr-logo-area">
                        <img
                          src={URL.createObjectURL(formData.logo)}
                          alt="Uploaded Preview"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                {!location?.state?.id ? (
                  <div className="form-floating-row d-flex flex-wrap">
                    <div className="ff-column">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={
                            tempSelectSpeciesName?.length > 0
                              ? tempSelectSpeciesName[0]?.displayCommonName
                              : "Select Species"
                          }
                          readOnly
                          onClick={() => {
                            toggleSpeciesAndCategories();
                          }}
                          placeholder="Select Species"
                        />
                        {tempSelectSpeciesName?.length > 0 ? (
                          <button
                            type="button"
                            className="ff-change-link"
                            onClick={() => {
                              if (tempSelectSpeciesName?.length > 1) {
                                toast(<AlertError message="You can select only one species." />);
                              } else {
                                toggleSpeciesAndCategories();
                              }
                            }}
                          >
                            {`${tempSelectSpeciesName?.length} Selected`}
                          </button>
                        ) : (
                          <button type="button" className="ff-change-link" onClick={() => toggleSpeciesAndCategories()}>
                            Select
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-floating-row d-flex flex-wrap mt-3">
                    <div className="ff-column w-100">
                      <div className="form-floating custom-form-floating">
                        <input
                          type="text"
                          className="form-control text-capitalize"
                          value={speciesData?.displayCommonName}
                          readOnly
                        />
                        <button type="button" className="ff-change-link">
                          Selected
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-floating-row d-flex flex-wrap">
                  <div className="ff-column">
                    <div className="form-floating custom-form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Describe any particular things that interest you regarding this species."
                        id="floatingTextarea2"
                        style={{ height: "115px" }}
                        value={formData.description}
                        name="description"
                        onChange={handleInputChange}
                      />
                      <label htmlFor="floatingTextarea2">
                        Describe any particular things that interest you regarding this species.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-floating-btn text-end">
                  {location?.state?.id ? (
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!speciesData}
                      className="btn btn-default btn-md"
                    >
                      Dispatch a Reporter
                    </button>
                  ) : (
                    <button type="submit" onClick={handleSubmit} className="btn btn-default btn-md">
                      Dispatch a Reporter
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SponsorSpeciesModal
          show={showSpeciesAndCategories}
          onHide={toggleSpeciesAndCategories}
          loading={false}
          setLoading={setLoadingDetail}
          speciesData={speciesData}
          isEdit={true}
          isViewAll={false}
          setSpeciesData={setSpeciesData}
          setSpeciesCatData={setSpeciesCatData}
          speciesCatData={speciesCatData}
          tempSelectSpeciesCategory={tempSelectSpeciesCategory}
          setTempSelectSpeciesCategory={setTempSelectSpeciesCategory}
          tempSelectSpecies={tempSelectSpecies}
          setTempSelectSpecies={setTempSelectSpecies}
          tempSelectSpeciesName={tempSelectSpeciesName}
          setTempSelectSpeciesName={setTempSelectSpeciesName}
          isPost={true}
          postType={props?.postType}
        />
      </div>
    </PageLayout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateYourProfile: (data, callback) => {
    dispatch(updateYourProfile(data, callback));
  },
  getSpeciesDetails: (params, callback) => dispatch(getSpeciesDetails(params, callback)),
  createStory: (params, data, callback) => {
    dispatch(createStory(params, data, callback));
  },
});
const mapStateToProps = (state) => ({
  getSubscription: getSubscription(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateStory));
