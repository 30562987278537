import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import {
  capitalizeContentInBrackets,
  capitalizeFirstLetter,
  capitalizeFirstLetterWord,
  transformDropdownData,
} from "../../utils/helperFunctions";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import shareSvg from "../../include/images/share.svg";
import Details from "../../components/speciesDetailsPages/details";
import Organizations from "../../components/speciesDetailsPages/organizations";
import People from "../../components/speciesDetailsPages/people";
import Region from "../../components/speciesDetailsPages/region";
import ScienceAndEducation from "../../components/speciesDetailsPages/scienceAndEducation";
import Zoo from "../../components/speciesDetailsPages/zoo";
import WetMarkets from "../../components/speciesDetailsPages/wetMarkets";
import UserUpload from "../../components/common/userUpload/userUpload";
import LiveFeeds from "../../components/common/liveFeedsModal/liveFeeds";
import App from "../../common/maps/map";
import USMap from "../../common/maps/regionDetail/usMap";
import WorldMap from "../../common/maps/regionDetail/worldMap";
import ShowMoreModal from "../../components/common/showMoreModal/showMoreModal";
import ShareModal from "../../components/common/sharemodal/shareModal";
import editIcon2 from "../../include/images/edit-2.svg";
import Select from "react-select";
import { customStyles1 } from "../../common/customStyles/reactSelectStyle";
import Education from "../../components/common/education/education";
import References from "../../components/common/reference/references";
import { Dropdown } from "react-bootstrap";
import Sponsor from "../../components/speciesDetailsPages/sponsor";
import Media from "../../components/common/education/media";
import { propTypes } from "react-bootstrap/esm/Image";

const EditSpeciesDetail = ({
  loadingDetail,
  details,
  zoo,
  menuItem,
  setMenuItem,
  setOpenShareModal,
  capitalizeText,
  handleMenuItemClick,
  items,
  id,
  setSelectedId,
  splashText,
  combinedData,
  speciesMapData,
  toggleShowMoreModal,
  usState,
  regionsName,
  type,
  usRegions,
  allRegionDetails,
  setType,
  showMoreModal,
  openShareModal,
  addTargetBlankToLinks,
  miscSettingCitation,
  editMode,
  editState,
  setEditState,
  dangerLevelDropdown,
}) => {
  const indexesToRemove = [8, 9];
  const filteredItems = items.filter((item, index) => !indexesToRemove.includes(index));
  const [imgSrc, setImgSrc] = useState(null);
  const [detailImgSrc, setDetailImgSrc] = useState(null);
  const [localDropdown, setLocalDropdown] = useState(() => [...dangerLevelDropdown]);

  const [selectedValue, setSelectedValue] = useState(() => {
    const dangerLevelName = capitalizeFirstLetter(details?.data?.dangerLevelName);
    const existingOption = dangerLevelDropdown.find((option) => option.label === dangerLevelName);

    if (existingOption) {
      return existingOption;
    } else if (details?.data?.dangerLevelName && details?.data?.dangerLevel) {
      const newOption = {
        label: dangerLevelName,
        value: details?.data?.dangerLevel,
      };
      setLocalDropdown((prev) => [...prev, newOption]);
      return newOption;
    } else {
      return null;
    }
  });

  const date = new Date(details?.data?.updatedAtVolunteer);
  const [selectMainReg, setSelectMainReg] = useState(null);
  const [loading, setloading] = useState(false);
  const options = allRegionDetails.map((region) => ({
    value: region._id,
    label: region.country === "US" ? `${region.state}, ${region.countryName}` : region.countryName,
  }));

  const initialSelectedOption = editState?.regions?.map((region) => ({
    value: region._id,
    label: region.country === "US" ? `${region.state}, ${region.countryName}` : region.countryName,
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "dangerLevel") {
      setSelectedValue({ value: value.value, label: value.label });
    }
    if (name == "defaultRegion") {
      setSelectMainReg({ value: value, label: e.target.label });
    }
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleImageChange = (e, img) => {
    const file = e.target.files[0];
    if (file) {
      setEditState((prevState) => ({
        ...prevState,
        [img]: file,
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        if (img == "coverImage") {
          setImgSrc(reader.result);
        } else if (img == "detailTabImage") {
          setDetailImgSrc(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const displayRegions = editState?.regions?.slice(0, 3);
  const remainingRegions = editState?.regions?.slice(3);
  return (
    <main id="main">
      <div class="content-container overflow-hidden pt-4">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="listing-details-container d-flex flex-wrap justify-content-between">
                {loadingDetail ? (
                  <div class="ldc-image">
                    <Skeleton borderRadius={"10px"} width={"100%"} height={"100%"} />
                  </div>
                ) : (
                  <div class="ldc-image species-detail-image ">
                    <div class="species-details-img position-relative">
                      <img
                        src={
                          imgSrc?.startsWith("data:") ? imgSrc : `${process.env.REACT_APP_MEDIA}${editState.coverImage}`
                        }
                        alt="Species"
                      />
                      <Link className="edit-btn species-detailsedit-btn" onClick={triggerFileInput}>
                        <img src={editIcon2} alt="Edit" />
                      </Link>
                      <input
                        type="file"
                        id="fileInput"
                        className="form-control"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleImageChange(e, "coverImage")}
                      />
                    </div>
                  </div>
                )}
                <div className="ldc-text">
                  <div className="ldc-text-head">
                    {loadingDetail ? (
                      <span className="text-title text-red text-capitalize">
                        <Skeleton borderRadius={"10px"} width={200} height={20} />
                      </span>
                    ) : (
                      <Select
                        className="basic-single w-50 text-capitalize"
                        classNamePrefix="select-search"
                        placeholder="Select"
                        styles={customStyles1}
                        options={localDropdown}
                        value={selectedValue}
                        onChange={(value) => handleChange({ target: { name: "dangerLevel", value: value } })}
                      />
                    )}
                    <div className="ldc-text-main d-flex">
                      {loadingDetail ? (
                        <Skeleton borderRadius={"10px"} height={40} width={200} />
                      ) : (
                        <>
                          <div className="mt-2 mb-2">
                            <label htmlFor="displayCommonName" className="form-label mb-1 ms-1 pointer-event-none">
                              Display Common Name
                            </label>
                            <input
                              type="text"
                              id="displayCommonName"
                              name="displayCommonName"
                              value={editState.displayCommonName}
                              onChange={handleChange}
                              className="text-capitalize form-control h-40 w-75"
                            />
                            {/* <div className="ml-20">
                                {" "}
                                <Link onClick={() => setOpenShareModal(true)}>
                                  <img src={shareSvg} alt="" />
                                </Link>
                              </div> */}
                          </div>
                          <div>
                            <img
                              src={process.env.REACT_APP_MEDIA + details?.data?.dangerLevelImage?.small}
                              className="ldc-text-main-icon"
                              alt=""
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {loadingDetail ? (
                      <Skeleton borderRadius={"10px"} height={20} width={200} />
                    ) : (
                      <span className="text-capitalize">
                        {details?.data?.scientificName ? details?.data?.scientificName : "-"}
                      </span>
                    )}
                  </div>
                  <div className="ldc-text-body">
                    <ul className="ltb-details-list d-flex flex-wrap">
                      <li className="ltb-details-item">
                        <div
                          className={
                            zoo?.data?.length
                              ? `ltb-details-box cursor-pointer ${menuItem == "6" && "active"}`
                              : `ltb-details-box pointer-event-none`
                          }
                          onClick={() => setMenuItem("6")}
                        >
                          <p>National Parks/ Sanctuaries</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <>
                              {zoo && zoo?.data?.length > 0 ? (
                                <>
                                  {zoo.data.map(
                                    (item, idx) =>
                                      idx <= 1 && (
                                        <h4 className={`caps-text ${idx === 1 ? "mt-0" : ""}`} key={idx}>
                                          {item?.name}
                                        </h4>
                                      ),
                                  )}
                                  {zoo.data.length > 4 && <span>...</span>}
                                </>
                              ) : (
                                <h4>-</h4>
                              )}
                            </>
                          )}
                        </div>
                      </li>
                      <li class="ltb-details-item">
                        <div className="ltb-details-box">
                          <p className="pointer-event-none">Common Names</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : details?.data?.name ? (
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={editState.name}
                              onChange={handleChange}
                              className="text-capitalize form-control"
                            />
                          ) : (
                            <h4>-</h4>
                          )}
                        </div>
                      </li>
                      {/* <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <p className="pointer-event-none">Regions</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <Select
                              name="defaultRegion"
                              className="basic-single text-capitalize z-3"
                              classNamePrefix="select-search"
                              placeholder="Select"
                              isMulti
                              styles={customStyles1}
                              options={options}
                              value={selectMainReg || initialSelectedOption}
                              onChange={(selectedOptions) => {
                                setSelectMainReg(selectedOptions);
                                setEditState((prevState) => ({
                                  ...prevState,
                                  regions: selectedOptions.map((option) => ({
                                    _id: option.value,
                                    name: option.label.split(", ")[0],
                                  })),
                                }));
                              }}
                            />
                          )}
                        </div>
                      </li> */}
                      <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <h4>Regions</h4>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <>
                              {details?.data?.regions?.length > 0 ? (
                                <>
                                  <p className="caps-text">
                                    {displayRegions.map((region, index) => (
                                      <span key={region._id} className="d-block">
                                        {/* Check if the country is United States, then show both state and country */}
                                        {region.countryName === "United States" && region.state
                                          ? `${region.state}, ${region.countryName}`
                                          : region.countryName}
                                        {index < displayRegions.length - 1 ? ", " : ""}
                                      </span>
                                    ))}
                                  </p>

                                  {remainingRegions.length > 0 && (
                                    <div className="td-link-text fw-semibold">
                                      <Dropdown bsPrefix="header-name-box fw-medium dropdown cursor-pointer">
                                        <Dropdown.Toggle as="span" id="dropdown-basic" className="dropdown-toggle">
                                          <p
                                            className="td-a-icon"
                                            style={{ color: "#47AD1D", cursor: "pointer" }}
                                            data-bs-toggle="dropdown"
                                            aria-expanded={false}
                                          >
                                            <u>View More</u>
                                          </p>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu
                                          bsPrefix="dropdown-menu dropdown-menu-end"
                                          style={{ background: "#fff", maxHeight: "200px", overflowY: "auto" }}
                                        >
                                          {remainingRegions.map((region) => (
                                            <Dropdown.Item key={region._id}>
                                              <tr>
                                                {/* Check if the country is United States, then show both state and country */}
                                                {region.countryName === "United States" && region.state
                                                  ? `${region.state}, ${region.countryName}`
                                                  : region.countryName}
                                              </tr>
                                            </Dropdown.Item>
                                          ))}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <h4>-</h4>
                              )}
                            </>
                          )}
                        </div>
                      </li>
                      <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <p className="pointer-event-none">Population</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              name="population"
                              value={editState.population}
                              onChange={handleChange}
                            />
                          )}
                        </div>
                      </li>
                      <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <p className="pointer-event-none">Habitat</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <input
                              type="text"
                              name="habitat"
                              value={editState.habitat}
                              onChange={handleChange}
                              className="caps-text form-control"
                            />
                          )}
                        </div>
                      </li>
                      <li className="ltb-details-item">
                        <div className="ltb-details-box">
                          <p className="pointer-event-none">Weight(in lbs) and Life Span</p>
                          {loadingDetail ? (
                            <Skeleton borderRadius={"10px"} height={20} width={150} />
                          ) : (
                            <>
                              <input
                                type="text"
                                name="weight"
                                value={editState.weight}
                                onChange={handleChange}
                                className="caps-text form-control"
                              />
                              <input
                                type="text"
                                className="form-control mt-2"
                                name="lifeSpan"
                                value={editState.lifeSpan}
                                onChange={handleChange}
                              />
                            </>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Details
                loading={loadingDetail}
                menuItem={menuItem}
                id={id}
                setSelectedId={setSelectedId}
                editMode={editMode}
                handleChange={handleChange}
                editState={editState}
                handleImageChange={handleImageChange}
                detailImgSrc={detailImgSrc}
                setEditState={setEditState}
              />
            </div>
          </div>
        </div>
      </div>
      {loadingDetail ? (
        <div class="tabs-block-container">
          <div class="tbc-head ">
            <Skeleton
              borderRadius={"10px"}
              height={"7.5vh"}
              baseColor="var(--dark-green-color)"
              highlightColor="#0cb40c"
            />
          </div>
          <div class="container">
            {" "}
            <Skeleton borderRadius={"10px"} width={"100%"} height={"80vh"} />
          </div>
        </div>
      ) : (
        <div class="tabs-block-container new-tabs-block-container">
          <div class="tbc-head ">
            <div class="container">
              <div class="row">
                <div className="col-md-12">
                  <form className="d-lg-none">
                    <select
                      className="form-select basic-single "
                      id="tabSelector"
                      onChange={(e) => handleMenuItemClick(e.target.value)}
                      value={menuItem}
                    >
                      {filteredItems.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </option>
                      ))}
                    </select>
                  </form>
                  <ul className="nav nav-pills d-lg-flex d-none" id="pills-tab" role="tablist">
                    {filteredItems.map((item) => (
                      <li className="nav-item" key={item.id} role="presentation">
                        <div
                          className={`nav-link ${menuItem === item.id && "active"}`}
                          id={`pills-${item.id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${item.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${item.id}`}
                          aria-selected={menuItem === item.id}
                          onClick={() => handleMenuItemClick(item.id)}
                        >
                          {item.label} {item.count > 0 && `(${item.count})`}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tbc-body overflow-hidden">
            <div class="tab-content tab-content-slider" id="pills-tabContent">
              {menuItem == "2" && (
                <div class={`tab-pane fade ${menuItem == "2" && "active show"}`} id="pills-two">
                  <Organizations
                    menuItem={menuItem}
                    id={id}
                    name={details?.data?.name}
                    slug={details?.data?.slug}
                    splashData={splashText}
                    editMode={editMode}
                  />
                </div>
              )}
              {menuItem == "3" && (
                <div class={`tab-pane fade ${menuItem == "3" && "active show"}`} id="pills-three">
                  <People menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "4" && (
                <div class={`tab-pane fade ${menuItem == "4" && "active show"}`} id="pills-four">
                  <Region
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    loading={loadingDetail}
                    editMode={editMode}
                    region={details.data.regions}
                  />
                </div>
              )}
              {menuItem == "5" && (
                <div class={`tab-pane fade ${menuItem == "5" && "active show"}`} id="pills-five">
                  <ScienceAndEducation menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "6" && (
                <div class={`tab-pane fade ${menuItem == "6" && "active show"}`} id="pills-six">
                  <Zoo menuItem={menuItem} id={id} editMode={editMode} />
                </div>
              )}
              {menuItem == "7" && (
                <div class={`tab-pane fade ${menuItem == "7" && "active show"}`} id="pills-seven">
                  <WetMarkets
                    menuItem={menuItem}
                    id={id}
                    editMode={editMode}
                    editState={editState}
                    setEditState={setEditState}
                  />
                </div>
              )}
              {menuItem == "8" && (
                <div class={`tab-pane fade ${menuItem == "8" && "active show"}`} id="pills-eight">
                  <Sponsor menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "9" && (
                <div class={`tab-pane fade ${menuItem == "9" && "active show"}`} id="pills-nine">
                  <LiveFeeds menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "10" && (
                <div class={`tab-pane fade ${menuItem == "10" && "active show"}`} id="pills-ten">
                  <div className="container">
                    <div class="tbc-map-full detail-page-map region-detail-map">
                      <div class="map-hero-image map-wet-market">
                        {combinedData && (
                          <App
                            data={combinedData}
                            data1={speciesMapData}
                            mapType="detail-page"
                            isMultiMarker={true}
                            zoom={"zoom"}
                            singleMap={true}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {menuItem == "11" && (
                <div class={`tab-pane fade ${menuItem == "11" && "active show"}`} id="pills-eleven">
                  <Education menuItem={menuItem} id={id} setSelectedId={setSelectedId} section="species" />
                </div>
              )}
              {menuItem == "12" && (
                <div class={`tab-pane fade ${menuItem == "12" && "active show"}`} id="pills-twelve">
                  <References
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    section="species"
                    isEdit={editMode}
                  />
                </div>
              )}
              {menuItem == "13" && (
                <div class={`tab-pane fade ${menuItem == "13" && "active show"}`} id="pills-thirteen">
                  <Media
                    loading={loadingDetail}
                    menuItem={menuItem}
                    id={id}
                    setSelectedId={setSelectedId}
                    section="species"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {menuItem == "6" && (
        <div className="container">
          <div className="d-flex space-between species-region-text" style={{ marginTop: "20px" }}>
            <p className="map-switch cursor-pointer" onClick={() => toggleShowMoreModal()}>
              View All Regions Name
            </p>

            {usState?.length > 0 && (
              <p
                className="map-switch cursor-pointer"
                onClick={() => {
                  if (type == "us") {
                    setType("world");
                  } else if (type == "world") {
                    setType("us");
                  }
                }}
              >
                Switch to {type === "us" ? "All " : "US"} region map
              </p>
            )}
          </div>
          <div class="tbc-map-full">
            <div class="map-hero-image">
              {(regionsName && regionsName?.length === 1 && regionsName[0]?.countryName === "United States") ||
              type == "us" ? (
                <USMap
                  name={
                    (regionsName && regionsName.length === 1) || type == "us"
                      ? usState?.map((item) => item?.state)
                      : usState?.map((item) => item?.countryName)
                  }
                  data={usRegions}
                  setSelectedId={setSelectedId}
                  page="species"
                  isNotClickable={true}
                />
              ) : (
                <WorldMap
                  name={regionsName && regionsName?.length && regionsName?.map((item) => item?.countryName)}
                  data={allRegionDetails}
                  setSelectedId={setSelectedId}
                  page="species"
                  isNotClickable={true}
                  setType={setType}
                  showAllMap={true}
                />
              )}
            </div>
            <ShowMoreModal
              show={showMoreModal}
              onHide={toggleShowMoreModal}
              listData={regionsName}
              heading={`${details?.data?.name} found in these regions`}
              isNonEditable={true}
              type="regions"
            />
          </div>
        </div>
      )}
      <ShareModal
        show={openShareModal}
        onHide={() => setOpenShareModal(!openShareModal)}
        name={capitalizeContentInBrackets(capitalizeFirstLetterWord(details?.data?.name))}
        id={details?.data?._id}
        category="species"
      />
      <div className="container custom-citation">
        <div className="custom-citation-text">Citations</div>
        <div
          className="custom-citation-text"
          dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(miscSettingCitation) }}
        ></div>
        {details?.data?.updatedByVolunteer?.firstName && (
          <div className="last-edited">
            {" "}
            Last edited on {date.toLocaleString()} by{" "}
            <span className="text-capitalize">
              {details?.data?.updatedByVolunteer?.firstName} {details?.data?.updatedByVolunteer?.lastName}{" "}
            </span>
          </div>
        )}
      </div>
    </main>
  );
};

export default EditSpeciesDetail;
